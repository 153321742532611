export enum StringResources {
  ApplicationName = 'webPod',
  LoginText = 'Choose service',
  ToWebsite = 'To Website',
  ToDeveloper = 'To Developer\'s website',
  ToCollaborator = 'To $Collaborator\'s website',
  ToLinkedIn = 'To LinkedIn',
  EnlightWebsite = 'https://teamenlight.com',
  CollaboratorWebsite = 'https://macrumors.com',
  EnlightLinkedIn = 'https://www.linkedin.com/company/72566485'
}
