// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDqg0n4Ynb5Dgf2qYSFjmCIAXzBw40PTFg',
    authDomain: 'webpod-2aad4.firebaseapp.com',
    projectId: 'webpod-2aad4',
    storageBucket: 'webpod-2aad4.appspot.com',
    messagingSenderId: '3005526868',
    appId: '1:3005526868:web:5bd6c90d1910af7bae0ec1',
    measurementId: 'G-87DKMM5YHK',
    collection: 'feedbacks'
  },
  version: '0.1',
  SpotifyLoginBackendUrl: 'https://webpod.seresk.com:1339/login',
  SpotifyRefreshTokenBackendUrl: 'https://webpod.seresk.com:1339/refresh_token?refresh_token='

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
