import {Component, OnInit} from '@angular/core';
import {MenuItemTypeEnum} from './enums/MenuItemTypeEnum';
import {MenuItem} from './models/MenuItem';
import {MenuModel} from './models/MenuModel';
import {StateService} from './services/state.service';
import {AccountingService} from './services/accounting.service';
import {TutorialModalComponent} from './components/tutorial-modal/tutorial-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StringResources} from './enums/StringResources';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = StringResources.ApplicationName;


  constructor(
    private stateService: StateService,
    private accountingService: AccountingService,
    private modalService: NgbModal) {

    this.stateService.setSelectedMenuName(StringResources.ApplicationName);
  }

  ngOnInit(): void {

  }

  openTutorialModal(): void {
    if (this.stateService.atFeedback === true) {
      return;
    }

    this.stateService.tooltipAnimationVisible = true;
    setTimeout(function () {
      this.stateService.tooltipAnimationVisible = false;
    }.bind(this), 2000);
  }
}


declare var $: any;
