export class ArtistModel {

  id: string = '';
  artist: string = '';
  genre: string = '';
  name: string = '';
  imageUrl: string = '';

  public constructor(init?: Partial<ArtistModel>) {
    Object.assign(this, init);
  }
}
