import {Component, OnInit} from '@angular/core';
import {StateService} from '../../services/state.service';
import {AccountingService} from '../../services/accounting.service';
import {LoadingService} from '../../services/loading.service';
import {DeviceService} from '../../services/device.service';
import {PlayService} from '../../services/play.service';
import {PlayStateEnum} from '../../enums/PlayStateEnum';
import {AuthorizationService} from '../../services/authorization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public PlayStateEnum = PlayStateEnum;

  constructor(public stateService: StateService,
              public accountingService: AccountingService,
              public playService: PlayService,
              public loadingService: LoadingService,
              public deviceService: DeviceService,
              public authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
  }

}
