import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalActionEnum} from '../../enums/ModalActionEnum';
import {SpotifyLoginTokensEnum} from '../../enums/SpotifyLoginTokensEnum';
import {LocalStorageKeysEnum} from '../../enums/LocalStorageKeysEnum';

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss']
})
export class TutorialModalComponent implements OnInit {

  public ModalActionEnum = ModalActionEnum;
  public doNotShowSet = false;
  public savedDoNotShow = false;

  constructor(public activeModal: NgbActiveModal) {
    let doNotShowString = window.localStorage.getItem(LocalStorageKeysEnum.DoNotShowTutorial)!;

    if (doNotShowString === 'true') {
      this.doNotShowSet = true;
      this.savedDoNotShow = true;
    } else {
      this.doNotShowSet = false;
      this.savedDoNotShow = false;
    }
  }

  ngOnInit(): void {
  }

  public storeDoNotShow(): boolean {
    window.localStorage.setItem(LocalStorageKeysEnum.DoNotShowTutorial, this.doNotShowSet.toString());
    return true;
  }
}
