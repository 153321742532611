import {Component, OnInit} from '@angular/core';
import {ControlBarBase} from '../control-base/control-bar-base';
import {IPlaybackModeControlShowHideEventsSubscriber} from '../../../interfaces/IPlaybackModeControlShowHideEventsSubscriber';
import {IScrollSubscriber} from '../../../interfaces/IScrollSubscriber';
import {PublisherService} from '../../../services/publisher.service';
import {IMenuButtonEventsSubscriber} from '../../../interfaces/IMenuButtonEventsSubscriber';

@Component({
  selector: 'app-playback-mode-control',
  templateUrl: './playback-mode-control.component.html',
  styleUrls: ['./playback-mode-control.component.scss']
})
export class PlaybackModeControlComponent
  extends ControlBarBase
  implements OnInit,
    IScrollSubscriber,
    IPlaybackModeControlShowHideEventsSubscriber,
    IMenuButtonEventsSubscriber {

  TAG: string;

  constructor(private publisher: PublisherService) {
    super();
    this.publisher.subscribeToPlaybackModeControlShowHideEvents(this);
  }

  ngOnInit(): void {
  }

  onPlaybackModeControlShowEvent(): void {

    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToScrollEvents(this);
    this.resetTimeout();
  }

  onPlaybackModeControlHideEvent(): void {
    this.releaseSubscriptions();
  }

  private releaseSubscriptions(): void {
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToScrollEvents(this);
    this.clearTheTimeout();
  }

  onScrollDecrease(): void {
    console.log('PlayBackMode-');
    this.resetTimeout();
  }

  onScrollIncrease(): void {
    console.log('PlayBackMode+');
    this.resetTimeout();
  }

  onTimeoutReturnToNowPlayingBar(): void {
    this.publisher.fireOnPlaybackModeControlHideEvent();
    this.publisher.fireOnNowPlayingBarShowEvent();
  }

  onMenuButtonClicked(): void {
    this.releaseSubscriptions();
  }

}
