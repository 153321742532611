<div class="menu-container">

  <div class="menu-body" [ngStyle]="{'margin-top.px':this.menu.scrollMarginOffset}">
    <div class="menu-item  instruction height-{{this.heightOfTopLabel}}x">
      <div class="menu-label  text ">Select one of your available playback device and start listening:</div>
      <div class="menu-icon">
      </div>
    </div>
    <ng-container *ngFor="let menuItem of this.menu.menuItemList; index as i">
      <div class="menu-item {{menuItem.type}}" [ngClass]="{'selected':i===this.menu.selectedIndex}">
        <div class="menu-label text">{{menuItem.label}}</div>
        <div class="menu-icon">
        </div>
      </div>
    </ng-container>
    <div class="menu-item loading-item">
      <img class="loading-icon" src="/assets/icons/loading.gif">
    </div>
  </div>
</div>
