import {Component, OnInit} from '@angular/core';
import {PublisherService} from '../../services/publisher.service';
import {StateService} from '../../services/state.service';
import {INowPlayingSlideEventSubscriber} from '../../interfaces/INowPlayingSlideEventSubscriber';
import {PlayService} from '../../services/play.service';
import {IMenuButtonEventsSubscriber} from '../../interfaces/IMenuButtonEventsSubscriber';
import {IPlayButtonSubscriber} from '../../interfaces/IPlayButtonSubscriber';
import {INextPreviousButtonEventsSubscriber} from '../../interfaces/INextPreviousButtonEventsSubscriber';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {IScrollSubscriber} from '../../interfaces/IScrollSubscriber';
import {StreamingService} from '../../services/streaming.service';
import {ControlBarsEnum} from '../../enums/ControlBarsEnum';
import {IVolumeControlShowHideEventsSubscriber} from '../../interfaces/IVolumeControlShowHideEventsSubscriber';
import {INowPlayingBarShowHideEventsSubscriber} from '../../interfaces/INowPlayingBarShowHideEventsSubscriber';
import {IVolumeControlsAvailableUpdateSubscriber} from '../../interfaces/IVolumeSettingsAvailableUpdateSubscriber';
import {ILoginSubscriber} from '../../interfaces/ILoginSubscriber';

@Component({
  selector: 'app-now-playing-page',
  templateUrl: './now-playing-page.component.html',
  styleUrls: ['./now-playing-page.component.scss']
})
export class NowPlayingPageComponent
  implements OnInit,
    INowPlayingSlideEventSubscriber,
    IMenuButtonEventsSubscriber,
    INextPreviousButtonEventsSubscriber,
    IEnterButtonEventsSubscriber,
    IVolumeControlShowHideEventsSubscriber,
    INowPlayingBarShowHideEventsSubscriber,
    IScrollSubscriber,
    IVolumeControlsAvailableUpdateSubscriber,
    ILoginSubscriber {


  TAG: string = 'NowPlayingPageComponent';

  public volumeControlOn: boolean = false;
  public playbackProgress: number = 30;
  private barWidth = 0;
  public barOffset = 0;
  private currentBar: ControlBarsEnum = ControlBarsEnum.NowPlayingBar;

  constructor(private publisher: PublisherService,
              public stateService: StateService,
              public streamService: StreamingService,
              public playService: PlayService) {
    this.publisher.subscribeToNowPlayingSlideEvents(this);
    this.publisher.subscribeToNowPlayingBarShowHideEvents(this);
    this.publisher.subscribeToLoginEvents(this);
    this.publisher.subscribeToVolumeControlsAvailableUpdateEvents(this);
  }

  private reset(): void {
    this.barWidth = 0;
    this.barOffset = 0;
    this.currentBar = ControlBarsEnum.NowPlayingBar;
  }

  ngOnInit(): void {
  }

  onMenuButtonClicked(): void {
    this.publisher.fireNowPlayingSlideHide();
  }

  onBackwardClicked(): void {
  }

  onEnterClicked(): void {
    this.slideToNextBar();
  }


  private slideToVolumeBar(): void {
    if (this.volumeControlOn) {
      this.setBarWidth();
      this.barOffset = this.getNextPageOffset(ControlBarsEnum.VolumeControlBar);
      this.currentBar = ControlBarsEnum.VolumeControlBar;

      this.fireBarShowEvents(ControlBarsEnum.VolumeControlBar);
      this.publisher.unsubscribeToScrollEvents(this);

    }
  }

  private slideToNextBar(): void {
    this.setBarWidth();
    const nextBar = this.getNextBarOnEnter();
    const previousBar = this.currentBar;
    this.barOffset = this.getNextPageOffset(nextBar);
    this.currentBar = nextBar;

    this.fireBarHideEvents(previousBar);
    this.fireBarShowEvents(nextBar);
  }

  private fireBarShowEvents(nextBar: ControlBarsEnum): void {
    switch (nextBar) {
      case(ControlBarsEnum.NowPlayingBar): {
        return this.publisher.fireOnNowPlayingBarShowEvent();
      }
      case(ControlBarsEnum.VolumeControlBar): {
        return this.publisher.fireOnVolumeControlShowEvent();
      }
      case(ControlBarsEnum.SeekControlBar): {
        return this.publisher.fireOnSeekControlShowEvent();
      }
      case(ControlBarsEnum.DeviceControlBar): {
        return this.publisher.fireOnDeviceControlShowEvent();
      }
      case(ControlBarsEnum.PlaybackModeControlBar): {
        return this.publisher.fireOnPlaybackModeControlShowEvent();
      }
    }
  }

  private fireBarHideEvents(previousBar: ControlBarsEnum): void {
    switch (previousBar) {
      case(ControlBarsEnum.NowPlayingBar): {
        return this.publisher.fireOnNowPlayingBarHideEvent();
      }
      case(ControlBarsEnum.VolumeControlBar): {
        return this.publisher.fireOnVolumeControlHideEvent();
      }
      case(ControlBarsEnum.SeekControlBar): {
        return this.publisher.fireOnSeekControlHideEvent();
      }
      case(ControlBarsEnum.DeviceControlBar): {
        return this.publisher.fireOnDeviceControlHideEvent();
      }
      case(ControlBarsEnum.PlaybackModeControlBar): {
        return this.publisher.fireOnPlaybackModeControlHideEvent();
      }
    }
  }

  private getNextBarOnEnter(): ControlBarsEnum {
    switch (this.currentBar) {
      case(ControlBarsEnum.NowPlayingBar): {
        return ControlBarsEnum.SeekControlBar;
      }
      case(ControlBarsEnum.VolumeControlBar): {
        return ControlBarsEnum.NowPlayingBar;
      }
      case(ControlBarsEnum.SeekControlBar): {
        return ControlBarsEnum.DeviceControlBar;
      }
      case(ControlBarsEnum.DeviceControlBar): {
        return ControlBarsEnum.PlaybackModeControlBar;
      }
      case(ControlBarsEnum.PlaybackModeControlBar): {
        return ControlBarsEnum.NowPlayingBar;
      }
    }
  }

  private getNextPageOffset(nextPage: ControlBarsEnum): number {
    switch (nextPage) {
      case(ControlBarsEnum.NowPlayingBar): {
        return 0;
      }
      case(ControlBarsEnum.VolumeControlBar): {
        return -1 * this.barWidth;
      }
      case(ControlBarsEnum.SeekControlBar): {
        if (this.volumeControlOn) {
          return -2 * this.barWidth;
        }
        return -1 * this.barWidth;
      }
      case(ControlBarsEnum.DeviceControlBar): {
        if (this.volumeControlOn) {
          return -3 * this.barWidth;
        }
        return -2 * this.barWidth;
      }
      case(ControlBarsEnum.PlaybackModeControlBar): {
        if (this.volumeControlOn) {
          return -4 * this.barWidth;
        }
        return -3 * this.barWidth;
      }
    }
  }

  private setBarWidth(): void {
    this.barWidth = document.getElementById('control-container').getBoundingClientRect().width;
  }


  onForwardClicked(): void {
  }

  onScrollDecrease(): void {
    this.slideToVolumeBar();
  }

  onScrollIncrease(): void {
    this.slideToVolumeBar();
  }

  onNowPlayingBarShowEvent(): void {
    this.reset();
    this.publisher.subscribeToScrollEvents(this); //kell a volumeBar-ra való átlépéshez //kell a volumeBar-ra való átlépéshez
  }

  onNowPlayingBarHideEvent(): void {
    this.publisher.unsubscribeToScrollEvents(this); // az aktuális bar-na átadjuk a scroll eventek kezelését
  }

  onVolumeControlShowEvent(): void {
    // throw new Error('Method not implemented.');
  }

  onVolumeControlHideEvent(): void {
    //throw new Error('Method not implemented.');
  }


  onNowPlayingSlideHide(): void {
    this.unsubscribeToClickWheelEventSet();
  }

  onNowPlayingSlideShow(): void {
    this.subscribeToClickWheelEventSet();
    this.reset();
  }

  private subscribeToClickWheelEventSet(): void {
    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
    this.publisher.subscribeToNextPreviousButtonEvents(this);

    this.publisher.subscribeToScrollEvents(this);
  }

  private unsubscribeToClickWheelEventSet(): void {
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToEnterButtonEvents(this);
    this.publisher.unsubscribeToNextPreviousButtonEvents(this);

    this.publisher.unsubscribeToScrollEvents(this);
  }

  onSpotifyAccessTokenChange(): void {
  }

  onLogin(): void {
  }

  onLogout(): void {
  }

  onControlsAvailableUpdate(isAvailable: boolean): void {
    this.volumeControlOn = isAvailable;
  }
}
