import {IPlayBehaviour} from '../interfaces/IPlayBehaviour';
import {PlaybackModeEnum} from '../enums/PlaybackModeEnum';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {MenuItem} from '../models/MenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';

export class DefaultPlayBehavior implements IPlayBehaviour {
  TAG: string;
  playState: PlayStateEnum = PlayStateEnum.NotPlaying;
  playedMs: number;
  playlist: NowPlayingPlaylistModel = new NowPlayingPlaylistModel();
  progress: number;
  trackPlayedLabel: string;
  trackRemainingLabel: string;
  volume: number;

  getVolume(): Promise<number> {
    return Promise.resolve(0);
  }

  nextTrack(): Promise<void> {
    return Promise.resolve(undefined);
  }

  playClicked(): Promise<void> {
    return Promise.resolve(undefined);
  }

  playPlaylist(): Promise<void> {
    return Promise.resolve(undefined);
  }

  playbackMode(): PlaybackModeEnum {
    return undefined;
  }

  previousTrack(): Promise<void> {
    return Promise.resolve(undefined);
  }

  seek(seekToMs: number): void {
  }

  setVolume(volume: number): Promise<void> {
    return Promise.resolve(undefined);
  }

  showCurrentlyPlaying(): Promise<void> {
    return Promise.resolve(undefined);
  }

  startPlayingASingleTrack(): Promise<void> {
    return Promise.resolve(undefined);
  }

  onPlaylistChanged(newPlaylist: NowPlayingPlaylistModel): Promise<void> {
    return Promise.resolve(undefined);
  }

  destruct(): void {
  }

  getMainMenu(): Array<MenuItem> {
    return new Array<MenuItem>(0);
  }

}
