import {EngineBase} from './EngineBase';
import {AuthorizationService} from '../services/authorization.service';
import {DataService} from '../services/data.service';
import {PublisherService} from '../services/publisher.service';
import {DeviceService} from '../services/device.service';
import {TAGResources} from '../enums/TAGResources';
import {InvalidInstantiationException} from '../exceptions/InvalidInstantiationException';
import {IStreamEngine} from '../interfaces/IStreamEngine';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {AlbumModel} from '../models/AlbumModel';
import {ArtistModel} from '../models/ArtistModel';
import {DeviceModel} from '../models/DeviceModel';
import {TrackModel} from '../models/TrackModel';
import {CurrentlyPlayingResponseModel} from './Model/Spotify/CurrentlyPlayingResponseModel';
import {PlaylistModel} from '../models/PlaylistModel';
import {UserModel} from '../models/UserModel';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import {AppleMusicPlaybackStatesEnum} from '../enums/AppleMusicPlaybackStatesEnum';
import {PlayStateEnum} from '../enums/PlayStateEnum';
// @ts-ignore
import MusicKitInstance = MusicKit.MusicKitInstance;
// @ts-ignore
import AppleMusicKit = MusicKit;
// @ts-ignore
import Resource = MusicKit.Resource;
import {AppleMusicHelper} from '../helpers/AppleMusicPlaybackStateTranslator';
import {OrderDirectionEnum} from '../enums/OrderDirectionEnum';


export class AppleMusicEngine extends EngineBase implements IStreamEngine {
  TAG = 'AppleMusicEngine';

  musicKit: MusicKitInstance;

  constructor(
    private authorizationService: AuthorizationService,
    private dataService: DataService,
    private publisher: PublisherService,
    private deviceService: DeviceService,
    instanciatedByTAG: string
  ) {
    super();
    this.deviceService.setActiveDevice(
      new DeviceModel({
        name: '',
        id: 'Local',
        type: 'Local',
        isActive: true,
        volume: 100
      })
    );
    if (instanciatedByTAG !== TAGResources.StreamingService) {
      throw new InvalidInstantiationException();
    }
    this.musicKit = AppleMusicKit.getInstance();


    this.musicKit.addEventListener(AppleMusicKit.Events.mediaPlaybackError, this.mediaPlaybackError.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.playbackStateDidChange, this.playbackStateDidChange.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.mediaItemDidChange, this.mediaItemDidChange.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.queueItemsDidChange, this.queueItemsDidChange.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.queuePositionDidChange, this.queuePositionDidChange.bind(this));
  }


  private translateAppleMusicPlaybackState(e: AppleMusicPlaybackStatesEnum): PlayStateEnum {
    switch (e) {
      case AppleMusicPlaybackStatesEnum.COMPLETED:
      case AppleMusicPlaybackStatesEnum.ENDED:
      case AppleMusicPlaybackStatesEnum.LOADING:
      case AppleMusicPlaybackStatesEnum.NONE:
      case AppleMusicPlaybackStatesEnum.NULL:
      case AppleMusicPlaybackStatesEnum.STALLED:
      case AppleMusicPlaybackStatesEnum.STOPPED:
      case AppleMusicPlaybackStatesEnum.WAITING:
        return PlayStateEnum.NotPlaying;
      case AppleMusicPlaybackStatesEnum.PAUSED:
        return PlayStateEnum.Pause;
      case AppleMusicPlaybackStatesEnum.SEEKING:
      case AppleMusicPlaybackStatesEnum.PLAYING:
        return PlayStateEnum.Play;
    }
  }

  playbackStateDidChange(event: any): void {

    const webPodPlayStateEnum: PlayStateEnum = AppleMusicHelper.translateAppleMusicPlaybackState(event.state);
    this.publisher.fireOnPlayStateUpdateReceived(webPodPlayStateEnum);


    const playbackState = AppleMusicPlaybackStatesEnum[AppleMusicPlaybackStatesEnum[event.state]];

    // sometimes loading just gets stuck, a stop and resume fixes that
    if (playbackState === AppleMusicPlaybackStatesEnum.WAITING) {

    } else {
    }


    if (playbackState === AppleMusicPlaybackStatesEnum.PAUSED || playbackState === AppleMusicPlaybackStatesEnum.STOPPED) {
    } else {
    }
  }

  mediaItemDidChange(event): void {

    console.log(this.TAG, 'Event: mediaItemDidChange', event);
    //this.nowPlayingItem = event.item;
    //this.titleService.setTitle(this.nowPlayingItem.title + ' • ' + this.nowPlayingItem.artistName);
  }

  mediaPlaybackError(event: any): void {
    console.log(this.TAG, 'mediaPlayBackError', event);
  }

  queueItemsDidChange(event: any): void {
    console.log(this.TAG, 'Event: queueItemsDidChange', event);
  }

  queuePositionDidChange(event: any): void {
    console.log(this.TAG, 'Event: queuePositionDidChange', event);
  }

  async getAlbum(albumId: string): Promise<NowPlayingPlaylistModel> {
    const albumResponse = await this.musicKit.api.library.album(albumId, {include: ['tracks']});

    const playListResponse = new NowPlayingPlaylistModel();

    playListResponse.id = albumResponse.attributes.id;
    playListResponse.type = PlayListTypeEnum.album;

    const result = new Array<TrackModel>();

    albumResponse.relationships.tracks.data.forEach(t => {
      playListResponse.tracks.push(new TrackModel({
        id: t.id,
        url: t.href,
        name: t.attributes.name,
        artistName: t.attributes.artistName,
        albumName: t.attributes.albumName,
        trackNumber: t.attributes.trackNumber,
        totalNumberOfTrackInPlaylist: albumResponse.attributes.trackCount,
        genre: t.attributes.genreNames[0],
        artworkUrl: this.prepareArtwork(t.attributes.artwork),
        durationMs: t.attributes.durationInMillis,
        isPlayable: true,
      }));
    });

    return playListResponse;
  }

  async getAlbums(): Promise<Array<AlbumModel>> {
    const result = new Array<AlbumModel>();
    const resultSize = 100;
    let offset = 0;
    let responses: Resource[] = new Array<Resource>();
    this.dataService.startLoading();
    do {
      const promises = new Array<Promise<any>>();
      for (let i = 0; i < 10; i++) {
        promises.push(this.musicKit.api.library.albums(null,
          {
            limit: resultSize,
            offset
          }));
        offset += resultSize;
      }
      responses = await Promise.all(promises);
      responses.forEach(response => response.forEach(r => {
          const album = new AlbumModel({
            id: r.id,
            artist: r.attributes.artistName,
            genre: r.attributes.genreNames.length >= 0 ? r.attributes.genreNames[0] : '',
            name: r.attributes.name,
            imageUrl: this.prepareArtwork(r.attributes.artwork),
            totalTracks: r.attributes.trackCount,
            tracks: new Array<TrackModel>(),
          });
          result.push(album);
        })
      );
    } while (responses[9].length === 100);
    this.dataService.stopLoading();
    return result;
  }

  public async getAlbumsTracks(album: AlbumModel): Promise<TrackModel[]> {
    const albumResponse = await this.musicKit.api.library.album(album.id, {include: ['tracks']});
    const result = new Array<TrackModel>();
    albumResponse.relationships.tracks.data.forEach(t => {
      result.push(new TrackModel({
        id: t.id,
        url: t.href,
        name: t.attributes.name,
        artistName: t.attributes.artistName,
        albumName: t.attributes.albumName,
        trackNumber: t.attributes.trackNumber,
        totalNumberOfTrackInPlaylist: albumResponse.attributes.trackCount,
        genre: t.attributes.genreNames[0],
        artworkUrl: this.prepareArtwork(t.attributes.artwork),
        durationMs: t.attributes.duraionInMillis,
        isPlayable: t.is_playable,
      }));
    });

    console.log(albumResponse);
    return result;
  }

  async getArtists(): Promise<Array<ArtistModel>> {
    const result = new Array<ArtistModel>();
    const resultSize = 100;
    let offset = 0;
    let responses: Resource[] = new Array<Resource>();
    this.dataService.startLoading();
    do {
      const promises = new Array<Promise<any>>();
      for (let i = 0; i < 10; i++) {
        promises.push(this.musicKit.api.library.artists(null,
          {
            limit: resultSize,
            offset
          }));
        offset += resultSize;
      }
      responses = await Promise.all(promises);
      responses.forEach((response: any) => response.forEach(a => {
          const artist = new ArtistModel({
            id: a.id,
            artist: a.attributes.name,
            genre: '',
            name: a.attributes.name,
            imageUrl: '',
          });
          result.push(artist);
        })
      );
    } while (responses[9].length === 100);
    this.dataService.stopLoading();
    return result;
  }

  async getArtistsAlbums(artistId: string): Promise<Array<AlbumModel>> {
    const result = new Array<AlbumModel>();
    this.dataService.startLoading();

    const albumsResponse: any = await this.musicKit.api.library.artist(artistId,
      {
        include: 'albums'
      });

    albumsResponse.relationships.albums.data.forEach(a => {
      const album = new AlbumModel({
        id: a.id,
        artist: a.attributes.artistName,
        genre: a.attributes.genreNames[0],
        name: a.attributes.name,
        imageUrl: this.prepareArtwork(a.attributes.artwork),
        totalTracks: a.attributes.trackCount,
      });
      result.push(album);
    });
    this.dataService.stopLoading();
    return result;
  }

  getCurrentDevice(): Promise<DeviceModel> {
    return Promise.resolve(undefined);
  }

  getCurrentTrack(): Promise<TrackModel> {
    return Promise.resolve(undefined);
  }

  getCurrentlyPlaying(): Promise<CurrentlyPlayingResponseModel> {
    return Promise.resolve(undefined);
  }

  getDevices(): Promise<Array<DeviceModel>> {
    return Promise.resolve(undefined);
  }

  async getPlaylist(id: string): Promise<NowPlayingPlaylistModel> {
    const playListResponse = new NowPlayingPlaylistModel();

    const detailesPlayListResponse: any = await this.musicKit.api.library.playlist(id);
    playListResponse.type = PlayListTypeEnum.playlist;
    playListResponse.id = id;
    playListResponse.name = detailesPlayListResponse.attributes.name;

    const tracks = detailesPlayListResponse.relationships.tracks.data;

    tracks.forEach((t, index) => {
      playListResponse.tracks.push(new TrackModel({
        id: t.id,
        url: t.href,
        name: t.attributes.name,
        artistName: t.attributes.artistName,
        albumName: t.attributes.albumName,
        trackNumber: index + 1,
        totalNumberOfTrackInPlaylist: tracks.length,
        genre: '',
        artworkUrl: this.prepareArtwork(t.attributes.artwork),
        durationMs: t.attributes.durationInMillis,
        isPlayable: true,
      }));
    });

    return playListResponse;
  }

  async getPlaylists(): Promise<Array<PlaylistModel>> {
    const result = new Array<PlaylistModel>();
    const resultSize = 100;
    let offset = 0;
    let responses: Resource[] = new Array<Resource>();
    this.dataService.startLoading();
    do {
      const promises = new Array<Promise<any>>();
      for (let i = 0; i < 10; i++) {
        promises.push(this.musicKit.api.library.playlists(null,
          {
            limit: resultSize,
            offset
          }));
        offset += resultSize;
      }
      responses = await Promise.all(promises);
      responses.forEach((response: any) => response.forEach(p => {
          const playlist = new PlaylistModel({
            id: p.id,
            name: p.attributes.name,
            imageUrl: '',
          });
          result.push(playlist);
        })
      );
    } while (responses[9].length === 100);
    this.dataService.stopLoading();
    return result;
  }

  async getSavedTracks(): Promise<NowPlayingPlaylistModel> {
    const playlist = new NowPlayingPlaylistModel();
    playlist.name = 'Songs';
    playlist.type = PlayListTypeEnum.trackCollection;
    const tracksResult = new Array<TrackModel>();
    const resultSize = 100;
    let offset = 0;
    let responses: Resource[] = new Array<Resource>();
    this.dataService.startLoading();
    let i = 1;
    do {
      const promises = new Array<Promise<any>>();
      for (let i = 0; i < 10; i++) {
        promises.push(this.musicKit.api.library.songs(null,
          {
            limit: resultSize,
            offset
          }));
        offset += resultSize;
      }
      responses = await Promise.all(promises);
      responses.forEach((tracks: any) => tracks.forEach(t => {
          tracksResult.push(
            new TrackModel({
              id: t.id,
              url: t.href,
              name: t.attributes.name,
              artistName: t.attributes.artistName,
              albumName: t.attributes.albumName,
              trackNumber: i,
              totalNumberOfTrackInPlaylist: 0,
              genre: t.attributes.genreNames[0],
              artworkUrl: this.prepareArtwork(t.attributes.artwork),
              durationMs: t.attributes.durationInMillis,
              isPlayable: true,
            })
          );
          i++;
        })
      );
    } while (responses[9].length === 100);

    playlist.tracks = tracksResult.sort(
      this.getOrderFunction<TrackModel>('name', OrderDirectionEnum.ascending)
    );

    playlist.tracks.forEach(t => {
      t.totalNumberOfTrackInPlaylist = i;
    });
    this.dataService.stopLoading();
    return playlist;
  }

  getUser(): Promise<UserModel> {
    return Promise.resolve(undefined);
  }

  getVolume(): Promise<number> {
    return Promise.resolve(0);
  }

  initialize(): Promise<void> {
    return Promise.resolve(undefined);
  }

  isVolumeSettingAvailable(): boolean {
    return false;
  }

  async nextTrack(): Promise<void> {
    await this.musicKit.skipToNextItem();
  }

  async pausePlayback(): Promise<void> {
    await this.musicKit.pause();
  }

  async playPlaylist(playlist: NowPlayingPlaylistModel): Promise<void> {

    switch (playlist.type) {
      case PlayListTypeEnum.album:
      case PlayListTypeEnum.playlist: {

        const songIds = Array<string>();
        for (let i = 0; i < playlist.tracks.length; i++) {
          songIds.push(playlist.tracks[i].id);
        }

        await this.musicKit.setQueue({songs: songIds, startPosition: playlist.currentTrackIndex - 1});
        await this.musicKit.play();
        break;
      }
      case PlayListTypeEnum.trackCollection: {

        const songIds = Array<string>();
        //We hit a querystring limit

        let selectedSongIndex = 0;

        for (let i = playlist.currentTrackIndex; i < Math.min(playlist.tracks.length - 1, playlist.currentTrackIndex + 50); i++) {
          console.log(playlist.tracks[i].name);

          const track = playlist.tracks[i];
          songIds.push(track.id);
          if (i === playlist.currentTrackIndex) {
            selectedSongIndex = songIds.length - 2;
          }
        }

        await this.musicKit.setQueue({songs: songIds});
        await this.musicKit.play();
        break;
      }
    }

  }

  private getQueueIndexForActualPlayIndex

  playTrack(trackId: string): Promise<void> {
    return Promise.resolve(undefined);
  }

  async previousTrack(): Promise<void> {
    await this.musicKit.skipToPreviousItem();
  }

  async resumePlayback(): Promise<void> {
    await this.musicKit.play();
  }

  seek(positionMs: number): Promise<void> {
    return Promise.resolve(undefined);
  }

  setVolume(volume: number): Promise<void> {
    return Promise.resolve(undefined);
  }

  updatePlaylist(): Promise<void> {
    return Promise.resolve(undefined);
  }

  private prepareArtwork(artwork: any): string {

    if (typeof artwork === 'undefined') {
      return '';
    }
    let height = artwork.height;
    let width = artwork.width;
    let url = artwork.url;
    if (height == null || width == null) {
      return url;
    }
    if (artwork.height / 5 > 200) {
      height = height / 5;
      width = width / 5;
    }
    url = url.replace('{h}', height.toString());
    url = url.replace('{w}', width.toString());
    return url;
  }
}

