import {Component, OnInit} from '@angular/core';
import {PublisherService} from '../../../services/publisher.service';
import {IVolumeControlShowHideEventsSubscriber} from '../../../interfaces/IVolumeControlShowHideEventsSubscriber';
import {IScrollSubscriber} from '../../../interfaces/IScrollSubscriber';
import {ControlBarBase} from '../control-base/control-bar-base';
import {PlayService} from '../../../services/play.service';
import {DeviceService} from '../../../services/device.service';
import {StreamingService} from '../../../services/streaming.service';
import {IVolumeControlsAvailableUpdateSubscriber} from '../../../interfaces/IVolumeSettingsAvailableUpdateSubscriber';
import {IMenuButtonEventsSubscriber} from '../../../interfaces/IMenuButtonEventsSubscriber';

@Component({
  selector: 'app-volume-control',
  templateUrl: './volume-control.component.html',
  styleUrls: ['./volume-control.component.scss']
})
export class VolumeControlComponent
  extends ControlBarBase
  implements OnInit,
    IVolumeControlShowHideEventsSubscriber,
    IScrollSubscriber,
    IVolumeControlsAvailableUpdateSubscriber,
    IMenuButtonEventsSubscriber {

  TAG = 'VolumeControlComponent';
  public volumeSettingsAvailable = true;


  constructor(private publisher: PublisherService,
              public playService: PlayService) {
    super();
    this.publisher.subscribeToVolumeControlShowHideEvents(this);
    this.publisher.subscribeToVolumeControlsAvailableUpdateEvents(this);
  }

  onMenuButtonClicked(): void {
    this.releaseSubscriptions();
  }

  onControlsAvailableUpdate(isAvailable: boolean): void {
    this.volumeSettingsAvailable = isAvailable;
  }

  ngOnInit(): void {
  }

  private async sendVolumeRequest(volume: number): Promise<void> {
    if (volume > 100 || volume < 0) {
      return;
    }
    this.playService.behaviorFactory.behavior.volume = volume;
    await this.playService.setVolume(this.playService.behaviorFactory.behavior.volume);
  }

  onVolumeControlHideEvent(): void {
    this.releaseSubscriptions();
  }

  private releaseSubscriptions(): void {
    this.publisher.unsubscribeToScrollEvents(this);
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.clearTheTimeout();
  }

  onVolumeControlShowEvent(): void {
    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToScrollEvents(this);
    this.resetTimeout();
  }

  onScrollDecrease(): void {

    if (this.volumeSettingsAvailable === false) {
      return;
    }

    this.sendVolumeRequest(this.playService.behaviorFactory.behavior.volume - 1).catch(console.error);
    this.resetTimeout();
  }

  onScrollIncrease(): void {

    if (this.volumeSettingsAvailable === false) {
      return;
    }

    this.sendVolumeRequest(this.playService.behaviorFactory.behavior.volume + 1).catch(console.error);
    this.resetTimeout();
  }

  onTimeoutReturnToNowPlayingBar(): void {
    this.publisher.fireOnVolumeControlHideEvent();
    this.publisher.fireOnNowPlayingBarShowEvent();
  }

}
