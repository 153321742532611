import {Component, OnInit} from '@angular/core';
import {ControlBarBase} from '../control-base/control-bar-base';
import {IDeviceControlShowHideEventsSubscriber} from '../../../interfaces/IDeviceControlShowHideEventsSubscriber';
import {PublisherService} from '../../../services/publisher.service';
import {IScrollSubscriber} from '../../../interfaces/IScrollSubscriber';
import {IMenuButtonEventsSubscriber} from '../../../interfaces/IMenuButtonEventsSubscriber';

@Component({
  selector: 'app-device-control',
  templateUrl: './device-control.component.html',
  styleUrls: ['./device-control.component.scss']
})
export class DeviceControlComponent
  extends ControlBarBase
  implements OnInit,
    IScrollSubscriber,
    IDeviceControlShowHideEventsSubscriber,
    IMenuButtonEventsSubscriber {

  TAG = 'DeviceControlComponent';

  constructor(private publisher: PublisherService) {
    super();
    this.publisher.subscribeToDeviceControlShowHideEvents(this);
  }

  ngOnInit(): void {
  }

  onDeviceControlShowEvent(): void {

    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToScrollEvents(this);
    this.resetTimeout();
  }

  onDeviceControlHideEvent(): void {
    this.releaseSubscriptions();
  }

  private releaseSubscriptions(): void {
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToScrollEvents(this);
    this.clearTheTimeout();
  }

  onScrollDecrease(): void {
    console.log('PreviousDevice-');
    this.resetTimeout();
  }

  onScrollIncrease(): void {
    console.log('NextDevice+');
    this.resetTimeout();
  }

  onTimeoutReturnToNowPlayingBar(): void {

    this.publisher.fireOnDeviceControlHideEvent();
    this.publisher.fireOnNowPlayingBarShowEvent();
  }

  onMenuButtonClicked(): void {
    this.releaseSubscriptions();
  }


}
