import {ScrollModel} from './ScrollModel';
import {ScrollingDirectionEnum} from '../enums/ScrollingDirectionEnum';

export class ScrollStateMachine {

  private state = new Array<ScrollModel>(0);
  private sampleSize = 30;
  private fastScrollThresholdMs = 1500;
  private scrollIngDirection = ScrollingDirectionEnum.increase;

  private initialTimeoutId: NodeJS.Timeout;
  private initialTimeoutMs = 1000;
  public initialSeekOn = false;

  public scrollEvent(dir: ScrollingDirectionEnum): void {
    if (dir !== this.scrollIngDirection && this.initialSeekOn === false) {
      this.resetScrollState();
    }
    this.scrollIngDirection = dir;
    this.state.push(new ScrollModel(Date.now()));
    if (this.state.length > this.sampleSize) {
      this.state.shift();
    }
    if (this.initialSeekOn) {
      this.resetInitialTimeout();
    }
  }

  private resetScrollState(): void {
    this.state = new Array<ScrollModel>(0);
  }

  public isFastScrolling(): boolean {

    if (this.initialSeekOn) {
      return true;
    }

    if (this.state.length !== this.sampleSize) {
      return false;
    }

    if (this.state[this.sampleSize - 1].timestamp - this.state[0].timestamp < this.fastScrollThresholdMs) {
      return true;
    }
    return false;
  }

  public onInitialSeekHide(): void {
    console.error('Initial seek ended');
    this.initialSeekOn = false;
    this.resetScrollState();
  };

  public setTheInitialSeekTimeout(): void {
    this.initialTimeoutId = setTimeout(
      this.onInitialSeekHide.bind(this),
      this.initialTimeoutMs
    );
  }

  public clearInitialTimeout(): void {
    clearTimeout(this.initialTimeoutId);
  }

  public resetInitialTimeout(): void {
    this.clearInitialTimeout();
    this.setTheInitialSeekTimeout();
  }
}
