export abstract class ControlBarBase {

  private timeOutId: NodeJS.Timeout;
  private timeOutMs = 5000; ////

  abstract onTimeoutReturnToNowPlayingBar(): void;

  protected setTheReturnTimeout(): void {
    this.timeOutId = setTimeout(
      this.onTimeoutReturnToNowPlayingBar.bind(this),
      this.timeOutMs
    );
  }

  protected clearTheTimeout(): void {
    clearTimeout(this.timeOutId);
  }

  protected resetTimeout(): void {
    this.clearTheTimeout();
    this.setTheReturnTimeout();
  }
}
