import {Component, OnInit} from '@angular/core';
import {IVolumeControlShowHideEventsSubscriber} from '../../../interfaces/IVolumeControlShowHideEventsSubscriber';
import {IScrollSubscriber} from '../../../interfaces/IScrollSubscriber';
import {ISeekControlShowHideEventsSubscriber} from '../../../interfaces/ISeekControlShowHideEventsSubscriber';
import {PublisherService} from '../../../services/publisher.service';
import {ControlBarBase} from '../control-base/control-bar-base';
import {IMenuButtonEventsSubscriber} from '../../../interfaces/IMenuButtonEventsSubscriber';
import {PlayService} from '../../../services/play.service';

@Component({
    selector: 'app-seek-control',
    templateUrl: './seek-control.component.html',
    styleUrls: ['./seek-control.component.scss']
})
export class SeekControlComponent
    extends ControlBarBase
    implements OnInit,
        ISeekControlShowHideEventsSubscriber,
        IScrollSubscriber,
        IMenuButtonEventsSubscriber {

    TAG = 'SeekControlComponent';

    constructor(private publisher: PublisherService,
                public playService: PlayService) {
        super();
        publisher.subscribeToSeekControlShowHideEventsEvents(this);
    }

    private stepMs = 1000;
    private stepMultiplier = 1;

    ngOnInit(): void {
    }


    onScrollDecrease(): void {
        console.log('Seek-');
        this.playService.seek(this.playService.behaviorFactory.behavior.playedMs - this.stepMs * this.stepMultiplier);
        this.resetTimeout();
    }

    onScrollIncrease(): void {
        console.log('Seek+');
        this.playService.seek(this.playService.behaviorFactory.behavior.playedMs + this.stepMs * this.stepMultiplier);
        this.resetTimeout();
    }

    onSeekControlShowEvent(): void {
        this.publisher.subscribeToMenuButtonEvents(this);
        this.publisher.subscribeToScrollEvents(this);
        this.resetTimeout();
    }

    onSeekControlHideEvent(): void {
        this.releaseSubscriptions();
    }

    private releaseSubscriptions(): void {
        this.publisher.unsubscribeToMenuButtonEvents(this);
        this.publisher.unsubscribeToScrollEvents(this);
        this.clearTheTimeout();
    }

    onTimeoutReturnToNowPlayingBar(): void {
        this.publisher.fireOnSeekControlHideEvent();
        this.publisher.fireOnNowPlayingBarShowEvent();
    }

    onMenuButtonClicked(): void {
        this.releaseSubscriptions();
    }

}
