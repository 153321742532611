import { TrackModel } from "./TrackModel";

export class AlbumModel {

    id: string = '';
    artist: string = '';
    genre: string = '';
    name: string = '';
    imageUrl: string = '';
    totalTracks: number = 0;
    tracks: Array<TrackModel> = new Array<TrackModel>();

    public constructor(init?: Partial<AlbumModel>) {
        Object.assign(this, init);
    }
}