export enum MenuItemTypeEnum {
  MainMenuItem = 'main-menu-item',
  MenuItem = 'menu-item',
  PlaylistsCategoryItem = 'playlists-category-item',
  PlaylistItem = 'playlist-item',
  ArtistsCategoryItem = 'artists-category-item',
  ArtistItem = 'artist-item',
  AlbumsCategoryItem = 'albums-category-item',
  AlbumItem = 'album-item',
  SongsCategoryItem = 'songs-category-item',
  SongItem = 'song-item',
  SelectDeviceItem = 'select-device-item',
  DoubleRowItem = 'double-row-item',
  LogoutItem = 'logout-item',
  NowPlaying = 'now-playing-item',
  FeedbackItem = 'feedback-item',
  AboutItem = 'about-item',
  LinkItem = 'link-item',
  EmptyItem = 'empty-item',
}
