<div id="header">
  <div class="header-item play-icon engine-icon">
    <img
      *ngIf="this.authorizationService.spotifyLoggedIn"
      id="spotify-icon" src="assets/logos/Spotify_Icon_RGB_Green.png">

    <img
      *ngIf="this.authorizationService.appleMusicLoggedIn"
      id="apple-music-icon" src="assets/logos/Apple_Music_Icon_RGB_sm_073120.svg">
  </div>
  <div class="header-item menu-name text-2">
    <div class="selected-menu-container">
      <span [innerHTML]="stateService.selectedMenu"></span>
    </div>

  </div>
  <!-- todo: ide az üzenetek jöjjenek és a loading icon-->
  <div class="header-item notification-container">
    <div class=" name">
      <span class="text-4">{{this.deviceService.activeDevice && this.deviceService.activeDevice.name}}</span>
    </div>
    <div class="loading">
      <img *ngIf="this.playService.playSate === PlayStateEnum.Play && this.loadingService.isLoading ===false"
           src="/assets/icons/play-button.svg">
      <img *ngIf="this.playService.playSate === PlayStateEnum.Pause && this.loadingService.isLoading ===false"
           src="/assets/icons/pause.svg">
      <img *ngIf="this.loadingService.isLoading ===true" src="/assets/icons/loading.gif">
    </div>
  </div>
</div>
