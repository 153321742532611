import {IMenuItem} from '../interfaces/IMenuItem';
import {InitialIndex} from './InitialIndex';
import {TextConverterHelper} from '../helpers/TextConverterHelper';
import {ScrollingDirectionEnum} from '../enums/ScrollingDirectionEnum';

export class InitialIndexCatalog {

  initialIndexes: Array<InitialIndex>;

  private format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;


  constructor() {
    this.initialIndexes = new Array<InitialIndex>();
  }

  initializeIndexes(menuItems: Array<IMenuItem>): void {

    let nonAsciiIndexFound = false;
    let numericIndexFound = false;

    this.initialIndexes = new Array<InitialIndex>();

    let initialIndex: InitialIndex = null;

    if (menuItems.length > 0) {
      initialIndex = new InitialIndex(this.getFirstCharOfLabel(menuItems[0].label), 0, menuItems[0].label);
    }

    menuItems.forEach((menuItem, index) => {
      const firstChar = this.getFirstCharOfLabel(menuItem.label);
      if (firstChar !== initialIndex.initial) {
        if (this.containsAllAscii(firstChar) === false) {
          if (nonAsciiIndexFound === false) {
            nonAsciiIndexFound = true;
          } else {
            return;
          }
        }
        if (TextConverterHelper.isNumeric(firstChar) === true) {
          if (numericIndexFound === false) {
            numericIndexFound = true;
          } else {
            return;
          }
        }
        this.initialIndexes.push(initialIndex);
        initialIndex = new InitialIndex(firstChar, index, menuItem.label);
      }
    });

    this.initialIndexes.push(initialIndex);

    //console.log(this.initialIndexes);
  }

  private containsAllAscii(initial: string): boolean {
    return /^[\000-\177]*$/.test(initial);
  }


  public getFirstCharOfLabel(label: string): string {
    return TextConverterHelper.getFirstCharOfLabel(label);
  }

  public getNextInitialIndex(dir: ScrollingDirectionEnum, currentInitial: string): number {
    switch (dir) {
      case ScrollingDirectionEnum.decrease:
        for (let i = 0; i < this.initialIndexes.length; i++) {
          if (this.initialIndexes[i].initial === currentInitial) {
            if ((i - 1) > 0) {
              return this.initialIndexes[i - 1].index;
            } else {
              return this.initialIndexes[0].index;
            }
          }
        }
        //console.error('No initial index found');
        return 0;
      case ScrollingDirectionEnum.increase:

        for (let i = 0; i < this.initialIndexes.length; i++) {
          if (this.initialIndexes[i].initial === currentInitial) {
            if ((i + 1) < this.initialIndexes.length - 1) {
              return this.initialIndexes[i + 1].index;
            } else {
              return this.initialIndexes[this.initialIndexes.length - 1].index;
            }
          }
        }
        //console.error('No initial index found');
        return 0;
      default:
        //console.error('No initial index found');
        return 0;
    }
  }
}
