import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {PublisherService} from '../../services/publisher.service';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {IFeedbackEventsSubscriber} from '../../interfaces/IFeedbackEventsSubscriber';
import {environment} from '../../../environments/environment';
import {IMenuButtonEventsSubscriber} from '../../interfaces/IMenuButtonEventsSubscriber';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit,
  IEnterButtonEventsSubscriber,
  IMenuButtonEventsSubscriber,
  IFeedbackEventsSubscriber {

  public TAG = 'FeedbackComponent';

  public message = '';
  public sender = '';
  public showForm = true;


  constructor(private firestore: AngularFirestore, private publisher: PublisherService) {
    //this.getFeedbacks();
    this.publisher.subscribeToFeedbackEvents(this);
  }

  onMenuButtonClicked(): void {
    this.hideFeedbackForm();
  }

  async getFeedbacks(): Promise<void> {
    /*
    const feedbacks = await this.firestore.collection('feedbacks').get().toPromise();
    for (const doc of feedbacks.docs) {
      console.log(doc.id, '=>', doc.data());
    }
    */
  }

  ngOnInit(): void {
  }

  async send(): Promise<void> {

    await this.firestore
      .collection(environment.firebase.collection)
      .add(
        {
          contact: this.sender,
          feedback: this.message,
          created: Date(),
          created_ts: Date.now(),
          version: environment.version
        }
      );

    this.message = '';
    this.sender = '';

    this.showForm = false;

    setTimeout(function () {
      this.hideFeedbackForm();
    }.bind(this), 2000);

  }

  public backButtonClick(): void {
    setTimeout(function () {
      this.hideFeedbackForm();
    }.bind(this), 100);
  }

  private hideFeedbackForm(): void {
    this.publisher.unsubscribeToEnterButtonEvents(this);
    this.publisher.fireNavigateBackFromFeedback();
    setTimeout(function () {
      this.showForm = true;
    }.bind(this), 1000);
  }

  onEnterClicked(): void {
    this.send();
  }

  onFeedbackHideEvent(): void {
  }

  onFeedbackShowEvent(): void {
    this.publisher.subscribeToEnterButtonEvents(this);
    this.publisher.subscribeToMenuButtonEvents(this);
  }
}
