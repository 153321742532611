<div id="menu-wrapper">
  <app-login [ngStyle]="{'margin-left.px':this.pageOffSet}"></app-login>

  <ng-container *ngFor="let menu of this.menuList">
    <app-menu-page
      [menuModel]="menu"
    >
    </app-menu-page>
  </ng-container>


  <app-now-playing-page [hidden]="this.atSong !== true"></app-now-playing-page>

  <app-select-device-page [hidden]="this.atDeviceSelector !== true"></app-select-device-page>

  <app-feedback [hidden]="this.atFeedback !== true" ></app-feedback>


  <app-about [hidden]="this.atAbout !== true" ></app-about>

  <input type="text"
         style="display: none"
         value="{{this.authorizationService.spotifyAccessToken}}"
         id="access-token-input">
  <input *ngIf="this.deviceService.activeDevice"
         type="text"
         style="display: none"
         value="{{this.deviceService.activeDevice.id}}"
         id="device-id-input">
</div>
