import {IPlayBehaviour} from '../interfaces/IPlayBehaviour';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {PlaybackModeEnum} from '../enums/PlaybackModeEnum';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {StreamingService} from '../services/streaming.service';
import {PublisherService} from '../services/publisher.service';

// @ts-ignore
import MusicKitInstance = MusicKit.MusicKitInstance;
// @ts-ignore
import AppleMusicKit = MusicKit;
// @ts-ignore
import Resource = MusicKit.Resource;
import {AppleMusicHelper} from '../helpers/AppleMusicPlaybackStateTranslator';
import {AppleMusicPlaybackStatesEnum} from '../enums/AppleMusicPlaybackStatesEnum';
import {PlaybackTimeDidChangeModel} from '../models/PlaybackTimeDidChangeModel';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import {MenuItem} from '../models/MenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';

export class AppleMusicPlayBehavior implements IPlayBehaviour {
  public TAG: string;
  public playState: PlayStateEnum;
  public playedMs: number;
  public playlist: NowPlayingPlaylistModel = new NowPlayingPlaylistModel();
  public progress: number;
  public trackPlayedLabel: string;
  public trackRemainingLabel: string;
  public volume: number;
  musicKit: MusicKitInstance;


  private seekRequestTimeoutMs = 500;
  private seekRequestTimeoutId: NodeJS.Timeout;
  private isSeeking = false;


  private lastPreviousRequestedTimestamp = 0;
  private previousThreshold = 3000;


  constructor(private streamingService: StreamingService, private publisher: PublisherService) {

    this.musicKit = AppleMusicKit.getInstance();

    this.musicKit.addEventListener(AppleMusicKit.Events.mediaPlaybackError, this.mediaPlaybackError.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.playbackStateDidChange, this.playbackStateDidChange.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.mediaItemDidChange, this.mediaItemDidChange.bind(this));
    this.musicKit.addEventListener(AppleMusicKit.Events.queueItemsDidChange, this.queueItemsDidChange.bind(this));

    /*
    this.musicKit.addEventListener(AppleMusicKit.Events.playbackProgressDidChange, function (e: any) {
      console.log('playbackProgressDidChange', e);
    });
    this.musicKit.addEventListener(AppleMusicKit.Events.playbackDurationDidChange, function (e: any) {
      console.log('playbackDurationDidChange', e);
    });*/
    this.musicKit.addEventListener(AppleMusicKit.Events.playbackTimeDidChange, this.playbackTimeDidChangeCallback.bind(this));

  }

  getMainMenu(): Array<MenuItem> {

    const mainMenuItems = new Array<MenuItem>();

    mainMenuItems.push(new MenuItem({label: 'Albums', type: MenuItemTypeEnum.AlbumsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Artists', type: MenuItemTypeEnum.ArtistsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Playlists', type: MenuItemTypeEnum.PlaylistsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Now Playing', type: MenuItemTypeEnum.NowPlaying}));
    mainMenuItems.push(new MenuItem({label: 'Songs', type: MenuItemTypeEnum.SongsCategoryItem}));
    //mainMenuItems.push(new MenuItem({label: 'Shuffle Songs', type: MenuItemTypeEnum.MenuItem}));
    mainMenuItems.push(new MenuItem({label: 'Feedback', type: MenuItemTypeEnum.FeedbackItem}));
    mainMenuItems.push(new MenuItem({label: 'About', type: MenuItemTypeEnum.AboutItem}));
    mainMenuItems.push(new MenuItem({label: 'Logout', type: MenuItemTypeEnum.LogoutItem}));

    return mainMenuItems;
  }

  private playbackTimeDidChangeCallback(playBackTime: PlaybackTimeDidChangeModel): void {
    if (Number.isNaN(playBackTime.currentPlaybackDuration)) {
      playBackTime.currentPlaybackDuration = 0;
    }
    if (Number.isNaN(playBackTime.currentPlaybackTimeRemaining)) {
      playBackTime.currentPlaybackTimeRemaining = 0;
    }
    if (Number.isNaN(playBackTime.currentPlaybackTime)) {
      playBackTime.currentPlaybackTime = 0;
    }
    this.periodicPlaybackStatusUpdate(playBackTime);
  }

  destruct(): void {
  }

  getVolume(): Promise<number> {
    return Promise.resolve(0);
  }

  async nextTrack(): Promise<void> {
    switch (this.playlist.type) {
      case PlayListTypeEnum.playlist:
      case PlayListTypeEnum.album:
      case PlayListTypeEnum.trackCollection: {
        if (this.musicKit.player.queue.nextPlayableItemIndex !== undefined) {
          this.playlist.next();
          this.musicKit.skipToNextItem();
        } else {
          this.playStoppedPlayList().catch(console.error);
        }
        //TODO based on play mode do repeat
        //TODO music.player.queue.nextPlayableItemIndex === undefined jump to 0
        break;
      }
    }
  }

  onPlaylistChanged(newPlaylist: NowPlayingPlaylistModel): Promise<void> {
    return Promise.resolve(undefined);
  }

  async playClicked(): Promise<void> {

    if (this.isPlaying()) {
      await this.streamingService.pause();
      this.setPause();

    } else if (this.isPaused()) {
      await this.streamingService.resume();
      this.setPlay();

    } else if (this.isStopped() || this.isNotPlaying()) {
      this.playStoppedPlayList().catch(console.error);
    } else {
      //
    }
  }

  async playPlaylist(): Promise<void> {
    await this.streamingService.playPlaylist(this.playlist);
    this.playState = PlayStateEnum.Play;

  }


  //HUGE TODO
  private periodicPlaybackStatusUpdate(playBackTime: PlaybackTimeDidChangeModel): void {

    if (this.isSeeking === true)
      return;

    this.setProgress(playBackTime);

    this.setPlaybackLabels(playBackTime);

  }

  private setProgress(playBackTime: PlaybackTimeDidChangeModel): void {
    this.playlist.progressMs = playBackTime.currentPlaybackTime * 1000;
    this.playedMs = this.playlist.progressMs;
    this.progress = Math.min(this.playedMs / Math.floor(playBackTime.currentPlaybackDuration * 1000) * 100, 100);
    this.publisher.fireOnUpdatePlayProgressEvent(this.progress);
  }

  private setPlaybackLabels(playBackTime: PlaybackTimeDidChangeModel): void {

    const remainingMs = playBackTime.currentPlaybackTimeRemaining * 1000;

    if (this.progress === 100) {
      this.trackPlayedLabel = this.formatDate(playBackTime.currentPlaybackDuration);
      this.trackRemainingLabel = '-' + this.formatDate(0);
      return;
    }
    this.trackRemainingLabel = '-' + this.formatDate(remainingMs);
    this.trackPlayedLabel = this.formatDate(this.playedMs);
  }


  playbackMode(): PlaybackModeEnum {
    return undefined;
  }

  async previousTrack(): Promise<void> {
    switch (this.playlist.type) {
      case PlayListTypeEnum.trackCollection:
      case PlayListTypeEnum.playlist:
      case PlayListTypeEnum.album: {

        if (this.lastPreviousRequestedTimestamp + this.previousThreshold < Date.now()) {
          this.seek(0);
        } else {
          this.playlist.previous();
          this.musicKit.player.skipToPreviousItem();
        }
        this.lastPreviousRequestedTimestamp = Date.now();
        break;
      }
    }
  }

  seek(seekToMs: number): void {
    this.isSeeking = true;
    if (seekToMs < 0) {
      seekToMs = 0;
    }
    if (seekToMs > this.playlist.currentTrack.durationMs) {
      return;
    }
    this.playlist.progressMs = seekToMs;
    this.playedMs = this.playlist.progressMs;
    this.progress = Math.min(this.playedMs / Math.floor(this.playlist.currentTrack.durationMs) * 100, 100);

    const playbackTimeDidChangeModel = new PlaybackTimeDidChangeModel();
    playbackTimeDidChangeModel.currentPlaybackDuration = this.playlist.progressMs / 1000;
    playbackTimeDidChangeModel.currentPlaybackTime = this.playlist.currentTrack.durationMs / 1000;
    playbackTimeDidChangeModel.currentPlaybackTimeRemaining = (this.playlist.currentTrack.durationMs - this.playlist.progressMs) / 1000;
    this.setPlaybackLabels(playbackTimeDidChangeModel);

    this.clearSeekRequestTimeout();
    this.setSeekRequestTimeout(seekToMs);
  }

  private clearSeekRequestTimeout(): void {
    clearTimeout(this.seekRequestTimeoutId);
  }

  protected setSeekRequestTimeout(seekToMs: number): void {
    this.seekRequestTimeoutId = setTimeout(
      function () {
        this.isSeeking = false;
        this.musicKit.player.seekToTime(seekToMs / 1000);
      }.bind(this),
      this.seekRequestTimeoutMs
    );
  }

  setVolume(volume: number): Promise<void> {
    return Promise.resolve(undefined);
  }

  showCurrentlyPlaying(): Promise<void> {
    return Promise.resolve(undefined);
  }

  startPlayingASingleTrack(): Promise<void> {
    return Promise.resolve(undefined);
  }

  private mediaItemDidChange(event): void {
    console.log(this.TAG, 'Event: mediaItemDidChange', this.musicKit.player.nowPlayingItem.container.id, this.playlist);
    this.playlist.setCurrentTrackByTrackId(this.musicKit.player.nowPlayingItem.container.id);
  }


  queueItemsDidChange(event: any): void {
    console.log(this.TAG, 'Event: queueItemsDidChange', event);
  }


  mediaPlaybackError(event: any): void {
    console.log('mediaPlayBackError', event);
  }

  playbackStateDidChange(event: any): void {

    const webPodPlayStateEnum: PlayStateEnum = AppleMusicHelper.translateAppleMusicPlaybackState(event.state);
  }

  private setPause(): void {
    this.playState = PlayStateEnum.Pause;
  }

  private setPlay(): void {
    this.playState = PlayStateEnum.Play;
  }

  private isPlaying(): boolean {
    return this.playState === PlayStateEnum.Play;
  }

  private isPaused(): boolean {
    return this.playState === PlayStateEnum.Pause;
  }

  private isStopped(): boolean {
    return this.playState === PlayStateEnum.Stopped;
  }

  private isNotPlaying(): boolean {
    return this.playState === PlayStateEnum.NotPlaying;
  }

  private async playStoppedPlayList(): Promise<void> {
    this.playlist.currentTrackIndex = 0;
    await this.playPlaylist();
  }

  private formatDate(ms: number): string {
    const s = Math.floor(ms / 1000);
    const minutes = Math.floor(s / 60).toString();
    let seconds = (s % 60).toString();
    if (seconds.length === 1) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  }
}
