import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public isLoading: boolean = false;

    constructor() {
    }

    public startLoading() {
        this.isLoading = true;
    }

    public stopLoading() {
        this.isLoading = false;
    }
}
