import {IMenu} from '../interfaces/IMenu';
import {IMenuItem} from '../interfaces/IMenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';
import {MenuItem} from './MenuItem';

import * as faker from 'faker';
import {TrackModel} from './TrackModel';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {InvalidModelStateException} from '../exceptions/InvalidModelStateException';
import {InitialIndexCatalog} from './InitialIndexCatalog';
import {ScrollStateMachine} from './ScrollStateMachine';
import {ScrollingDirectionEnum} from '../enums/ScrollingDirectionEnum';
import {TextConverterHelper} from '../helpers/TextConverterHelper';

export class MenuModel implements IMenu {

  initialIndexCatalog: InitialIndexCatalog;
  menuItemList: Array<IMenuItem>;
  menuItemWindow = new Array<IMenuItem>(0);

  name: string;
  selectedMenuItem: IMenuItem;
  selectedIndex: number;
  pageOffSet = 0;

  private inTransition = false;

  private defaultOffsetTop = 0;
  public scrollMarginOffset = 0;
  private heightOfMenuItem = 0;

  public scrollState = new ScrollStateMachine();
  private fastScrollStepSize = 5;

  public currentInitial = '';

  constructor(name: string, menuItemList: Array<IMenuItem>, defaultOffset = 0) {

    this.menuItemList = menuItemList;
    this.name = name;
    this.defaultOffsetTop = defaultOffset;
    this.selectedIndex = 0;
    this.selectedMenuItem = this.menuItemList[this.selectedIndex];

    this.initialIndexCatalog = new InitialIndexCatalog();
    this.initialIndexCatalog.initializeIndexes(this.menuItemList);
    this.setCurrentInitial();

    for (let i = 0; i < 9; i++) {
      this.menuItemWindow.push(new MenuItem({type: MenuItemTypeEnum.EmptyItem, label: ''}));
    }
    this.setMenuWindow();
  }

  public setMenuItems(menuItemList: Array<IMenuItem>): void {

    if (this.menuItemList && this.menuItemList[this.selectedIndex]) {
      const oldLabel = this.menuItemList[this.selectedIndex].label;
      menuItemList.forEach((item, index) => {
        if (item.label === oldLabel) {
          this.selectedIndex = index;
        }
      });
      if (this.selectedIndex > menuItemList.length - 1) {
        this.selectedIndex = menuItemList.length - 1;
      }
      this.menuItemList = menuItemList;
      this.selectedMenuItem = this.menuItemList[this.selectedIndex];
    } else {
      this.selectedIndex = 0;
      this.menuItemList = menuItemList;
      this.selectedMenuItem = this.menuItemList[this.selectedIndex];

    }
    this.setMenuWindow();
  }

  private isUpperWindowItemSelected(): boolean {
    if (this.menuItemWindow.length > 0) {
      return this.menuItemWindow[0].uuid === this.menuItemList[this.selectedIndex + 1].uuid
        || this.menuItemWindow[0].uuid === this.menuItemList[this.selectedIndex + this.fastScrollStepSize].uuid;

    }
    return false;
  }

  private isLowerWindowItemSelected(): boolean {
    if (this.menuItemWindow.length > 0) {
      return this.menuItemWindow[this.menuItemWindow.length - 1].uuid === this.menuItemList[this.selectedIndex - 1].uuid
        || this.menuItemWindow[this.menuItemWindow.length - 1].uuid === this.menuItemList[this.selectedIndex - this.fastScrollStepSize].uuid;
    }
    return false;
  }

  private slideWindowDown(): void {
    for (let i = 0, j = 8; i < 9; j--, i++) {
      this.menuItemWindow[i] = this.menuItemList[this.selectedIndex - j];
    }
  }

  private slideWindowUp(): void {
    for (let i = 0; i < 9 && i < this.menuItemList.length; i++) {
      this.menuItemWindow[i] = this.menuItemList[this.selectedIndex + i];
    }
  }

  private setMenuWindow(): void {
    for (let i = 0; i < 9 && i < this.menuItemList.length; i++) {
      this.menuItemWindow[i] = this.menuItemList[i];
    }
  }

  private SetHeightOfMenuItem(): void {
    if (this.heightOfMenuItem === 0) {
      this.heightOfMenuItem = document.getElementsByClassName('menu-item')[0].getBoundingClientRect().height;
    }
  }

  onScrollIncrease(): void {

    this.scrollState.scrollEvent(ScrollingDirectionEnum.increase);

    if (this.selectedIndex === this.menuItemList.length - 1) {
      return;
    }

    this.setIncreasedSelectedMenuIndex();

    this.selectedMenuItem = this.menuItemList[this.selectedIndex];

    this.SetHeightOfMenuItem();
    this.SetScrollMarginOffsetOnIncrease();
    this.setCurrentInitial();
  }


  onScrollDecrease(): void {

    this.scrollState.scrollEvent(ScrollingDirectionEnum.decrease);

    if (this.selectedIndex === 0) {
      return;
    }
    this.setDecreasedSelectedMenuIndex();
    this.selectedMenuItem = this.menuItemList[this.selectedIndex];

    this.SetHeightOfMenuItem();
    this.SetScrollMarginOffsetOnDecrease();
    this.setCurrentInitial();
  }

  private setIncreasedSelectedMenuIndex(): void {
    if (this.scrollState.isFastScrolling() === false) {
      this.selectedIndex++;
    } else if (this.scrollState.initialSeekOn) {

      this.selectedIndex = this.initialIndexCatalog.getNextInitialIndex(
        ScrollingDirectionEnum.increase,
        this.currentInitial);

    } else {
      if (this.selectedIndex + this.fastScrollStepSize <= this.menuItemList.length - 1) {
        this.selectedIndex += this.fastScrollStepSize;
      } else {
        this.selectedIndex = this.menuItemList.length - 1;
      }
      if (this.currentInitial !== this.getCurrentInitial()) {
        this.scrollState.initialSeekOn = true;
        this.scrollState.setTheInitialSeekTimeout();
      }
    }
  }

  private setDecreasedSelectedMenuIndex(): void {
    if (this.scrollState.isFastScrolling() === false) {
      this.selectedIndex--;
    } else if (this.scrollState.initialSeekOn) {

      this.selectedIndex = this.initialIndexCatalog.getNextInitialIndex(
        ScrollingDirectionEnum.decrease,
        this.currentInitial);

    } else {
      if (this.selectedIndex - this.fastScrollStepSize > 0) {
        this.selectedIndex -= this.fastScrollStepSize;
      } else {
        this.selectedIndex = 0;
      }
      if (this.currentInitial !== this.getCurrentInitial()) {
        this.scrollState.initialSeekOn = true;
        this.scrollState.setTheInitialSeekTimeout();
      }
    }
  }

  private getCurrentInitial(): string {
    if (this.menuItemList.length > 0) {
      return TextConverterHelper.getFirstCharOfLabel(this.menuItemList[this.selectedIndex].label);
    }
    return '';
  }

  private setCurrentInitial(): void {
    if (this.menuItemList.length > 0) {
      this.currentInitial = TextConverterHelper.getFirstCharOfLabel(this.menuItemList[this.selectedIndex].label);
    }
  }

  private SetScrollMarginOffsetOnDecrease(): void {

    if (this.selectedIndex !== 0) {

      if (this.scrollState.initialSeekOn) {
        this.slideWindowUp();
        return;
      }

      if (this.scrollMarginOffset < 0) {

        if (this.isUpperWindowItemSelected()) {
          this.slideWindowUp();
          this.scrollMarginOffset += this.heightOfMenuItem;
        }
      }

    } else {
      this.slideWindowUp();
      this.scrollMarginOffset = 0;
    }
  }

  private SetScrollMarginOffsetOnIncrease(): void {

    if (this.selectedIndex + this.defaultOffsetTop > 8) {

      const scrollMarginOffsetToBe = (this.selectedIndex + this.defaultOffsetTop - 8) * -this.heightOfMenuItem;
      // we only decrease the scrollMarginOffset in case the top selectedMenuItem
      // would be out of the screen


      if (this.scrollState.initialSeekOn) {
        this.slideWindowDown();
        return;
      }
      if (this.isLowerWindowItemSelected()) {
        //then we scroll down
        this.slideWindowDown();
        this.scrollMarginOffset = scrollMarginOffsetToBe;
      }
    } else {
      if (this.scrollMarginOffset < 0) {
        return;
      }
      this.scrollMarginOffset = 0;
    }
  }

  onBackwardClicked(): void {
  }

  onForwardClicked(): void {
  }

  onMenuClicked(): void {
  }

  onPlayClicked(): void {
  }

  //TODO Delete this is just a dummy
  getChildMenu(): MenuModel {

    switch (this.selectedMenuItem.type) {
      case MenuItemTypeEnum.AlbumsCategoryItem: {

        const mainMenuItems = new Array<MenuItem>();

        for (let i = 0; i < 12; i++) {
          mainMenuItems.push(new MenuItem(
            {
              label: faker.vehicle.manufacturer(),
              type: MenuItemTypeEnum.AlbumItem
            }
          ));
        }
        return new MenuModel('Albums', mainMenuItems);
      }
      case MenuItemTypeEnum.ArtistsCategoryItem: {

        const mainMenuItems = new Array<MenuItem>();
        for (let i = 0; i < 20; i++) {
          mainMenuItems.push(
            new MenuItem(
              {
                label: faker.vehicle.manufacturer(),
                type: MenuItemTypeEnum.AlbumItem
              }
            )
          );
        }
        return new MenuModel('Artists', mainMenuItems);
      }
      case MenuItemTypeEnum.AlbumItem:
        const mainMenuItems = new Array<MenuItem>();
        for (let i = 0; i < 20; i++) {
          mainMenuItems.push(
            new MenuItem(
              {
                label: faker.vehicle.manufacturer(),
                type: MenuItemTypeEnum.AlbumItem
              }
            )
          );
        }
        return new MenuModel(this.selectedMenuItem.label, mainMenuItems);

      case MenuItemTypeEnum.SongsCategoryItem: {

        const mainMenuItems = new Array<MenuItem>();
        for (let i = 0; i < 20; i++) {
          mainMenuItems.push(
            new MenuItem(
              {
                label: faker.vehicle.manufacturer(),
                type: MenuItemTypeEnum.AlbumItem
              }
            )
          );
        }
        return new MenuModel('Songs', mainMenuItems);
      }
      case MenuItemTypeEnum.SongItem: {
        const mainMenuItems = new Array<MenuItem>();
        for (let i = 0; i < 20; i++) {
          mainMenuItems.push(
            new MenuItem(
              {
                label: faker.vehicle.manufacturer(),
                type: MenuItemTypeEnum.AlbumItem
              }
            )
          );
        }
        return new MenuModel('Songs', mainMenuItems);

      }
      case MenuItemTypeEnum.AboutItem: {

        const mainMenuItems = new Array<MenuItem>();

        mainMenuItems.push(
          new MenuItem({
            label: 'Protect the environment by repair of devices before they become waste.',
            type: MenuItemTypeEnum.DoubleRowItem
          })
        );
        mainMenuItems.push(
          new MenuItem({
            label: 'Enlight Digital Studios',
            type: MenuItemTypeEnum.EmptyItem
          })
        );

        return new MenuModel('About', mainMenuItems);
      }
      default: {

        const mainMenuItems = new Array<MenuItem>();
        for (let i = 0; i < 20; i++) {
          mainMenuItems.push(
            new MenuItem({
              label: faker.vehicle.manufacturer(),
              type: MenuItemTypeEnum.AlbumItem
            })
          );
        }
        return new MenuModel(faker.company.companyName(), mainMenuItems);
      }
    }
  }

  getSong(): TrackModel {
    if (this.selectedMenuItem.type === MenuItemTypeEnum.SongItem) {
      return new TrackModel({
        id: faker.random.alpha({count: 20}),
        name: faker.name.findName(),
        artistName: faker.name.findName(),
        albumName: faker.address.country(),
        artworkUrl: faker.image.nature(),
        durationMs: faker.random.number(600),
        isPlayable: true,
        playState: PlayStateEnum.NotPlaying,
        trackNumber: faker.random.number(20)
      });
    }
    throw new InvalidModelStateException();
  };

  onEnterClicked(): void {
  }
}
