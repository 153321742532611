export enum SpotifyURLResources {
  'UserProfile' = 'https://api.spotify.com/v1/me',
  'UsersSavedAlbums' = 'https://api.spotify.com/v1/me/albums',
  'UsersArtists' = 'https://api.spotify.com/v1/me/following',
  'ArtistsAlbums' = 'https://api.spotify.com/v1/artists/{id}/albums',
  'MultipleAlbums' = 'https://api.spotify.com/v1/albums',
  'UsersDevices' = 'https://api.spotify.com/v1/me/player/devices',
  'TransferDevice' = 'https://api.spotify.com/v1/me/player',
  'PlaybackInfo' = 'https://api.spotify.com/v1/me/player',
  'CurrentlyPlaying' = 'https://api.spotify.com/v1/me/player/currently-playing',
  'SetVolume' = 'https://api.spotify.com/v1/me/player/volume',
  'PlayResume' = 'https://api.spotify.com/v1/me/player/play',
  'Pause' = 'https://api.spotify.com/v1/me/player/pause',
  'PlayTrack' = 'https://api.spotify.com/v1/me/player/play',
  'Seek' = 'https://api.spotify.com/v1/me/player/seek',
  'Previous' = 'https://api.spotify.com/v1/me/player/previous',
  'Next' = 'https://api.spotify.com/v1/me/player/next',
  'GetAlbum' = 'https://api.spotify.com/v1/albums/',
  'GetPlaylist' = 'https://api.spotify.com/v1/playlists/',
  'GetSavedTracks' = 'https://api.spotify.com/v1/me/tracks',
  'GetUsersPlayLists' = 'https://api.spotify.com/v1/me/playlists'
}
