///  <reference types="@types/spotify-api"/>

import {IStreamEngine} from '../interfaces/IStreamEngine';
import {UserModel} from '../models/UserModel';
import {DataService} from '../services/data.service';
import {AuthorizationService} from '../services/authorization.service';
import {PublisherService} from '../services/publisher.service';
import {TokenExpiredException} from '../exceptions/TokenExpiredException';
import {SpotifyURLResources} from '../enums/SpotifyURLResources';
import {TAGResources} from '../enums/TAGResources';
import {InvalidInstantiationException} from '../exceptions/InvalidInstantiationException';
import {AlbumModel} from '../models/AlbumModel';
import {EngineBase} from './EngineBase';
import {OrderDirectionEnum} from '../enums/OrderDirectionEnum';
import {TrackModel} from '../models/TrackModel';
import {StringResources} from '../enums/StringResources';
import {DeviceService} from '../services/device.service';
import {DeviceModel} from '../models/DeviceModel';
import {IDeviceChangedSubscriber} from '../interfaces/IDeviceChangedSubscriber';
import {IDeviceBrowserRequestEventsSubscriber} from '../interfaces/IDeviceBrowserRequestEventsSubscriber';
import {PlayStateResponseModel} from './Model/Spotify/PlayStateResponseModel';
import {HttpException} from '../exceptions/HttpException';
import {ExceptionCodesEnum} from '../exceptions/ExceptionCodesEnum';
import {IVolumeControlsAvailableUpdateSubscriber} from '../interfaces/IVolumeSettingsAvailableUpdateSubscriber';
import {CurrentlyPlayingResponseModel} from './Model/Spotify/CurrentlyPlayingResponseModel';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {ArtistModel} from '../models/ArtistModel';
import {PlaylistModel} from '../models/PlaylistModel';
import {IPlayProgressEventSubscriber} from '../interfaces/IPlayProgressEventSubscriber';
import {MenuItem} from '../models/MenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';
import {StateService} from '../services/state.service';

/**
 * Should only be instantiated by StreamingService
 */
export class SpotifyEngine extends EngineBase
  implements IStreamEngine,
    IDeviceChangedSubscriber,
    IDeviceBrowserRequestEventsSubscriber,
    IVolumeControlsAvailableUpdateSubscriber,
    IPlayProgressEventSubscriber {

  TAG = 'SpotifyEngine';

  private player: Spotify.SpotifyPlayer;
  private volumeSettingsAvailable = true;
  private thisBrowserDeviceId = '';

  private updateDevicesInterval: any = null;
  private updateDevicesIntervalMs = 2000;


  private lastSpotifyMessage = 0;
  private lastSpotifyMessageType = '';
  private spotifyMessageThreshold = 1000; // ms


  private lastVolumeRequestTimestamp = 0;
  private volumeRequestTimeoutMs = 500;
  private volumeRequestTimeoutId: NodeJS.Timeout;

  private lastSeekRequestTimestamp = 0;
  private seekRequestTimeoutMs = 1000;
  private seekRequestTimeoutId: NodeJS.Timeout;

  constructor(
    private authorizationService: AuthorizationService,
    private dataService: DataService,
    private publisher: PublisherService,
    private deviceService: DeviceService,
    private stateService: StateService,
    instanciatedByTAG: string
  ) {
    super();

    if (instanciatedByTAG !== TAGResources.StreamingService) {
      throw new InvalidInstantiationException();
    }

    window.onSpotifyWebPlaybackSDKReady = initWebPlayer.bind(this);

    function initWebPlayer(): void {
      this.initializeSpotifyWebPlayer();
    }

    this.publisher.subscribeToDeviceChangedEvents(this);
    this.publisher.subscribeToDeviceBrowserRequestEvents(this);
    this.publisher.subscribeToPlayProgressEvents(this);
  }

  onUpdatePlayProgressEvent(playProgressPercent: number): void {
    if (playProgressPercent === 100) {
      this.updatePlaylist();
    }
  }


  onControlsAvailableUpdate(isAvailable: boolean): void {
    this.volumeSettingsAvailable = isAvailable;
  }

  onDeviceRefreshRequestEvent(): void {
    this.getDevices().catch(console.error);
    this.updateDevicesInterval = setInterval(function callback(): void {
      this.getDevices().catch(console.error);
    }.bind(this), this.updateDevicesIntervalMs);
  }

  onDeviceRefreshCancelEvent(): void {
    clearInterval(this.updateDevicesInterval);
  }

  private initializeSpotifyWebPlayer(): void {


    this.player = new Spotify.Player({
      name: StringResources.ApplicationName,
      getOAuthToken: function cb(callback): void {
        callback(this.authorizationService.spotifyAccessToken);
      }.bind(this),
      volume: 1,
    });

    (window as any).spotifyPlayer = this.player;

    const button = document.getElementById('click-wheel');

    const enterButton = document.getElementById('enter-button');
    button.addEventListener('click', () => {
      // The application will have to explicitly call `player.activateElement()`,
      // since the call to Spotify Web API will be deferred.
      (window as any).spotifyPlayer.activateElement();
      setTimeout(() => {
      }, 10000);
    });
    enterButton.addEventListener('click', () => {
      // The application will have to explicitly call `player.activateElement()`,
      // since the call to Spotify Web API will be deferred.
      (window as any).spotifyPlayer.activateElement();

      setTimeout(() => {
      }, 10000);
    });


    this.player.addListener(
      'initialization_error', (message => {
        console.error('initialization_error', message);
      }).bind(this)
    );
    this.player.addListener(
      'authentication_error', (message => {
        console.error('authentication_error', message);
      }).bind(this)
    );
    this.player.addListener(
      'account_error', (response => {
        console.error('account_error', response);
        if (response.message === 'This functionality is restricted to premium users only') {
          console.error('IN')
          this.stateService.setSelectedMenuName('<span style="font-size:10px"><small>Spotify Premium required</small></span>');
        }
      }).bind(this)
    );
    this.player.addListener(
      'playback_error', (message => {
        console.error('playback_error', message);
      }).bind(this)
    );

    // Playback status updates
    this.player.addListener(
      'player_state_changed', (message => {
        //   console.log('player_state_changed', message);
      }).bind(this)
    );


    // Called when connected to the player created beforehand successfully
    this.player.addListener(
      'ready', function readyCallback(deviceId: any): void {
        this.thisBrowserDeviceId = deviceId.device_id;
      }.bind(this)
    );

    // Not Ready
    this.player.addListener('not_ready', function notReadyCallback(deviceId): void {
      console.log('Device ID has gone offline', deviceId);
    }.bind(this));

    // Connect to the player created beforehand, this is equivalent to
    // creating a new device which will be visible for Spotify Connect
    this.player.connect();
  }

  private async sendSeekRequest(positionMs: number): Promise<void> {
    try {
      return await this._seek(positionMs);
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
      }
      return await this._seek(positionMs);
    }
  }

  public async seek(positionMs: number): Promise<void> {


    this.clearSeekRequestTimeout();
    this.setSeekRequestTimeout(positionMs);

  }

  private async _seek(positionMs: number): Promise<void> {
    await this.dataService.put<any>(
      SpotifyURLResources.Seek + `?position_ms=${positionMs}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }

  public async previousTrack(): Promise<void> {
    try {
      return await this._previousTrack();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
      }
      return await this._previousTrack();
    }
  }

  private async _previousTrack(): Promise<void> {
    await this.dataService.post<any>(
      SpotifyURLResources.Previous + `?device_id=${this.deviceService.activeDevice.id}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );

    setTimeout(function () {
      this.updatePlaylist();
    }.bind(this), 1000);
  }

  public async nextTrack(): Promise<void> {
    try {
      return await this._nextTrack();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
      }
      return await this._nextTrack();
    }
  }

  private async _nextTrack(): Promise<void> {
    await this.dataService.post<any>(
      SpotifyURLResources.Next + `?device_id=${this.deviceService.activeDevice.id}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
    setTimeout(function () {
      this.updatePlaylist();
    }.bind(this), 1000);
  }

  public async resumePlayback(): Promise<void> {
    try {
      await this._resumePlayback();
      await this.updatePlaylist();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
      }
    }
  }

  private async _resumePlayback(): Promise<void> {
    await this.dataService.put<any>(
      SpotifyURLResources.PlayResume + `?device_id=${this.deviceService.activeDevice.id}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }

  public async pausePlayback(): Promise<void> {
    try {
      await this._pausePlayback();
      await this.updatePlaylist();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
      }
    }
  }

  private async _pausePlayback(): Promise<void> {
    await this.dataService.put<any>(
      SpotifyURLResources.Pause + `?device_id=${this.deviceService.activeDevice.id}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }


  public async playTrack(trackId: string): Promise<void> {
    try {
      const result = await this._playTrack(trackId);
      this.publisher.fireOnPlayStateUpdateReceived(PlayStateEnum.Play);
      return result;
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        await this._playTrack(trackId);
        this.publisher.fireOnPlayStateUpdateReceived(PlayStateEnum.Play);
      }
    }
  }

  private async _playTrack(trackId: string): Promise<void> {
    await this.dataService.put<any>(
      SpotifyURLResources.PlayTrack + `?device_id=${this.deviceService.activeDevice.id}`,
      JSON.stringify({uris: [trackId]}),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }

  public async playPlaylist(playList: NowPlayingPlaylistModel): Promise<void> {
    try {
      await this._playPlaylist(playList);
      setTimeout(function delayedTimeout(): void {
        this.updatePlaylist();
      }.bind(this), 2000);
      //await this.updatePlaylist();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        await this._playPlaylist(playList);
        setTimeout(function delayedTimeout(): void {
          this.updatePlaylist();
        }.bind(this), 2000);

      }
    }
  }


  private async _playPlaylist(playlist: NowPlayingPlaylistModel): Promise<void> {
    let contextUri = '';
    let request = '';
    switch (playlist.type) {
      case PlayListTypeEnum.album: {
        contextUri = 'spotify:album:' + playlist.id;
        request = JSON.stringify({context_uri: contextUri, offset: {position: playlist.currentTrackIndex}});
        break;
      }
      case PlayListTypeEnum.playlist: {
        contextUri = 'spotify:playlist:' + playlist.id;
        request = JSON.stringify({context_uri: contextUri, offset: {position: playlist.currentTrackIndex}});
        break;
      }
      case PlayListTypeEnum.trackCollection: {
        const trackIds = playlist.currentAndUpcomingTrackIds;
        trackIds.forEach((tid, i) => {
          trackIds[i] = 'spotify:track:' + trackIds[i]
        });
        request = JSON.stringify({uris: trackIds, position_ms: 0});
        break;
      }
    }
    const url = SpotifyURLResources.PlayTrack + `?device_id=${this.deviceService.activeDevice.id}`;

    await this.dataService.put<any>(
      url,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }


  async getUser(): Promise<UserModel> {
    try {
      return await this._getUser();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getUser();
      }
    }
  }

  private async _getUser(): Promise<UserModel> {
    const response = await this.dataService.get<any>(
      SpotifyURLResources.UserProfile,
      this.authorizationService.getAuthorizationHeaders()
    );

    return new UserModel({
      email: response.email,
      id: response.id,
      userName: response.display_name,
    });
  }

  async getAlbums(): Promise<Array<AlbumModel>> {
    try {
      return await this._getAlbums();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getAlbums();
      }
    }
  }

  private async _getAlbums(): Promise<Array<AlbumModel>> {
    const limitQueryString = '?limit=50';
    const result = new Array<AlbumModel>();
    let url = SpotifyURLResources.UsersSavedAlbums + limitQueryString;
    do {
      const response = await this.dataService.get<SpotifyApi.UsersSavedAlbumsResponse>(
        url,
        this.authorizationService.getAuthorizationHeaders()
      );
      response.items.forEach((a) => {
        const tracks = new Array<TrackModel>();
        a.album.tracks.items.forEach((t, index) => {
          tracks.push(
            new TrackModel({
              id: t.id,
              url: t.uri,
              name: t.name,
              artistName: t.artists[0].name,
              albumName: a.album.name,
              trackNumber: index + 1,
              totalNumberOfTrackInPlaylist: a.album.tracks.total,
              genre: a.album.genres[0],
              artworkUrl: a.album.images[0].url,
              durationMs: t.duration_ms,
              isPlayable: t.is_playable,
            })
          );
        });
        const album = new AlbumModel({
          id: a.album.id,
          artist: a.album.artists[0].name,
          genre: a.album.genres[0],
          name: a.album.name,
          imageUrl: a.album.images[0].url,
          totalTracks: a.album.tracks.total,
          tracks,
        });
        result.push(album);
      });
      url = response.next;
    } while (url != null);

    try {
      result.sort(
        this.getOrderFunction<AlbumModel>('name', OrderDirectionEnum.ascending)
      );
    } catch (e) {
      console.log('SortError', e);
    }

    return result;
  }


  async getPlaylists(): Promise<PlaylistModel[]> {
    try {
      return await this._getPlaylists();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getPlaylists();
      }
    }
  }

  private async _getPlaylists(): Promise<Array<PlaylistModel>> {
    const limitQueryString = '?limit=50';
    const result = new Array<PlaylistModel>();
    let url = SpotifyURLResources.GetUsersPlayLists + limitQueryString;
    do {
      const response = await this.dataService.get<SpotifyApi.ListOfUsersPlaylistsResponse>(
        url,
        this.authorizationService.getAuthorizationHeaders()
      );
      response.items.forEach((p) => {

        const playlist = new PlaylistModel({
          id: p.id,
          name: p.name,
          imageUrl: this.imageResolver(p.images),
        });
        result.push(playlist);
      });
      url = response.next;
    } while (url != null);

    try {
      result.sort(
        this.getOrderFunction<PlaylistModel>('name', OrderDirectionEnum.ascending)
      );
    } catch (e) {
      console.log('SortError', e);
    }

    return result;
  }

  private imageResolver(imageArray: SpotifyApi.ImageObject[]): string {
    if (imageArray.length === 0) {
      return '';
    } else {
      return imageArray[0].url;
    }
  }

  async getArtists(): Promise<Array<ArtistModel>> {
    try {
      return await this._getArtistModel();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getArtistModel();
      }
    }
  }

  private async _getArtistModel(): Promise<Array<ArtistModel>> {
    const limitQueryString = '?limit=50&type=artist';
    const result = new Array<ArtistModel>();
    let url = SpotifyURLResources.UsersArtists + limitQueryString;
    do {
      const response = await this.dataService.get<SpotifyApi.UsersFollowedArtistsResponse>(
        url,
        this.authorizationService.getAuthorizationHeaders()
      );
      const pagingObject = response.artists;
      pagingObject.items.forEach((a) => {
        const artist = new ArtistModel({
          id: a.id,
          artist: a.name,
          genre: a.genres[0],
          name: a.name,
          imageUrl: a.images[0].url,
        });
        result.push(artist);
      });
      url = pagingObject.next;
    } while (url != null);

    try {
      result.sort(
        this.getOrderFunction<ArtistModel>('name', OrderDirectionEnum.ascending)
      );
    } catch (e) {
      console.log('SortError', e);
    }

    return result;
  }

  async getArtistsAlbums(artistId: string): Promise<Array<AlbumModel>> {
    try {
      return await this._getArtistsAlbums(artistId);
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
        return await this._getArtistsAlbums(artistId);
      }
    }
  }

  private async _getArtistsAlbums(artistId: string): Promise<Array<AlbumModel>> {
    let limitQueryString = '?include_groups=single%2Calbum%2Ccompilation&limit=50&type=artist';
    limitQueryString += '&market=' + this.authorizationService.region;
    const artistsResult = new Array<ArtistModel>();
    let url = SpotifyURLResources.ArtistsAlbums.replace('{id}', artistId) + limitQueryString;
    const albumIds = new Array<string>();

    do {
      const response = await this.dataService.get<SpotifyApi.ArtistsAlbumsResponse>(
        url,
        this.authorizationService.getAuthorizationHeaders()
      );

      response.items.forEach((a) => {
        albumIds.push(a.id);
        const album = new AlbumModel({
          id: a.id,
          artist: a.artists[0].name,
          genre: '',
          name: a.name,
          imageUrl: a.images[0].url,
          totalTracks: 0,
        });
        artistsResult.push(album);
      });
      url = response.next;

    } while (url != null);


    const artistsAlbumsResult = new Array<AlbumModel>();

    for (let i = 0; i < albumIds.length; i = i + 20) {
      let partialUrl = SpotifyURLResources.MultipleAlbums + '?ids=';
      for (let j = i; j < i + 20 && j <= albumIds.length - 1; j++) {
        if (j === i) {
          partialUrl += albumIds[j];
        } else {
          partialUrl += '%2C' + albumIds[j];
        }
      }

      const partialAlbumsListResponse = await this.dataService.get<SpotifyApi.MultipleAlbumsResponse>(
        partialUrl,
        this.authorizationService.getAuthorizationHeaders()
      );

      partialAlbumsListResponse.albums.forEach((a) => {
        const tracks = new Array<TrackModel>();
        a.tracks.items.forEach((t, index) => {
          tracks.push(
            new TrackModel({
              id: t.id,
              url: t.uri,
              name: t.name,
              artistName: t.artists[0].name,
              albumName: a.name,
              trackNumber: index + 1,
              totalNumberOfTrackInPlaylist: a.tracks.total,
              genre: a.genres[0],
              artworkUrl: a.images[0].url,
              durationMs: t.duration_ms,
              isPlayable: t.is_playable,
            })
          );
        });
        const album = new AlbumModel({
          id: a.id,
          artist: a.artists[0].name,
          genre: a.genres[0],
          name: a.name,
          imageUrl: a.images[0].url,
          totalTracks: a.tracks.total,
          tracks,
        });
        artistsAlbumsResult.push(album);
      });

    }

    try {
      artistsAlbumsResult.sort(
        this.getOrderFunction<ArtistModel>('name', OrderDirectionEnum.ascending)
      );
    } catch (e) {
      console.log('SortError', e);
    }

    return artistsAlbumsResult;
  }

  public async getAlbumsTracks(album: AlbumModel): Promise<TrackModel[]> {
    return album.tracks;
  }

  public async getAlbum(albumId: string): Promise<NowPlayingPlaylistModel> {
    try {
      return await this._getAlbum(albumId);
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this.getAlbum(albumId);
      }
    }
  }

  private async _getAlbum(albumId: string): Promise<NowPlayingPlaylistModel> {
    const singleAlbumResponse = await this.dataService.get<SpotifyApi.SingleAlbumResponse>(
      SpotifyURLResources.GetAlbum + albumId,
      this.authorizationService.getAuthorizationHeaders()
    );

    const playListResponse = new NowPlayingPlaylistModel();

    playListResponse.id = singleAlbumResponse.id;
    playListResponse.type = PlayListTypeEnum.album;

    let getTrackUrl = singleAlbumResponse.tracks.href;
    do {

      const tracksRespone = await this.dataService.get<SpotifyApi.AlbumTracksResponse>(
        getTrackUrl,
        this.authorizationService.getAuthorizationHeaders());

      tracksRespone.items.forEach((t, index) => {
        playListResponse.tracks.push(new TrackModel({
          id: t.id,
          url: t.uri,
          name: t.name,
          artistName: t.artists[0].name,
          albumName: singleAlbumResponse.name,
          trackNumber: index + 1,
          totalNumberOfTrackInPlaylist: singleAlbumResponse.tracks.total,
          genre: singleAlbumResponse.genres[0],
          artworkUrl: singleAlbumResponse.images[0].url,
          durationMs: t.duration_ms,
          isPlayable: t.is_playable,
        }));
      });
      getTrackUrl = tracksRespone.next;
    } while (getTrackUrl !== null);

    return playListResponse;
  }


  public async getPlaylist(playlistId: string): Promise<NowPlayingPlaylistModel> {
    try {
      return await this._getPlaylist(playlistId);
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getPlaylist(playlistId);
      }
    }
  }

  private async _getPlaylist(playlistId: string): Promise<NowPlayingPlaylistModel> {
    const singlePlaylistResponse = await this.dataService.get<SpotifyApi.SinglePlaylistResponse>(
      SpotifyURLResources.GetPlaylist + playlistId + '?market=' + this.authorizationService.region,
      this.authorizationService.getAuthorizationHeaders()
    );

    const playListResponse = new NowPlayingPlaylistModel();

    playListResponse.type = PlayListTypeEnum.playlist;
    playListResponse.id = singlePlaylistResponse.id;
    playListResponse.name = singlePlaylistResponse.name;

    let getNextTracksUrl = singlePlaylistResponse.tracks.next;
    let tracks = singlePlaylistResponse.tracks.items;

    let continueFlag = true;

    do {

      tracks.forEach((item, index) => {
        const t = item.track;
        playListResponse.tracks.push(new TrackModel({
          id: t.id,
          url: t.uri,
          name: t.name,
          artistName: t.artists[0].name,
          albumName: singlePlaylistResponse.name,
          trackNumber: index + 1,
          totalNumberOfTrackInPlaylist: singlePlaylistResponse.tracks.total,
          genre: '',
          artworkUrl: singlePlaylistResponse.images[0].url,
          durationMs: t.duration_ms,
          isPlayable: t.is_playable,
        }));
      });
      if (getNextTracksUrl !== null) {
        const nextTracks = await this.dataService.get<SpotifyApi.PlaylistTrackResponse>(
          getNextTracksUrl,
          this.authorizationService.getAuthorizationHeaders()
        );
        tracks = nextTracks.items;
        getNextTracksUrl = nextTracks.next;
        continueFlag = true;
      } else {
        continueFlag = false;
      }
      console.error('continueFlag', continueFlag);
    } while (continueFlag);

    return playListResponse;
  }

  private async getSingleTrackPlaylist(currentlyPaying: CurrentlyPlayingResponseModel): Promise<NowPlayingPlaylistModel> {

    const playListResponse = new NowPlayingPlaylistModel();

    playListResponse.type = PlayListTypeEnum.trackCollection;
    playListResponse.id = '';
    playListResponse.name = currentlyPaying.track.name;
    const t = currentlyPaying.track;
    playListResponse.tracks.push(new TrackModel({
      id: t.id,
      url: t.url,
      name: t.name,
      artistName: t.artistName,
      albumName: t.albumName,
      trackNumber: 1,
      totalNumberOfTrackInPlaylist: 1,
      genre: '',
      artworkUrl: t.artworkUrl,
      durationMs: t.durationMs,
      isPlayable: t.isPlayable,
    }));

    return playListResponse;
  }

  public async getSavedTracks(): Promise<NowPlayingPlaylistModel> {
    try {
      return await this._getSavedTracks();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getSavedTracks();
      }
    }
  }

  private async _getSavedTracks(): Promise<NowPlayingPlaylistModel> {
    var url = SpotifyURLResources.GetSavedTracks + '?limit=50&market=' + this.authorizationService.region;

    const sumTracks = new Array<SpotifyApi.SavedTrackObject>();
    const response = new Array<TrackModel>();

    do {
      const usersSavedTracksResponse = await this.dataService.get<SpotifyApi.UsersSavedTracksResponse>(
        url,
        this.authorizationService.getAuthorizationHeaders()
      );
      usersSavedTracksResponse.items.forEach((trackObject, index) => {
        sumTracks.push(trackObject);

      });
      url = usersSavedTracksResponse.next;
    } while (url !== null);

    sumTracks.forEach((trackObject, index) => {
      const t = trackObject.track;
      response.push(new TrackModel({
        id: t.id,
        url: t.uri,
        name: t.name,
        artistName: t.artists[0].name,
        albumName: t.album.name,
        trackNumber: index + 1,
        totalNumberOfTrackInPlaylist: sumTracks.length,
        genre: '',
        artworkUrl: t.album.images[0].url,
        durationMs: t.duration_ms,
        isPlayable: t.is_playable,
      }));
    });

    const playlist = new NowPlayingPlaylistModel();
    playlist.tracks = response.sort(
      this.getOrderFunction<TrackModel>('name', OrderDirectionEnum.ascending)
    );
    playlist.name = 'Songs';
    playlist.type = PlayListTypeEnum.trackCollection;
    playlist.allowAutoUpdate = false;
    return playlist;
  }

  public async getDevices(): Promise<Array<DeviceModel>> {
    try {
      return await this._getDevices();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getDevices();
      }
    }
  }

  private async _getDevices(): Promise<Array<DeviceModel>> {
    const devicesResponse = await this.dataService.get<SpotifyApi.UserDevicesResponse>(
      SpotifyURLResources.UsersDevices,
      this.authorizationService.getAuthorizationHeaders()
    );

    const response = Array<DeviceModel>();

    devicesResponse.devices.forEach(d => {
      response.push(new DeviceModel({
        name: d.name,
        id: d.id,
        type: d.type,
        isActive: d.is_active,
        volume: d.volume_percent
      }));
    });
    this.deviceService.setDevices(response);
    return response;
  }

  public async transferDevice(): Promise<void> {
    try {
      return await this._transferDevice();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._transferDevice();
      }
    }
  }

  private async _transferDevice(): Promise<void> {

    const devicesResponse = await this.dataService.put<void>(
      'https://api.spotify.com/v1/me/player',
      JSON.stringify({device_ids: [this.deviceService.activeDevice.id]}),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }

  onDeviceGone(): void {
  }

  onDeviceSet(): void {
    this.resetVolumeSettings();
  }

  private resetVolumeSettings(): void {
    // First set new device as volume-controllable
    this.publisher.fireOnVolumeControlsAvailableUpdate(true);
    this.volumeSettingsAvailable = true;
    // Try volume change
    this.setVolume(this.deviceService.activeDevice.volume);
  }

  onDeviceTransferred(): void {
    this.transferDevice();
  }

  onDeviceListUpdated(): void {
  }

  isVolumeSettingAvailable(): boolean {
    return this.volumeSettingsAvailable;
  }

  private async getCurrentPlayback(): Promise<PlayStateResponseModel> {

    const result = await this.dataService.get<any>(
      SpotifyURLResources.PlaybackInfo,
      this.authorizationService.getAuthorizationHeaders()
    );
    const response = new PlayStateResponseModel();

    if (result && result.device) {
      response.device = new DeviceModel({
        name: result.device.name,
        id: result.device.id,
        type: result.device.type,
        isActive: result.device.is_active,
        volume: result.device.volume_percent
      });
    }
    if (result && result.device) {
      response.volume = result.device.volume_percent;
    }
    return response;
  }

  public async getCurrentlyPlaying(): Promise<CurrentlyPlayingResponseModel> {
    try {
      return await this._getCurrentlyPlaying();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getCurrentlyPlaying();
      }
    }
  }

  private async _getCurrentlyPlaying(): Promise<CurrentlyPlayingResponseModel> {

    const result = await this.dataService.get<SpotifyApi.CurrentlyPlayingResponse>(
      SpotifyURLResources.CurrentlyPlaying,
      this.authorizationService.getAuthorizationHeaders()
    );
    const response = new CurrentlyPlayingResponseModel();

    if (result && result.context) {
      if (result.context.type === 'playlist') {
        response.playlistType = PlayListTypeEnum.playlist;
        const stringArray = result.context.uri.split(':');
        response.playlistId = stringArray[stringArray.length - 1];
      }
      if (result.context.type === 'album') {
        response.playlistType = PlayListTypeEnum.album;
        const stringArray = result.context.uri.split(':');
        response.playlistId = stringArray[stringArray.length - 1];
      }
    }
    if (result && result.context === null && (result as any).currently_playing_type === 'track') {
      response.playlistType = PlayListTypeEnum.trackCollection;
      response.playlistId = null;
    }
    if (result && result.item) {
      const a = result.item;
      const t = result.item;
      response.track = new TrackModel({
        id: t.id,
        url: t.uri,
        name: t.name,
        artistName: t.artists[0].name,
        albumName: a.album.name,
        trackNumber: t.track_number,
        totalNumberOfTrackInPlaylist: 0,
        genre: '',
        artworkUrl: a.album.images[0].url,
        durationMs: t.duration_ms,
        isPlayable: t.is_playable,
      });
    }
    if (result && result.is_playing && (result.progress_ms || result.progress_ms === 0)) {
      response.progressMs = result.progress_ms;
      if (result.is_playing === true) {
        response.playState = PlayStateEnum.Play;
      }
      if (!result.is_playing && result.progress_ms > 0) {
        response.playState = PlayStateEnum.Pause;
      }
      if (!result.is_playing && result.progress_ms === 0) {
        response.playState = PlayStateEnum.Stopped;
      }
    }
    return response;
  }

  async getVolume(): Promise<number> {
    try {
      return await this._getVolume();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();

        return await this._getVolume();
      }
    }
  }

  async _getVolume(): Promise<number> {
    const result = await this.getCurrentPlayback();
    return result.volume;
  }

  async getCurrentDevice(): Promise<DeviceModel | undefined> {
    try {
      return await this._getCurrentDevice();
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
        return await this._getCurrentDevice();
      }
    }
  }

  async _getCurrentDevice(): Promise<DeviceModel | undefined> {
    const result = await this.getCurrentPlayback();
    return result.device;
  }

  private async sendVolumeRequest(volume: number): Promise<void> {
    try {
      return await this._setVolume(volume);
    } catch (error) {
      if (error instanceof TokenExpiredException) {
        await this.authorizationService.refreshTokenSpotify();
        return await this._setVolume(volume);
      }
      if (error instanceof HttpException) {
        if (error.code === ExceptionCodesEnum.VOLUME_CONTROL_DISALLOW) {
          console.error('VOLUME SETTINGS ARE NO LONGER AVAILABLE for this device');
          this.volumeSettingsAvailable = false;
          this.publisher.fireOnVolumeControlsAvailableUpdate(this.volumeSettingsAvailable);
        }
      }
    }
  }

  async setVolume(volume: number): Promise<void> {
    if (volume > 100 || volume < 0) {
      return;
    }
    if (Date.now() - this.volumeRequestTimeoutMs > this.lastVolumeRequestTimestamp) {
      this.lastVolumeRequestTimestamp = Date.now();
      this.clearVolumeRequestTimeout();
      await this.sendVolumeRequest(volume);

    } else {
      this.clearVolumeRequestTimeout();
      this.setVolumeRequestTimeout(volume);
    }
  }

  private async _setVolume(volume: number): Promise<void> {

    const devicesResponse = await this.dataService.put<void>(
      'https://api.spotify.com/v1/me/player/volume?volume_percent=' + volume + '&device_id=' + this.deviceService.activeDevice.id,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authorizationService.spotifyAccessToken}`
        }
      }
    );
  }


  getCurrentTrack(): Promise<TrackModel> {
    throw new Error('Method not implemented.');
  }

  private async setActiveDevice(): Promise<void> {
    if (this.deviceService.activeDevice === undefined) {

    }
  }

  public async updatePlaylist(): Promise<void> {
    if (this.deviceService.activeDevice) {
      const currentlyPlaying = await this.getCurrentlyPlaying();

      if (currentlyPlaying.playState !== null) {
        this.publisher.fireOnPlayStateUpdateReceived(currentlyPlaying.playState);
      }
      if (currentlyPlaying.playlistId != null) {
        switch (currentlyPlaying.playlistType) {
          case PlayListTypeEnum.album: {
            const playlist = await this.getAlbum(currentlyPlaying.playlistId);
            playlist.setCurrentTrackByTrackId(currentlyPlaying.track.id);
            playlist.progressMs = currentlyPlaying.progressMs;
            this.publisher.fireOnPlaylistChanged(playlist);
            break;
          }
          case PlayListTypeEnum.playlist: {
            const playlist = await this.getPlaylist(currentlyPlaying.playlistId);
            playlist.setCurrentTrackByTrackId(currentlyPlaying.track.id);
            playlist.progressMs = currentlyPlaying.progressMs;
            this.publisher.fireOnPlaylistChanged(playlist);
            break;
          }
          default: {
            break;
          }
        }
      } else if (currentlyPlaying.playlistType === PlayListTypeEnum.trackCollection) {

        const playlist = await this.getSingleTrackPlaylist(currentlyPlaying);
        playlist.setCurrentTrackByTrackId(currentlyPlaying.track.id);
        playlist.progressMs = currentlyPlaying.progressMs;
        this.publisher.fireOnPlaylistChanged(playlist);
      }
    }
  }

  async initialize(): Promise<void> {

    const result = await this.getCurrentPlayback();
    /**
     * Initialize device
     */
    if (result.device && this.deviceService.activeDevice === undefined) {
      this.deviceService.setActiveDevice(result.device);
    }
    /**
     * Initialize now playing
     */

    await this.updatePlaylist();

  }

  private timeout(ms): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private clearVolumeRequestTimeout(): void {
    clearTimeout(this.volumeRequestTimeoutId);
  }

  protected setVolumeRequestTimeout(volume: number): void {
    this.volumeRequestTimeoutId = setTimeout(
      function () {
        this.sendVolumeRequest(volume).catch(console.error);
      }.bind(this),
      this.volumeRequestTimeoutMs
    );
  }

  private clearSeekRequestTimeout(): void {
    clearTimeout(this.seekRequestTimeoutId);
  }

  protected setSeekRequestTimeout(volume: number): void {
    this.seekRequestTimeoutId = setTimeout(
      function () {
        this.sendSeekRequest(volume).catch(console.error);
      }.bind(this),
      this.volumeRequestTimeoutMs
    );
  }
}
