import {DeviceModel} from '../../../models/DeviceModel';

export class PlayStateResponseModel {
  volume: number | null = null;
  device: DeviceModel | null = null;

  public constructor(init?: Partial<PlayStateResponseModel>) {
    Object.assign(this, init);
  }
}
