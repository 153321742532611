export class InitialIndex {
  initial: string;
  index: number;
  label: string;

  constructor(initial: string, index: number, label) {
    this.initial = initial;
    this.index = index;
    this.label = label;
  }
}
