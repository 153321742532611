import {ExceptionBase} from './ExceptionBase';

export class TokenExpiredException extends ExceptionBase {
    constructor() {
        super({
            code: 'token_expired_exception',
            description: 'Token expired exception'
        });
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

    }
}