import {ExceptionBase} from './ExceptionBase';
import {HttpErrorResponse} from '@angular/common/http';

export class HttpException {
  status: number;
  message: string;
  error: any;

  code: string;

  constructor(e: HttpErrorResponse) {
    this.status = e.status;
    this.message = e.message;
    this.error = e.error;
    if (e.error && e.error.error && e.error.error.reason) {
      this.code = e.error.error.reason;
    }

    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

  }
}
