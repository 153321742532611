import {TrackModel} from './TrackModel';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import Track = Spotify.Track;

export class NowPlayingPlaylistModel {

  /**
   * TODO initnél lekérdezés és feltöltés
   *
   * TODO make it play
   *
   * TODO Album kiválasztásánál feltöltés
   *
   * TODO Playlist kiválasztsánál feltöltés
   *
   *
   */
  id: string;
  type: PlayListTypeEnum;
  name = '';
  currentTrackIndex = 0;
  tracks: Array<TrackModel> = new Array<TrackModel>();
  emptyTrack = new TrackModel();
  progressMs = 0;

  allowAutoUpdate = true;


  get currentTrack(): TrackModel {
    if (this.tracks.length === 0) {
      return this.emptyTrack;
    }
    return this.tracks[this.currentTrackIndex];
  }

  get currentAndUpcomingTrackIds(): Array<string> {
    const currentAndUpcomingTracksIds = new Array<string>();

    for (let i = this.currentTrackIndex; i < this.tracks.length; i++) {
      currentAndUpcomingTracksIds.push(this.tracks[i].id);
    }
    return currentAndUpcomingTracksIds;
  }


  public constructor(init?: Partial<NowPlayingPlaylistModel>) {
    Object.assign(this, init);
    Object.setPrototypeOf(this, new.target.prototype);
  }

  resetPlayProgress(): void {
    this.progressMs = 0;
  }

  next(): void {
    if (this.tracks.length > this.currentTrackIndex + 1) {
      this.currentTrackIndex++;
    } else {
      this.currentTrackIndex = 0;
    }
  }

  previous(): void {
    if (this.currentTrackIndex - 1 >= 0) {
      this.currentTrackIndex--;
    } else {
      this.currentTrackIndex = this.tracks.length - 1;
    }
  }

  public setCurrentTrackByTrackId(trackId: string): void {
    this.tracks.forEach((t, i) => {
      if (t.id === trackId) {
        this.currentTrackIndex = i;
        return;
      }
    });
  }

  public getCurrentTrack(): TrackModel {
    return this.tracks[this.currentTrackIndex];
  }
}
