<div class="menu-container">
  <div class="menu-body">

    <div *ngIf="this.menuModel.menuItemWindow[0].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[0].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[0].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[0].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[1].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[1].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[1].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[1].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[2].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[2].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[2].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[2].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[3].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[3].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[3].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[3].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[4].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[4].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[4].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[4].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[5].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[5].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[5].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[5].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[6].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[6].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[6].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[6].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[7].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[7].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[7].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[7].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

    <div *ngIf="this.menuModel.menuItemWindow[8].type !== this.MenuItemTypeEnum.EmptyItem"
         class="menu-item {{this.menuModel.menuItemWindow[8].type}}"
         [ngClass]="{'selected':this.menuModel.menuItemWindow[8].uuid===this.menuModel.selectedMenuItem.uuid}">
      <div class="menu-label text"
           [ngStyle]="{'max-width.px':this.stateService.pageWidth -30}"
      >
        {{this.menuModel.menuItemWindow[8].label}}
      </div>
      <div class="menu-icon">
        <img src="/assets/icons/right-arrow.svg">
      </div>
    </div>

  </div>

  <div id="initial-seek-wrapper">
    <div id="initial-seek-container" *ngIf="this.menuModel.scrollState.initialSeekOn">
      <div id="initial">
        {{this.menuModel.currentInitial}}
      </div>
    </div>
  </div>

</div>
