import {Component, OnInit} from '@angular/core';
import {MenuModel} from '../../models/MenuModel';
import {PublisherService} from '../../services/publisher.service';
import {StateService} from '../../services/state.service';
import {MenuItem} from '../../models/MenuItem';
import {DeviceService} from '../../services/device.service';
import {INavigationEventSubscriber} from '../../interfaces/INavigationSubscriber';
import {MenuItemTypeEnum} from '../../enums/MenuItemTypeEnum';
import {IClickWheelEventsSubscriber} from '../../interfaces/IClickWheelEventsSubscriber';
import {IScrollSubscriber} from '../../interfaces/IScrollSubscriber';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {IMenuButtonEventsSubscriber} from '../../interfaces/IMenuButtonEventsSubscriber';
import {IDeviceChangedSubscriber} from '../../interfaces/IDeviceChangedSubscriber';

@Component({
  selector: 'app-select-device-page',
  templateUrl: './select-device-page.component.html',
  styleUrls: ['./select-device-page.component.scss']
})
export class SelectDevicePageComponent
  implements OnInit,
    IDeviceChangedSubscriber,
    IScrollSubscriber,
    IEnterButtonEventsSubscriber,
    IMenuButtonEventsSubscriber,
    INavigationEventSubscriber {

  TAG = 'SelectDevicePageComponent';

  public menu: MenuModel;
  public activeMenuIndex = 0;
  public heightOfTopLabel = 2;
  public selectDeviceResolveObject: { resolve: (value?: (PromiseLike<void> | void)) => void; reject: (reason?: any) => void } | null;

  constructor(private publisher: PublisherService,
              private stateService: StateService,
              private deviceService: DeviceService) {

    this.menu = new MenuModel('Select Device', new Array<MenuItem>(), this.heightOfTopLabel);

    this.publisher.subscribeToNavigationEventsEvents(this);
    this.publisher.subscribeToDeviceChangedEvents(this);
    this.selectDeviceResolveObject = null;
  }

  onDeviceTransferred(): void {
  }

  onDeviceSet(): void {
  }

  onDeviceListUpdated(): void {
    const menuItems = new Array<MenuItem>();

    this.deviceService.deviceList.forEach((d, index) => {
      menuItems.push(new MenuItem({
        label: d.name,
        type: MenuItemTypeEnum.MenuItem,
        isSelectable: true,
        device: d
      }));
    });

    this.menu.setMenuItems(menuItems);
  }

  onDeviceGone(): void {
  }

  ngOnInit(): void {
  }

  onNavigateLogin(): void {
  }

  onNavigateToMainMenu(): void {
  }

  onNavigateToMainMenuRequest(): void {
  }

  onShowSelectDevice(resolveObject: { resolve: (value?: (PromiseLike<void> | void)) => void; reject: (reason?: any) => void } | null): void {
    this.setSelectDevicePromise(resolveObject);

    this.publisher.fireOnDeviceRefreshRequestEvent();
    this.subscribeToClickWheelEventSet();
  }

  private setSelectDevicePromise(resolveObject: { resolve: (value?: (PromiseLike<void> | void)) => void; reject: (reason?: any) => void }): void {
    if (resolveObject !== undefined) {
      this.selectDeviceResolveObject = resolveObject;
    }
  }

  private resolveSelectDevicePromise(): void {
    if (this.selectDeviceResolveObject !== undefined) {
      this.selectDeviceResolveObject.resolve();
      this.selectDeviceResolveObject = null;
    }
  }

  private rejectSelectDevicePromise(): void {
    if (this.selectDeviceResolveObject !== null) {
      this.selectDeviceResolveObject.reject('Went back');
      this.selectDeviceResolveObject = null;
    }
  }

  onHideSelectDevice(): void {
    this.publisher.fireOnDeviceRefreshCancelEvent();
    this.unsubscribeToClickWheelEventSet();
  }

  onEnterClicked(): void {
    if (this.menu.selectedMenuItem !== undefined) {
      this.deviceService.setActiveDevice(this.menu.selectedMenuItem.device);
      this.publisher.fireOnHideSelectDevice();

      if (this.selectDeviceResolveObject !== null) {
        return this.resolveSelectDevicePromise();
      }
    }
  }

  onMenuButtonClicked(): void {
    this.rejectSelectDevicePromise();
    this.publisher.fireOnHideSelectDevice();
  }

  onScrollIncrease(): void {
    this.menu.onScrollIncrease();
  }

  onScrollDecrease(): void {
    this.menu.onScrollDecrease();
  }

  private subscribeToClickWheelEventSet(): void {
    this.publisher.subscribeToScrollEvents(this);
    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
  }

  private unsubscribeToClickWheelEventSet(): void {
    this.publisher.unsubscribeToScrollEvents(this);
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToEnterButtonEvents(this);
  }


}
