import {Injectable} from '@angular/core';
import {CacheTypeEnum} from './CacheTypeEnum';
import {SpotifyLoginTokensEnum} from '../enums/SpotifyLoginTokensEnum';
import {CacheModel} from './CacheModel';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cacheLimit = 86200;

  constructor() {
  }

  public async get<T>(key: CacheTypeEnum): Promise<T> {

    if (window.localStorage.getItem(key) !== null) {
      const cacheModel = this.readLocalStorage(key);

      if (cacheModel.storedAtEpoch + this.cacheLimit < Date.now() / 1000) {
        console.log('Cache expired for key', key);
        return Promise.reject('Cache Expired');
      }

      const data: T = JSON.parse(cacheModel.data);

      return Promise.resolve(data);
    }
    console.log('Cache not found for key', key);
    return Promise.reject('NotFound');
  }

  public async getSmart<T>(key: CacheTypeEnum, type: (new () => T)): Promise<T> {

    if (window.localStorage.getItem(key) !== null) {
      const cacheModel = this.readLocalStorage(key);

      if (cacheModel.storedAtEpoch + this.cacheLimit < Date.now() / 1000) {
        console.log('Cache expired for key', key);
        return Promise.reject('Cache Expired');
      }

      const data: T = JSON.parse(cacheModel.data);

      const object = new type();

      Object.setPrototypeOf(data, object as any);

      return Promise.resolve(data);
    }
    console.log('Cache not found for key', key);
    return Promise.reject('NotFound');
  }

  private activator<T>(type: { new(): T; }): T {
    return new type();
  }


  public store<T>(key: CacheTypeEnum, storeable: T): T {
    const cacheModel = new CacheModel();
    cacheModel.key = key;
    cacheModel.data = JSON.stringify(storeable);
    cacheModel.storedAtEpoch = Date.now() / 1000;

    window.localStorage.setItem(key, JSON.stringify(cacheModel));
    return storeable;

  }

  private readLocalStorage(key: string): CacheModel {
    return JSON.parse(window.localStorage.getItem(key)) as CacheModel;
  }
}
