import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {TokenExpiredException} from '../exceptions/TokenExpiredException';
import {LoadingService} from './loading.service';
import {HttpException} from '../exceptions/HttpException';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient, private loadingService: LoadingService) {
  }

  public startLoading(): any {

    this.loadingService.startLoading();
  }

  public stopLoading(): any {
    this.loadingService.stopLoading();
  }

  async get<T>(url: string, headers: any): Promise<T> {

    this.loadingService.startLoading();

    var getResult = await this.http.get<T>(
      url,
      headers
    ).pipe(catchError(
      function errorHandler(errorResponse: HttpErrorResponse): any {

        console.error(errorResponse);

        this.loadingService.stopLoading();

        if (typeof errorResponse !== 'undefined' && errorResponse.status && errorResponse.status === 401) {
          throw new TokenExpiredException();
        }


        if (typeof errorResponse === 'undefined' || typeof errorResponse.error === 'undefined' || typeof errorResponse.error.error === 'undefined') {

          return throwError('An unknown error occurred; please try again later.');

        }

        if (typeof errorResponse.error.error.message !== 'undefined' &&
          typeof errorResponse.error.error.code !== 'undefined') {

          return throwError(errorResponse.error.error.code + ' : ' + errorResponse.error.error.message);

        }

        if (typeof errorResponse.error.error.message !== 'undefined') {

          return throwError(errorResponse.error.error.message);

        }

        if (typeof errorResponse.error.error !== 'undefined') {

          return throwError('Something bad happened; please try again later. The error is: ' + errorResponse.error.error);

        }

        return throwError('Something bad happened; please try again later.');

      }.bind(this))
    ).toPromise<T>();

    this.loadingService.stopLoading();

    return getResult;
  }


  async post<T>(url: string, body: any, httpOptions: any): Promise<T> {

    this.loadingService.startLoading();

    var postResult = await this.http.post<T>(
      url,
      body,
      httpOptions
    ).pipe(catchError(
      function errorHandler(errorResponse: HttpErrorResponse): any {

        console.error(errorResponse);


        this.loadingService.stopLoading();

        if (typeof errorResponse !== 'undefined' && errorResponse.status && errorResponse.status === 401) {
          throw new TokenExpiredException();
        }

        if (typeof errorResponse === 'undefined' || typeof errorResponse.error === 'undefined' || typeof errorResponse.error.error === 'undefined') {

          return throwError('An unknown error occurred; please try again later.');

        }

        if (typeof errorResponse.error.error.message !== 'undefined' &&
          typeof errorResponse.error.error.code !== 'undefined') {

          return throwError(errorResponse.error.error.code + ' : ' + errorResponse.error.error.message);

        }

        if (typeof errorResponse.error.error.message !== 'undefined') {

          return throwError(errorResponse.error.error.message);

        }

        if (typeof errorResponse.error.error !== 'undefined') {

          return throwError('Something bad happened; please try again later. The error is: ' + errorResponse.error.error);

        }

        return throwError('Something bad happened; please try again later.');

      }.bind(this))
    ).toPromise<T>();

    this.loadingService.stopLoading();

    return postResult;
  }


  async put<T>(url: string, body: any, httpOptions: any): Promise<T> {

    this.loadingService.startLoading();
    var putResult = await this.http.put<T>(
      url,
      body,
      httpOptions
    ).pipe(catchError(
      function errorHandler(errorResponse: HttpErrorResponse): any {

        console.error('dataService.put', errorResponse);


        this.loadingService.stopLoading();

        if (typeof errorResponse !== 'undefined' && errorResponse.status && errorResponse.status === 401) {
          throw new TokenExpiredException();
        }
        throw new HttpException(errorResponse);

      }.bind(this))
    ).toPromise<T>();

    this.loadingService.stopLoading();

    return putResult;
  }


}
