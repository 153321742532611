import {Component, OnInit} from '@angular/core';
import {IDeviceChangedSubscriber} from '../../interfaces/IDeviceChangedSubscriber';
import {IScrollSubscriber} from '../../interfaces/IScrollSubscriber';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {IMenuButtonEventsSubscriber} from '../../interfaces/IMenuButtonEventsSubscriber';
import {INavigationEventSubscriber} from '../../interfaces/INavigationSubscriber';
import {MenuModel} from '../../models/MenuModel';
import {PublisherService} from '../../services/publisher.service';
import {StateService} from '../../services/state.service';
import {DeviceService} from '../../services/device.service';
import {MenuItem} from '../../models/MenuItem';
import {MenuItemTypeEnum} from '../../enums/MenuItemTypeEnum';
import {StringResources} from '../../enums/StringResources';
import {IAboutEventsSubscriber} from '../../interfaces/IAboutEventsSubscriber';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit,
  IScrollSubscriber,
  IEnterButtonEventsSubscriber,
  IMenuButtonEventsSubscriber,
  IAboutEventsSubscriber {

  TAG = 'SelectDevicePageComponent';

  public menu: MenuModel;
  public activeMenuIndex = 0;
  public heightOfTopLabel = 2;
  public selectDeviceResolveObject: { resolve: (value?: (PromiseLike<void> | void)) => void; reject: (reason?: any) => void } | null;

  constructor(private publisher: PublisherService,
              private stateService: StateService) {

    var aboutMenuItems = new Array<MenuItem>();

    //  aboutMenuItems.push(new MenuItem({label: StringResources.ToCollaborator, type: MenuItemTypeEnum.LinkItem}));
    aboutMenuItems.push(new MenuItem({label: StringResources.ToDeveloper, type: MenuItemTypeEnum.LinkItem}));

    this.menu = new MenuModel('About', aboutMenuItems, this.heightOfTopLabel);

    this.publisher.subscribeToAboutEvents(this);
  }

  onDeviceTransferred(): void {
  }

  onDeviceSet(): void {
  }


  onDeviceGone(): void {
  }

  ngOnInit(): void {
  }


  onNavigateToMainMenu(): void {
  }

  onNavigateToMainMenuRequest(): void {
  }

  onEnterClicked(): void {
    if (this.menu.selectedMenuItem !== undefined) {
      if (this.menu.selectedMenuItem.label === StringResources.ToWebsite) {
        window.location.href = StringResources.EnlightWebsite;
      }
      if (this.menu.selectedMenuItem.label === StringResources.ToDeveloper) {
        window.location.href = StringResources.EnlightWebsite;
      }
      if (this.menu.selectedMenuItem.label === StringResources.ToLinkedIn) {
        window.location.href = StringResources.EnlightLinkedIn;
      }
      if (this.menu.selectedMenuItem.label === StringResources.ToCollaborator) {
        window.location.href = StringResources.CollaboratorWebsite;
      }
    }
  }

  onMenuButtonClicked(): void {
    this.publisher.fireNavigateBackFromAbout();
  }

  onScrollIncrease(): void {
    this.menu.onScrollIncrease();
  }

  onScrollDecrease(): void {
    this.menu.onScrollDecrease();
  }

  private subscribeToClickWheelEventSet(): void {
    this.publisher.subscribeToScrollEvents(this);
    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
  }

  private unsubscribeToClickWheelEventSet(): void {
    this.publisher.unsubscribeToScrollEvents(this);
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToEnterButtonEvents(this);
  }

  onAboutHideEvent(): void {
    this.unsubscribeToClickWheelEventSet();
  }

  onAboutShowEvent(): void {
    this.subscribeToClickWheelEventSet();
  }


}


