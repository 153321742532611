import {Injectable} from '@angular/core';
import {ILoginSubscriber} from '../interfaces/ILoginSubscriber';
import {PublisherService} from './publisher.service';
import {LoginTypesEnum} from '../enums/LoginTypesEnum';
import {IPlayBehaviour} from '../interfaces/IPlayBehaviour';
import {StreamingService} from './streaming.service';
import {SpotifyPlayBehavior} from '../behaviors/SpotifyPlayBehavior';
import {DefaultPlayBehavior} from '../behaviors/DefaultPlayBehavior';
import {AppleMusicPlayBehavior} from '../behaviors/AppleMusicPlayBehavior';

@Injectable({
  providedIn: 'root'
})
export class PlayBehaviorFactoryService implements ILoginSubscriber {

  public behavior: IPlayBehaviour = new DefaultPlayBehavior();

  constructor(private publisher: PublisherService, private streamingService: StreamingService) {

    this.publisher.subscribeToLoginEvents(this);

  }

  TAG: string;

  onLogin(type: LoginTypesEnum): void {
    switch (type) {
      case LoginTypesEnum.Spotify:
        this.behavior = new SpotifyPlayBehavior(this.streamingService, this.publisher);
        break;
      case LoginTypesEnum.AppleMusic:
        this.behavior = new AppleMusicPlayBehavior(this.streamingService, this.publisher);
    }
  }

  onLogout(): void {
    this.behavior = new DefaultPlayBehavior();
  }

  onSpotifyAccessTokenChange(): void {
  }
}
