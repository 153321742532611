<div class="menu-container">

  <div class="menu-body" [ngStyle]="{'margin-top.px':this.menu.scrollMarginOffset}">
    <!--<div class="about-container">
      <div class="enlight-about about-item">
        Collaboration with:<br> <b><i>Collaborator Placeholder</i></b>
      </div>
      <div class="enlight-logo about-item">
        <img src="/assets/logos/enlight-logo.png">

      </div>
    </div>-->
    <div class="about-container">
      <div class="enlight-about about-item">
        Developed by:<br> <b>Enlight Digital Studio</b>
      </div>
      <div class="enlight-logo about-item">
        <img src="/assets/logos/enlight-logo.png">
      </div>
    </div>
    <div class="about-container">
      <i>
        Please consider to support artists by buying official merchandise, streaming or purchasing their music.
      </i>
    </div>
    <ng-container *ngFor="let menuItem of this.menu.menuItemList; index as i">
      <div class="menu-item {{menuItem.type}}" [ngClass]="{'selected':i===this.menu.selectedIndex}">
        <div class="menu-label text">{{menuItem.label}}</div>
        <div class="menu-icon">
        </div>
      </div>
    </ng-container>
  </div>
</div>
