import {Injectable} from '@angular/core';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {TrackModel} from '../models/TrackModel';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public selectedMenu = '';
  public screenHeight = 0;
  public headerHeight = 0;
  public pageHeight = 0;
  public pageWidth = 0;

  private _currentSongId = '';

  public atFeedback = false;

  public tooltipAnimationVisible = false;

  get currentSongId(): string {
    return this._currentSongId;
  }

  onResize() {
    this.pageWidth = document.getElementsByClassName('menu-item')[0].getBoundingClientRect().width;
    this.screenHeight = document.getElementById('screen')!.getBoundingClientRect().height;
    this.headerHeight = document.getElementById('header')!.getBoundingClientRect().height;
    this.pageHeight = this.screenHeight - this.headerHeight;
  }

  set currentSongId(id: string) {
    if (id === undefined || id === null || id === '') {
      this._currentSongId = 'INVALID_ID';
    }
    this._currentSongId = id;
  }

  constructor() {
  }

  setSelectedMenuName(selectedMenu: string) {
    this.selectedMenu = selectedMenu;
  }


}
