<div class="modal-header">
  <h4 class="modal-title text-1">How-to navigate</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="text-3">Move your thumb around the Click Wheel to select a menu item. To choose the
    item, press the Center button.</p>
  <div id="clickwheel-tutorial-wrapper">
    <img id="tutorial-1-img" src="/assets/images/click-wheel.jpg">
    <!-- <img id="pointer" src="/assets/icons/pointer.png">-->
  </div>


  <p class="text-3">To go back to the previous menu, press Menu on the Click Wheel.</p>
  <img id="tutorial-2-img" src="/assets/images/tutorial.png">

</div>
<div class="modal-footer">
  <div class="container">
    <div class="row">
      <div class="col-8 col col-left">
        <div class="form-check" *ngIf="this.savedDoNotShow ==false">
          <input class="form-check-input" [(ngModel)]="doNotShowSet" type="checkbox" value="" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">
            Do not show again
          </label>
        </div>
      </div>
      <div class="col-4 col col-right">
        <button type="button" class="btn btn-outline-dark"
                (click)="storeDoNotShow() && activeModal.close(this.ModalActionEnum.close)">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
