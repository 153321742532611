import {Component, OnInit} from '@angular/core';
import {PlayService} from '../../../services/play.service';
import {PublisherService} from '../../../services/publisher.service';
import {INowPlayingBarShowHideEventsSubscriber} from '../../../interfaces/INowPlayingBarShowHideEventsSubscriber';
import {ISeekControlShowHideEventsSubscriber} from '../../../interfaces/ISeekControlShowHideEventsSubscriber';
import {StateService} from '../../../services/state.service';

@Component({
  selector: 'app-now-playing-bar',
  templateUrl: './now-playing-bar.component.html',
  styleUrls: ['./now-playing-bar.component.scss']
})
export class NowPlayingBarComponent implements OnInit, INowPlayingBarShowHideEventsSubscriber, ISeekControlShowHideEventsSubscriber {

  TAG = 'NowPlayingBarComponent';

  constructor(public playService: PlayService, private publisher: PublisherService, public stateService: StateService) {
    //TODO this.publisher.subscribeToNowPlayingBarShowHideEvents(this);
    //TODO this.publisher.subscribeToSeekControlShowHideEventsEvents(this);
  }

  ngOnInit(): void {

  }


  onNowPlayingBarHideEvent(): void {
  }

  onNowPlayingBarShowEvent(): void {
  }

  onSeekControlHideEvent(): void {
  }

  onSeekControlShowEvent(): void {
  }

}
