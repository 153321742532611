import {Component, OnInit} from '@angular/core';
import {StateService} from '../../services/state.service';
import {IClickWheelEventsSubscriber} from '../../interfaces/IClickWheelEventsSubscriber';
import {PublisherService} from '../../services/publisher.service';
import {ILoginSubscriber} from '../../interfaces/ILoginSubscriber';
import {IScrollSubscriber} from '../../interfaces/IScrollSubscriber';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {INavigationEventSubscriber} from '../../interfaces/INavigationSubscriber';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TutorialModalComponent} from '../tutorial-modal/tutorial-modal.component';
import {ModalActionEnum} from '../../enums/ModalActionEnum';
import {LocalStorageKeysEnum} from '../../enums/LocalStorageKeysEnum';
import {LoginTypesEnum} from '../../enums/LoginTypesEnum';
import {EventKeys} from '../../enums/EventKeys';
import {StringResources} from '../../enums/StringResources';
import {environment} from '../../../environments/environment';

declare var MusicKit: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,
  IScrollSubscriber,
  IEnterButtonEventsSubscriber,
  ILoginSubscriber,
  INavigationEventSubscriber {

  public TAG = 'LoginComponent';

  public isSpotifySelected = false;
  public isAppleMusicSelected = false;

  public ModalActionEnum: ModalActionEnum;

  musicKit: any;

  constructor(
    public stateService: StateService,
    private publisher: PublisherService) {
    this.publisher.subscribeToScrollEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
    this.publisher.subscribeToLoginEvents(this);
    this.publisher.subscribeToNavigationEventsEvents(this);

    try {
      this.musicKit = MusicKit.getInstance();
    } catch (e) {
      if (e.errorCode === 'CONFIGURATION_ERROR') {
        this.listenToMusicKitConfiguredEvent();
      }
      console.error('musicKit error', e.errorCode);
    }
  }

  private listenToMusicKitConfiguredEvent(): void {
    document.addEventListener(EventKeys.musickitconfigured, this.initializeMusicKit.bind(this));
  }

  private initializeMusicKit(): void {
    this.musicKit = MusicKit.getInstance();
  }

  onNavigateToMainMenuRequest(): void {
  }

  onNavigateToMainMenu(): void {
  }

  onNavigateLogin(): void {
    this.publisher.subscribeToScrollEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
    this.publisher.subscribeToLoginEvents(this);
  }

  onShowSelectDevice(showSelectDevicePromise: { resolve: (value?: void | PromiseLike<void>) => void; reject: (reason?: any) => void; }): void {
  }

  onHideSelectDevice(): void {
  }

  ngOnInit(): void {
  }


  onEnterClicked(): void {
    if (this.isSpotifySelected === false && this.isAppleMusicSelected === false) {
      return;
    } else if (this.isSpotifySelected === true) {
      this.loginToSpotifyButtonClicked();
    } else if (this.isAppleMusicSelected === true) {
      this.appleMusicLoginButtonClicked();
    }
  }

  onScrollDecrease(): void {
    this.isSpotifySelected = true;
    this.isAppleMusicSelected = false;
  }

  onScrollIncrease(): void {
    this.isAppleMusicSelected = true;
    this.isSpotifySelected = false;
  }

  onSpotifyAccessTokenChange(): void {
  }

  onLogin(type: LoginTypesEnum): void {
    this.publisher.unsubscribeToScrollEvents(this);
    this.publisher.unsubscribeToEnterButtonEvents(this);
  }

  onLogout(): void {
  }

  loginToSpotifyButtonClicked(): void {
    console.log('Redirecting to', environment.SpotifyLoginBackendUrl);
    window.location.href = environment.SpotifyLoginBackendUrl;
  }

  appleMusicLoginButtonClicked(): void {
    this.musicKit.authorize();
  }


}
