import {IPlayBehaviour} from '../interfaces/IPlayBehaviour';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import {StreamingService} from '../services/streaming.service';
import {PlaybackModeEnum} from '../enums/PlaybackModeEnum';
import {PublisherService} from '../services/publisher.service';
import {MenuItem} from '../models/MenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';

export class SpotifyPlayBehavior implements IPlayBehaviour {

  public TAG = 'SpotifyPlayBehavior';
  public playlist: NowPlayingPlaylistModel = new NowPlayingPlaylistModel();

  public playedMs = 0;
  public trackRemainingLabel = '-0:00';
  public trackPlayedLabel = '0:00';
  public volume = 0;

  public playState: PlayStateEnum = PlayStateEnum.NotPlaying;

  public progress: number;
  private previousThreshold = 3000;

  private lastPreviousRequestedTimestamp = 0;


  private interval: any = null;
  private refreshPlaylistTimeoutId: NodeJS.Timeout;
  private _playbackMode: PlaybackModeEnum = PlaybackModeEnum.PlayPlaylistOnce;
  private playbackStarted = 0;

  constructor(private streamingService: StreamingService, private publisher: PublisherService) {

  }

  getMainMenu(): Array<MenuItem> {

    const mainMenuItems = new Array<MenuItem>();

    mainMenuItems.push(new MenuItem({label: 'Albums', type: MenuItemTypeEnum.AlbumsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Artists', type: MenuItemTypeEnum.ArtistsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Playlists', type: MenuItemTypeEnum.PlaylistsCategoryItem}));
    mainMenuItems.push(new MenuItem({label: 'Now Playing', type: MenuItemTypeEnum.NowPlaying}));
    mainMenuItems.push(new MenuItem({label: 'Songs', type: MenuItemTypeEnum.SongsCategoryItem}));
    //mainMenuItems.push(new MenuItem({label: 'Shuffle Songs', type: MenuItemTypeEnum.MenuItem}));
    mainMenuItems.push(new MenuItem({label: 'Select device', type: MenuItemTypeEnum.SelectDeviceItem}));
    mainMenuItems.push(new MenuItem({label: 'Feedback', type: MenuItemTypeEnum.FeedbackItem}));
    mainMenuItems.push(new MenuItem({label: 'About', type: MenuItemTypeEnum.AboutItem}));
    mainMenuItems.push(new MenuItem({label: 'Logout', type: MenuItemTypeEnum.LogoutItem}));
    return mainMenuItems;
  }

  destruct(): void {
    this.clearPlaylistUpdateTimeout();
  }

  async playClicked(): Promise<void> {
    //await this.updatePlayState();

    if (this.isPlaying()) {
      await this.streamingService.pause();
      this.setPause();

      //await this.streamingService.updatePlaylist();

      this.clearPlaylistUpdateTimeout();

    } else if (this.isPaused()) {
      await this.streamingService.resume();
      this.setPlay();
      this.setPlaylistUpdateTimeout();

    } else if (this.isStopped()) {
      this.playStoppedPlayList().catch(console.error);
    } else {
      //
    }
  }

  private async playStoppedPlayList(): Promise<void> {
    this.playlist.currentTrackIndex = 0;
    await this.playPlaylist();
  }


  async nextTrack(): Promise<void> {
    switch (this.playlist.type) {
      case PlayListTypeEnum.playlist:
      case PlayListTypeEnum.album: {

        this.playlist.next();
        this.clearPlaylistUpdateTimeout();
        await this.playPlaylist();
        break;
      }
      case PlayListTypeEnum.trackCollection: {
        this.streamingService.nextTrack();
      }
    }
  }


  async playPlaylist(): Promise<void> {
    try {
      await this.streamingService.playPlaylist(this.playlist);
      this.playState = PlayStateEnum.Play;

      this.startElapsedTimeInterval();

      this.handleNextPlaylistRefresh();

      const volumeResult = await this.getVolume();
      if (volumeResult) {
        this.volume = volumeResult;
      }
    } catch (e) {
      console.error('Play.service. startPlaying exception', e);
    }
  }

  public async onPlaylistChanged(newPlaylist: NowPlayingPlaylistModel): Promise<void> {
    this.playlist = newPlaylist;
    this.handleNextPlaylistRefresh();
  }

  public playSate(): PlayStateEnum {
    return this.playState;
  }

  playbackMode(): PlaybackModeEnum {
    return this._playbackMode;
  }

  async previousTrack(): Promise<void> {
    switch (this.playlist.type) {
      case PlayListTypeEnum.playlist:
      case PlayListTypeEnum.album: {

        if (this.lastPreviousRequestedTimestamp + this.previousThreshold < Date.now()) {
          await this.playPlaylist();
          this.playlist.progressMs = 0;
        } else {
          this.playlist.previous();
          await this.playPlaylist();
        }
        this.lastPreviousRequestedTimestamp = Date.now();
        break;
      }
      case PlayListTypeEnum.trackCollection: {
        if (this.lastPreviousRequestedTimestamp + this.previousThreshold < Date.now()) {
          this.seek(0);
          this.playlist.progressMs = 0;
        } else {
          this.streamingService.previousTrack();
        }
        this.lastPreviousRequestedTimestamp = Date.now();
      }
    }
  }

  seek(seekToMs: number): void {
    if (seekToMs < 0) {
      seekToMs = 0;
    }
    if (seekToMs > this.playlist.currentTrack.durationMs) {
      return;
    }
    this.playlist.progressMs = seekToMs;

    this.setProgress();
    this.setPlaybackLabels();

    this.streamingService.seek(seekToMs);
  }

  async showCurrentlyPlaying(): Promise<void> {
    try {

      await this.streamingService.updatePlaylist();

      this.continueElapsedTimeInterval();

      this.handleNextPlaylistRefresh();

      const volumeResult = await this.getVolume();
      if (volumeResult) {
        this.volume = volumeResult;
      }
    } catch (e) {
      console.error('Play.service. startPlaying exception', e);
    }
  }

  async startPlayingASingleTrack(): Promise<void> {
    try {
      await this.streamingService.playTrack(this.playlist.currentTrack.url);
      this.startElapsedTimeInterval();
      this.handleNextPlaylistRefresh();
      const volumeResult = await this.getVolume();
      if (volumeResult) {
        this.volume = volumeResult;
      }
    } catch (e) {
      console.error('Play.service. startPlaying exception', e);
    }
  }


  async setVolume(volume: number): Promise<void> {
    this.volume = volume;
    return this.streamingService.setVolume(volume);
  }


  async getVolume(): Promise<number> {
    return await this.streamingService.getVolume();
  }


  private setPause(): void {
    this.playState = PlayStateEnum.Pause;
  }

  private setPlay(): void {
    this.playState = PlayStateEnum.Play;
  }

  private isPlaying(): boolean {
    return this.playState === PlayStateEnum.Play;
  }

  private isPaused(): boolean {
    return this.playState === PlayStateEnum.Pause;
  }

  private isStopped(): boolean {
    return this.playState === PlayStateEnum.Stopped;

  }

  private startElapsedTimeInterval(): void {
    this.playbackStarted = Date.now();
    this.playlist.resetPlayProgress();
    window.clearInterval(this.interval);
    this.periodicPlaybackStatusUpdate();
    this.interval = setInterval(this.periodicPlaybackStatusUpdate.bind(this), 1000);
  }

  private continueElapsedTimeInterval(): void {
    this.playbackStarted = Date.now();
    // this.playlist.resetPlayProgress();
    window.clearInterval(this.interval);
    this.periodicPlaybackStatusUpdate();
    this.interval = setInterval(this.periodicPlaybackStatusUpdate.bind(this), 1000);
  }

  private periodicPlaybackStatusUpdate(): void {

    this.progressPlayback();

    this.setProgress();

    this.setPlaybackLabels();

  }

  private setPlaybackLabels(): void {

    const remainingMs = Math.floor(this.playlist.currentTrack.durationMs) - this.playedMs;

    if (this.progress === 100) {
      this.trackPlayedLabel = this.formatDate(this.playlist.currentTrack.durationMs);
      this.trackRemainingLabel = '-' + this.formatDate(0);
      return;
    }
    this.trackRemainingLabel = '-' + this.formatDate(remainingMs);
    this.trackPlayedLabel = this.formatDate(this.playedMs);
  }

  private formatDate(ms: number): string {
    const s = Math.floor(ms / 1000);
    const minutes = Math.floor(s / 60).toString();
    let seconds = (s % 60).toString();
    if (seconds.length === 1) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  }

  private setProgress(): void {
    this.playedMs = this.playlist.progressMs;
    this.progress = Math.min(this.playedMs / Math.floor(this.playlist.currentTrack.durationMs) * 100, 100);

    this.publisher.fireOnUpdatePlayProgressEvent(this.progress);
  }

  private handleNextPlaylistRefresh(): void {
    if (this.playState === PlayStateEnum.Play) {
      this.setPlaylistUpdateTimeout();
    }
  }

  private setPlaylistUpdateTimeout(): void {
    //console.log('Upcoming playlist refresh', this.playlist.currentTrack.durationMs - this.playlist.progressMs + 1000);
    this.refreshPlaylistTimeoutId = setTimeout(
      function () {
        this.streamingService.updatePlaylist();
      }.bind(this), this.playlist.currentTrack.durationMs - this.playlist.progressMs + 1000
    );
  }

  private clearPlaylistUpdateTimeout(): void {
    clearTimeout(this.refreshPlaylistTimeoutId);
  }

  private progressPlayback(): void {
    switch (this.playState) {
      case PlayStateEnum.NotPlaying:
      case PlayStateEnum.Pause:
      case PlayStateEnum.Stopped:
        return;
      case PlayStateEnum.Play: {
        this.playlist.progressMs += 1000;
      }
    }
  }

}
