import { PlayStateEnum } from '../enums/PlayStateEnum';
import { UserModel } from './UserModel';

export class TrackModel {
    id: string;
    url: string;
    name: string;
    artistName: string;
    albumName: string;
    trackNumber: number;
    totalNumberOfTrackInPlaylist: number;
    genre: string;
    artworkUrl: string;
    isPlayable: boolean;
    durationMs: number; //in

    playState: PlayStateEnum = PlayStateEnum.NotPlaying;
    playAt: number = 0;

    public constructor(init?: Partial<TrackModel>) {
        Object.assign(this, init);
    }
}
