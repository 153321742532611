export class TextConverterHelper {

  public static format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  static normalize(input: string): string {
    return input.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static getOrderByValue<T>(item: T, key: string): string {
    let result: string = this.normalize(item[key]);

    if (result.startsWith('THE ')) {
      result = result.slice(4);
    }

    if (result.startsWith('A ')) {
      result = result.slice(2);
    }

    if (result.startsWith('AN ')) {
      result = result.slice(3);
    }

    result = this.removeSpecialCharsFromTheBeginning(result);

    if (this.format.test(result.substring(0, 1))) {
      result = result.slice(1);
    }
    // tslint:disable-next-line:radix
    if (!isNaN(parseInt(result))) {
      // tslint:disable-next-line:radix
      return parseInt(result).toString();
    }
    return result;
  }

  static getOrderByValueFromString(item: string): string {

    let result: string = this.normalize(item);

    if (result.startsWith('THE ')) {
      result = result.slice(4);
    }

    if (result.startsWith('A ')) {
      result = result.slice(2);
    }

    if (result.startsWith('AN ')) {
      result = result.slice(3);
    }

    result = this.removeSpecialCharsFromTheBeginning(result);

    if (this.format.test(result.substring(0, 1))) {
      result = result.slice(1);
    }
    // tslint:disable-next-line:radix
    if (!isNaN(parseInt(result))) {
      // tslint:disable-next-line:radix
      return parseInt(result).toString();
    }
    return result;
  }

  static removeSpecialCharsFromTheBeginning(input: string): string {
    return input.replace(/^[!-\/:-@\[-`\{-~]*/, '').replace(/\s+$/g, '');
  }

  static isNumeric(item: string): boolean {
    if (item.match(/^\d/)) {
      return true;
    }
    return false;
  }

  static getFirstCharOfLabel(label: string): string {
    const normalized = this.getOrderByValueFromString(label);
    return normalized.substring(0, 1).toUpperCase();
  }
}
