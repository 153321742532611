<div id="click-wheel-container">
  <img id="pointer" src="/assets/icons/pointer.png" *ngIf="this.stateService.tooltipAnimationVisible">
  <button id="enter-button"
          (click)="enterButtonClicked()"
          [style]="{
                top:this.clickWheelThickness+'px',
                left:this.clickWheelThickness+'px',
                width:this.buttonWidth + 'px',
                height:this.buttonWidth + 'px',
                borderRadius:this.buttonWidth + 'px'}"
  >

  </button>

  <div id="play-arrow">
    <svg version="1.1" id="webpod-play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 298.667 298.667" style="enable-background:new 0 0 298.667 298.667;" xml:space="preserve">
<g>
	<g>
		<polygon points="32,0 32,298.667 266.667,149.333 		"/>
	</g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
</svg>
  </div>
  <div id="pause-arrow">
    <svg version="1.1" id="webpod-pause" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 298.667 298.667" style="enable-background:new 0 0 298.667 298.667;" xml:space="preserve">
<g>
	<g>
		<g>
			<rect x="192" y="0" width="85.333" height="298.667"/>
      <rect x="21.333" y="0" width="85.333" height="298.667"/>
		</g>
	</g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
</svg>
  </div>
  <svg version="1.1" id="webpod-forward-arrow-pt-1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 373.333 373.333" style="enable-background:new 0 0 373.333 373.333;" xml:space="preserve">
<g>
	<g>
		<g>
			<polygon points="0,314.667 181.333,186.667 0,58.667 			"/>
      <polygon points="192,58.667 192,314.667 373.333,186.667 			"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
  <div id="webpod-forward-arrow-pt-2"></div>

  <svg version="1.1" id="webpod-backward-arrow-pt-1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 373.333 373.333" style="enable-background:new 0 0 373.333 373.333;" xml:space="preserve">
<g>
	<g>
		<g>
			<polygon points="0,314.667 181.333,186.667 0,58.667 			"/>
      <polygon points="192,58.667 192,314.667 373.333,186.667 			"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
  <div id="webpod-backward-arrow-pt-2"></div>
  <div id="menu-label">MENU</div>

  <div id="click-wheel" [style]="{width:this.clickWheelWidth + 'px'}">
    <input class="infinite" value="0"
           data-fgcolor="#DDDDDD00"
           data-bgcolor="#DDDDDD00"
           data-displayinput="false"
           data-cursor="true"
           style="display: none;
                   width: 0px;
                   visibility: hidden;">
    <div></div>
  </div>

</div>

<div id="click-wheel-debug" class="debug-element">
  <div class="ival" style="width:80px;text-align:center;font-size:50px;color:#AAA"></div>
  <div class="idir" style="width: 80px; text-align: center; font-size: 50px; display: none;">-</div>
  <div class="cwaction" style="width: 80px; text-align: center; font-size: 50px; display: none;">{{lastClickValue}}
  </div>
</div>
