import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ClickWheelComponent} from './components/clickwheel/click-wheel.component';
import {MenuPageComponent} from './components/menu-page/menu-page.component';
import {HeaderComponent} from './components/header/header.component';
import {MenuComponent} from './components/menu/menu.component';
import {NowPlayingPageComponent} from './components/now-playing-page/now-playing-page.component';
import {LoginComponent} from './components/login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {SelectDevicePageComponent} from './components/select-device-page/select-device-page.component';
import {VolumeControlComponent} from './components/now-playing-page/volume-control/volume-control.component';
import {SeekControlComponent} from './components/now-playing-page/seek-control/seek-control.component';
import {DeviceControlComponent} from './components/now-playing-page/device-control/device-control.component';
import {PlaybackModeControlComponent} from './components/now-playing-page/playback-mode-control/playback-mode-control.component';
import {NowPlayingBarComponent} from './components/now-playing-page/now-playing-bar/now-playing-bar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TutorialModalComponent} from './components/tutorial-modal/tutorial-modal.component';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import { AboutComponent } from './components/about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    ClickWheelComponent,
    MenuPageComponent,
    HeaderComponent,
    MenuComponent,
    NowPlayingPageComponent,
    LoginComponent,
    SelectDevicePageComponent,
    VolumeControlComponent,
    SeekControlComponent,
    DeviceControlComponent,
    PlaybackModeControlComponent,
    NowPlayingBarComponent,
    TutorialModalComponent,
    FeedbackComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
