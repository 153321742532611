<div class="menu-container">
  <div class="menu-body">
    <form *ngIf="this.showForm">
      <div class="form-group" id="contact-wrapper">
        <label for="exampleFormControlInput1" class="text-3">Contact</label>
        <input [(ngModel)]="sender"
               type="email"
               class="form-control"
               name="sender"
               id="exampleFormControlInput1"
               placeholder="name@example.com(optional)">
      </div>
      <div class="form-group">
        <label for="message-textarea" class="text-3">Message</label>
        <textarea [(ngModel)]="message"
                  name="message"
                  class="form-control"
                  id="message-textarea"
                  placeholder="Feel free to type your feedback here..">
        </textarea>
      </div>
      <div class="row">

        <div class="button-container col-6">
          <button [disabled]="message===''" role="button" id="send-message-button" (click)="send()">Send</button>
        </div>

        <div class="button-container col-6">
          <button role="button" id="back-button" (click)="backButtonClick()">Back</button>
        </div>
      </div>
    </form>
    <p *ngIf="!showForm">Thank you for your feedback!</p>
  </div>
</div>
