import {Component, OnInit} from '@angular/core';
import {ClickWheelValuesEnum} from '../../enums/ClickWheelValuesEnum';
import {PublisherService} from '../../services/publisher.service';
import {StateService} from '../../services/state.service';

@Component({
  selector: 'app-clickwheel',
  templateUrl: './click-wheel.component.html',
  styleUrls: ['./click-wheel.component.scss']
})
export class ClickWheelComponent implements OnInit {

  private clickWheelWidthRatio = 0.612;
  private clickWheelhicknessRatio = 0.193;
  private buttonWidthratio = 0.225;
  public buttonRadiusRartioComparedToClickWheelWidth = 0.368;
  public clickWheelInnerThicknessRatio = 0.631;

  private clickWheelMaxValue = 24; // must be dividable by 4
  private fourthOfMaxVale = this.clickWheelMaxValue / 4;
  private clickThreshold = this.clickWheelMaxValue * 0.05;


  public clickWheelWidth = 0;
  public buttonWidth = 0;
  public clickWheelThickness = 0;
  public screenWidth = 0;

  public previousValue = 0;
  public lastKeyDownDefaultValue = -1;
  public lastKeyDownTimestamp = this.lastKeyDownDefaultValue;
  public keydownThreshold = 500;
  public changeSinceRelease = false;


  private $cwaction: any;

  public lastClickValue: ClickWheelValuesEnum = ClickWheelValuesEnum.Invalid;

  constructor(private publisher: PublisherService, public stateService: StateService) {


  }

  ngOnInit(): void {
    this.$cwaction = $('div.cwaction');
    this.calculateElementDimensions();
    this.initializeClickWheel();
  }

  private calculateElementDimensions(): void {
    this.computeSreenWidth();
    this.computeClickWheelWidth();
    this.computeClickWheelThickness();
    this.computeButtonWidth();
  }

  private computeSreenWidth(): void {
    this.screenWidth = document.getElementById('device')!.getBoundingClientRect().width;
  }

  computeClickWheelWidth(): void {

    this.clickWheelWidth = Math.round(this.screenWidth * this.clickWheelWidthRatio);

  }


  computeClickWheelThickness(): void {
    this.clickWheelThickness = this.screenWidth * this.clickWheelhicknessRatio;
  }

  computeButtonWidth(): void {
    this.buttonWidth = this.screenWidth * this.buttonWidthratio;
  }

  private initializeClickWheel() {


    let i = 0;
    const $idir = $('div.idir');
    const $ival = $('div.ival');
    const that = this;
    const incr = () => {
      i++;
      $idir.show().html('+').fadeOut();
      $ival.html(i);
      that.triggerScrollIncrease();
    };
    const decr = () => {
      i--;
      $idir.show().html('-').fadeOut();
      $ival.html(i);
      that.triggerScrollDecrease();
    };
    $('input.infinite').knob(
      {
        min: 0,
        max: this.clickWheelMaxValue,
        width: this.clickWheelWidth,
        height: this.clickWheelWidth,
        thickness: this.clickWheelInnerThicknessRatio,
        stopper: false,
        change(a: number) {
          // console.log('change');

          if (that.lastKeyDownTimestamp === that.lastKeyDownDefaultValue) {
            that.lastKeyDownTimestamp = Date.now();
          }

          if (this.cv === that.previousValue + 1) {
            // console.log('incr', this.cv, that.previousValue);
            incr();
            that.changeSinceRelease = true;
          } else if (this.cv === that.previousValue - 1) {
            // console.log('decr', this.cv, that.previousValue);
            decr();
            that.changeSinceRelease = true;
          }

          that.previousValue = this.cv;
        },
        release(a: number) {
          // console.log('release');
          that.handleRelease(a);

        },
      });
  }

  isClick(): boolean {
    const timestampDifference = Date.now() - this.lastKeyDownTimestamp;
    if (timestampDifference < this.keydownThreshold && this.changeSinceRelease === false) {
      return true;
    }
    return false;
  }

  handleRelease(valeAtRelease: number): void {
    if (this.isClick()) {

      this.lastClickValue = this.clusterizeClickRelease(valeAtRelease);
      this.triggerClickWheelEvent(this.lastClickValue);

      this.debugShowClickEvent();
    }

    this.resetRelease();
  }

  private clusterizeClickRelease(valueAtClick: number): ClickWheelValuesEnum {

    if (valueAtClick < this.clickThreshold || ((this.clickWheelMaxValue - 1 - this.clickThreshold) < valueAtClick)) {
      return ClickWheelValuesEnum.Menu;
    }

    if (valueAtClick < this.fourthOfMaxVale + this.clickThreshold && valueAtClick > this.fourthOfMaxVale - this.clickThreshold) {
      return ClickWheelValuesEnum.Forward;
    }

    if (valueAtClick < this.fourthOfMaxVale * 2 + this.clickThreshold &&
      valueAtClick > this.fourthOfMaxVale * 2 - this.clickThreshold) {
      return ClickWheelValuesEnum.Play;
    }

    if (valueAtClick < this.fourthOfMaxVale * 3 + this.clickThreshold &&
      valueAtClick > this.fourthOfMaxVale * 3 - this.clickThreshold) {
      return ClickWheelValuesEnum.Backward;
    }

    return ClickWheelValuesEnum.Invalid;
  }

  enterButtonClicked(): void {
    this.lastClickValue = ClickWheelValuesEnum.Enter;
    this.triggerClickWheelEvent(this.lastClickValue);
    this.debugShowClickEvent();

  }

  triggerScrollIncrease(): void {
    this.lastClickValue = ClickWheelValuesEnum.ScrollIncrease;
    this.triggerClickWheelEvent(this.lastClickValue);
    this.debugShowClickEvent();
  }

  triggerScrollDecrease(): void {
    this.lastClickValue = ClickWheelValuesEnum.ScrollDecrease;
    this.triggerClickWheelEvent(this.lastClickValue);
    this.debugShowClickEvent();
  }

  private debugShowClickEvent(): void {
    this.$cwaction.show().html(this.lastClickValue.toString()).fadeOut();
  }

  private resetRelease(): void {
    this.changeSinceRelease = false;
    this.lastKeyDownTimestamp = this.lastKeyDownDefaultValue;
  }

  private triggerClickWheelEvent(clickWheelEvent: ClickWheelValuesEnum): void {
    switch (clickWheelEvent) {
      case ClickWheelValuesEnum.Menu: {
        this.publisher.fireMenuButtonClicked();
        break;
      }
      case ClickWheelValuesEnum.Forward: {
        this.publisher.fireOnForwardClicked();
        break;
      }
      case ClickWheelValuesEnum.Play: {
        this.publisher.fireOnPlayClicked();
        break;
      }
      case ClickWheelValuesEnum.Backward: {
        this.publisher.fireOnBackwardClicked();
        break;
      }
      case ClickWheelValuesEnum.ScrollIncrease: {
        this.publisher.fireScrollIncrease();
        break;
      }
      case ClickWheelValuesEnum.ScrollDecrease: {
        this.publisher.fireScrollDecrease();
        break;
      }
      case ClickWheelValuesEnum.Enter: {
        this.publisher.fireOnEnterClicked();
        break;
      }
    }

  }

}


declare var $: any;
