import {IMenuItem} from '../interfaces/IMenuItem';
import {MenuItemTypeEnum} from '../enums/MenuItemTypeEnum';
import {AlbumModel} from './AlbumModel';
import {TrackModel} from './TrackModel';
import {DeviceModel} from './DeviceModel';
import {IClickWheelEventsSubscriber} from '../interfaces/IClickWheelEventsSubscriber';
import {ArtistModel} from './ArtistModel';
import {PlaylistModel} from './PlaylistModel';
import {NowPlayingPlaylistModel} from './NowPlayingPlaylistModel';

export class MenuItem implements IMenuItem, IClickWheelEventsSubscriber {

  TAG: string = 'MenuItem';
  label: string = '';
  isSelectable: boolean = true;
  type: MenuItemTypeEnum;
  uuid: number;
  album: AlbumModel = new AlbumModel();
  track: TrackModel = new TrackModel();
  device: DeviceModel = new DeviceModel();
  artist: ArtistModel = new ArtistModel();
  playlist: PlaylistModel = new PlaylistModel();
  playlistWithTracks: NowPlayingPlaylistModel | null = null;

  public constructor(init?: Partial<MenuItem>) {
    Object.assign(this, init);
    this.uuid = Math.floor(Math.random() * 100000000000000000);
  }

  onBackwardClicked(): void {
  }

  onScrollDecrease(): void {
  }

  onEnterClicked(): void {
  }

  onForwardClicked(): void {
  }

  onScrollIncrease(): void {
  }

  onMenuButtonClicked(): void {
  }

  onPlayClicked(): void {
  }
}
