import {AppleMusicPlaybackStatesEnum} from '../enums/AppleMusicPlaybackStatesEnum';
import {PlayStateEnum} from '../enums/PlayStateEnum';

export class AppleMusicHelper {
  public static translateAppleMusicPlaybackState(e: AppleMusicPlaybackStatesEnum): PlayStateEnum {
    switch (e) {
      case AppleMusicPlaybackStatesEnum.COMPLETED:
      case AppleMusicPlaybackStatesEnum.ENDED:
      case AppleMusicPlaybackStatesEnum.LOADING:
      case AppleMusicPlaybackStatesEnum.NONE:
      case AppleMusicPlaybackStatesEnum.NULL:
      case AppleMusicPlaybackStatesEnum.STALLED:
      case AppleMusicPlaybackStatesEnum.STOPPED:
      case AppleMusicPlaybackStatesEnum.WAITING:
        return PlayStateEnum.NotPlaying;
      case AppleMusicPlaybackStatesEnum.PAUSED:
        return PlayStateEnum.Pause;
      case AppleMusicPlaybackStatesEnum.SEEKING:
      case AppleMusicPlaybackStatesEnum.PLAYING:
        return PlayStateEnum.Play;
    }
  }
}
