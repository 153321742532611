<div class="volume-bar">
  <div class="progress-bar-row">
    <div class="bar">
      <div class="progress-slide"
           [ngStyle]="{'width.%':this.playService.behaviorFactory.behavior.volume}"
           [ngClass]="{'disabled':this.volumeSettingsAvailable=== false}"
      >

      </div>
    </div>
  </div>
</div>
