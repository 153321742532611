<div class="login-container"
     [ngStyle]="{'height.px':this.stateService.pageHeight}"
>
  <div class="provider-container"
       [ngClass]="{'selected':this.isSpotifySelected}">
    <div class="logo-container">
      <img class="logo" src="/assets/logos/Spotify_Logo_RGB_Green.png">
    </div>
    <!-- <div class="button-container">
         <button class="text-4"
                 [ngClass]="{'selected':this.isSpotifySelected}"
                 (click)="this.loginToSpotifyButtonClicked()">
             <div class="glare"></div>
             Login to Spotify
         </button>
     </div>-->
  </div>
  <div class="provider-container"
       [ngClass]="{'selected':this.isAppleMusicSelected}">
    <div class="logo-container">
      <!--
      <img *ngIf="this.isAppleMusicSelected" id="coming-soon" src="/assets/images/coming-soon.png">
      -->
      <img class="logo" src="/assets/logos/apple-music-logo.svg">
    </div>
    <!--
    <div class="button-container">
        <button class="text-4"
                [ngClass]="{'selected':this.isAppleMusicSelected}">
            <div class="glare"></div>
            Login to Apple Music
        </button>
    </div>
    -->
  </div>
</div>
