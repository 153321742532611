import {Component, Input, OnInit} from '@angular/core';
import {MenuItemTypeEnum} from 'src/app/enums/MenuItemTypeEnum';
import {MenuModel} from '../../models/MenuModel';
import {StateService} from '../../services/state.service';

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss']
})
export class MenuPageComponent implements OnInit {
  @Input() menuModel!: MenuModel;

  TAG = '--';


  public MenuItemTypeEnum = MenuItemTypeEnum;

  constructor(public stateService: StateService) {

  }

  ngOnInit(): void {
  }
}
