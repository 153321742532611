import {PlayStateEnum} from '../../../enums/PlayStateEnum';
import {TrackModel} from '../../../models/TrackModel';
import {PlayListTypeEnum} from '../../../enums/PlayListTypeEnum';

export class CurrentlyPlayingResponseModel {
  playState: PlayStateEnum | null = null;
  progressMs: number | null = null;
  currentlyPlayingIndex: number | null = null;
  track: TrackModel | null = null;
  playlistType: PlayListTypeEnum | null = null;
  playlistId: string | null = null;

  public constructor(init?: Partial<CurrentlyPlayingResponseModel>) {
    Object.assign(this, init);
  }
}
