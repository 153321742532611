import {Injectable} from '@angular/core';
import {PublisherService} from './publisher.service';
import {ILoginSubscriber} from '../interfaces/ILoginSubscriber';
import {StreamEngineEnum} from '../enums/StreamEngineEnum';
import {StreamingService} from './streaming.service';
import {UserModel} from '../models/UserModel';
import {LoginTypesEnum} from '../enums/LoginTypesEnum';
import {StringResources} from '../enums/StringResources';
import {StateService} from './state.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService implements ILoginSubscriber {

  public TAG: string = 'AccountingService';

  public user: UserModel = new UserModel();
  public isLoggedIn: boolean = false;

  constructor(private publisher: PublisherService, private streamingService: StreamingService,
              private stateService: StateService) {
    this.publisher.subscribeToLoginEvents(this);
  }


  onSpotifyAccessTokenChange(): void {
  }

  onLogin(type: LoginTypesEnum): void {
    this.isLoggedIn = true;
    this.publisher.fireOnNavigateToMainMenuRequest();
    this.stateService.setSelectedMenuName(StringResources.ApplicationName);
    this.setUserModel().catch(console.error);
  }

  private async setUserModel(): Promise<void> {
    var result = await this.streamingService.getUser();
    this.user = result;
  }

  onLogout(): void {
    this.stateService.setSelectedMenuName(StringResources.LoginText);
  }
}
