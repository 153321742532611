<div class="now-playing-container" [ngStyle]="{'height.px':this.stateService.pageHeight}">
  <div class=" row top-padding-row"></div>
  <div class=" header-row">
    <div class="number-of-song-container text left-screen-padding">
      {{this.playService.behaviorFactory.behavior.playlist.currentTrack.trackNumber}} of
      {{this.playService.behaviorFactory.behavior.playlist.currentTrack.totalNumberOfTrackInPlaylist}}
    </div>
  </div>
  <div class="body-row">
    <div class="artwork-column">
      <img src="{{this.playService.behaviorFactory.behavior.playlist.currentTrack.artworkUrl}}">
    </div>
    <div class="details-column">
      <div class="text details-padding">{{this.playService.behaviorFactory.behavior.playlist.currentTrack.name}}</div>
      <div
        class="text details-padding">{{this.playService.behaviorFactory.behavior.playlist.currentTrack.artistName}}</div>
      <div
        class="text details-padding">{{this.playService.behaviorFactory.behavior.playlist.currentTrack.albumName}}</div>
    </div>
  </div>
  <div id="control-container">
    <div class="bar-container"
         [ngStyle]="{'margin-left.px':this.barOffset}">
      <app-now-playing-bar></app-now-playing-bar>
    </div>
    <app-volume-control *ngIf="this.volumeControlOn"></app-volume-control>
    <app-seek-control></app-seek-control>
    <app-device-control></app-device-control>
    <app-playback-mode-control></app-playback-mode-control>
  </div>
</div>
