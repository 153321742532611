import {Injectable} from '@angular/core';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {PlaybackModeEnum} from '../enums/PlaybackModeEnum';
import {IPlayPauseResumeEventSubscriber} from '../interfaces/IPlayPauseResumeEventSubscriber';
import {IChangeTrackSubscriber} from '../interfaces/INextTrackSubscriber';
import {ITrackFinishedSubscriber} from '../interfaces/ITrackFinishedSubscriber';
import {PublisherService} from './publisher.service';
import {StreamingService} from './streaming.service';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {IPlaylistChangedSubscriber} from '../interfaces/IPlaylistChangedSubscriber';
import {PlayListTypeEnum} from '../enums/PlayListTypeEnum';
import {PlayBehaviorFactoryService} from './play-behavior-factory.service';

@Injectable({
  providedIn: 'root'
})
export class PlayService
  implements IPlayPauseResumeEventSubscriber,
    IChangeTrackSubscriber,
    ITrackFinishedSubscriber,
    IPlaylistChangedSubscriber {


  public TAG = 'PlayService';

  public get playSate(): PlayStateEnum {
    return this.behaviorFactory.behavior.playState;
  }

  constructor(private publisher: PublisherService,
              private streamingService: StreamingService,
              public behaviorFactory: PlayBehaviorFactoryService) {

    publisher.subscribeToPlayPauseResumeEvents(this);
    publisher.subscribeToChangeTrackEvents(this);
    publisher.subscribeToTrackFinishedEvent(this);
    publisher.subscribeToPlaylistChangedEvents(this);
  }

  onPlayStateUpdateReceived(playStateUpdate: PlayStateEnum): void {
    this.behaviorFactory.behavior.playState = playStateUpdate;
  }

  public loadPlaylist(playlist: NowPlayingPlaylistModel): void {
    this.behaviorFactory.behavior.playlist = playlist;
  }

  async onPauseRequested(): Promise<void> {
    await this.streamingService.pause();
    this.behaviorFactory.behavior.playState = PlayStateEnum.Pause;
  }

  async onPlayClicked(): Promise<void> {

    await this.behaviorFactory.behavior.playClicked();
  }


  onNextTrack(): void {
    this.behaviorFactory.behavior.nextTrack().catch(console.error);
  }

  onPreviousTrack(): void {
  }

  public async previousTrack(): Promise<void> {
    await this.behaviorFactory.behavior.previousTrack();
  }

  async nextTrack(): Promise<void> {
    await this.behaviorFactory.behavior.nextTrack();
  }


  onResumeRequested(): void {
  }

  onTrackFinished(): void {
    this.behaviorFactory.behavior.nextTrack().catch(console.error);
  }

  public async setVolume(volume: number): Promise<void> {
    return await this.behaviorFactory.behavior.setVolume(volume);
  }

  public async getVolume(): Promise<number> {
    return await this.streamingService.getVolume();
  }

  public async startPlayingASingleTrack(): Promise<void> {
    await this.behaviorFactory.behavior.startPlayingASingleTrack();
  }


  public async playPlaylist(): Promise<void> {
    await this.behaviorFactory.behavior.playPlaylist();
  }

  public async showCurrentlyPlaying(): Promise<void> {
    try {
      await this.behaviorFactory.behavior.showCurrentlyPlaying();

    } catch (e) {
      console.error('Play.service. startPlaying exception', e);
    }
  }

  public seek(seekToMs: number): void {
    this.behaviorFactory.behavior.seek(seekToMs);
  }

  onPlaylistChangedEvent(newPlaylist: NowPlayingPlaylistModel): void {
    this.behaviorFactory.behavior.onPlaylistChanged(newPlaylist);
  }
}
