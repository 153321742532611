import {TrackModel} from './TrackModel';

export class PlaylistModel {
  id: string = '';
  name: string = '';
  imageUrl: string = '';

  public constructor(init?: Partial<PlaylistModel>) {
    Object.assign(this, init);
  }
}
