import {Injectable} from '@angular/core';
import {DeviceModel} from '../models/DeviceModel';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {TrackModel} from '../models/TrackModel';
import {PublisherService} from './publisher.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private publisher: PublisherService) {
  }

  get activeDevice(): DeviceModel | undefined {
    return this._activeDevice;
  }

  private _activeDevice: DeviceModel | undefined;

  deviceList: Array<DeviceModel> = new Array<DeviceModel>();

  setActiveDevice(a: DeviceModel): void {
    if (this._activeDevice != null) {
      //transfer
      this._activeDevice = a;
      this.publisher.fireOnDeviceTransferred();
    } else {
      //regular assigment
      this._activeDevice = a;
    }
    this.publisher.fireOnDeviceSet();
  }

  setDevices(devices: Array<DeviceModel>): void {
    this.publisher.fireOnDeviceListUpdated();
    this.deviceList = devices;
  }
}
