<div class="now-playing" *ngIf="playService.behaviorFactory.behavior">
  <div class="progress-bar-row">
    <div class="bar">
      <div class="progress-slide" [ngStyle]="{'width.%':this.playService.behaviorFactory.behavior.progress}">

      </div>
    </div>
  </div>
  <div class="progress-details">
    <div class="progress-detail length text left-screen-padding">
      {{this.playService.behaviorFactory.behavior.trackPlayedLabel}}
    </div>
    <div class="progress-detail remaining text right-screen-padding">
      {{this.playService.behaviorFactory.behavior.trackRemainingLabel}}
    </div>
  </div>
</div>
