import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuModel} from '../../models/MenuModel';
import {MenuItemTypeEnum} from '../../enums/MenuItemTypeEnum';
import {MenuItem} from '../../models/MenuItem';
import {PublisherService} from '../../services/publisher.service';
import {StateService} from '../../services/state.service';
import {ExceptionBase} from '../../exceptions/ExceptionBase';
import {INowPlayingSlideEventSubscriber} from '../../interfaces/INowPlayingSlideEventSubscriber';
import {AuthorizationService} from '../../services/authorization.service';
import {INavigationEventSubscriber} from '../../interfaces/INavigationSubscriber';
import {StreamingService} from '../../services/streaming.service';
import {PlayService} from '../../services/play.service';
import {IMenuButtonEventsSubscriber} from '../../interfaces/IMenuButtonEventsSubscriber';
import {IEnterButtonEventsSubscriber} from '../../interfaces/IEnterButtonEventsSubscriber';
import {IScrollSubscriber} from '../../interfaces/IScrollSubscriber';
import {DeviceService} from '../../services/device.service';
import {INextPreviousButtonEventsSubscriber} from '../../interfaces/INextPreviousButtonEventsSubscriber';
import {IPlayButtonSubscriber} from '../../interfaces/IPlayButtonSubscriber';
import {NowPlayingPlaylistModel} from '../../models/NowPlayingPlaylistModel';
import {LocalStorageKeysEnum} from '../../enums/LocalStorageKeysEnum';
import {TutorialModalComponent} from '../tutorial-modal/tutorial-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ILoginSubscriber} from '../../interfaces/ILoginSubscriber';
import {LoginTypesEnum} from '../../enums/LoginTypesEnum';
import {IFeedbackEventsSubscriber} from '../../interfaces/IFeedbackEventsSubscriber';
import {StringResources} from '../../enums/StringResources';
import {IAboutEventsSubscriber} from '../../interfaces/IAboutEventsSubscriber';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,
  AfterViewInit,
  INowPlayingSlideEventSubscriber,
  INavigationEventSubscriber,
  IMenuButtonEventsSubscriber,
  IEnterButtonEventsSubscriber,
  INextPreviousButtonEventsSubscriber,
  IPlayButtonSubscriber,
  IScrollSubscriber,
  ILoginSubscriber,
  IFeedbackEventsSubscriber,
  IAboutEventsSubscriber {

  public menuList = Array<MenuModel>();
  public pageOffSet = 0;

  private activeMenuIndex = 0;
  public atSong = false;
  public atDeviceSelector = false;
  public atFeedback = false;
  public atAbout = false;

  TAG = 'MenuComponent';

  constructor(private publisher: PublisherService,
              public stateService: StateService,
              private streamingService: StreamingService,
              public deviceService: DeviceService,
              private playService: PlayService,
              public authorizationService: AuthorizationService, // csak copy token miatt
              private modalService: NgbModal,
              private cdr: ChangeDetectorRef) {
    const mainMenuItems = new Array<MenuItem>();

    mainMenuItems.push(new MenuItem({label: '', type: MenuItemTypeEnum.AlbumItem}));
    const mainMenu = new MenuModel('temp', mainMenuItems);

    this.publisher.subscribeToNowPlayingSlideEvents(this);
    this.publisher.subscribeToNavigationEventsEvents(this);
    this.publisher.subscribeToNextPreviousButtonEvents(this);
    this.publisher.subscribeToPlayButtonEvents(this);
    this.publisher.subscribeToLoginEvents(this);
    this.publisher.subscribeToFeedbackEvents(this);
    this.publisher.subscribeToAboutEvents(this);

    this.menuList.push(mainMenu);
  }


  ngAfterViewInit(): void {
    this.stateService.onResize();
    this.authorizationService.loginRoutine();
    this.cdr.detectChanges();

  }

  ngOnInit(): void {
  }

  private openTutorialForTheFirstTime(): void {
    if (this.authorizationService.spotifyLoggedIn === false) {
      const doNotShowString = window.localStorage.getItem(LocalStorageKeysEnum.DoNotShowTutorial)!;

      if (doNotShowString !== 'true') {
        this.openTutorial();
      }
    }
  }

  private openTutorial(): void {
    if (this.stateService.atFeedback === true) {
      return;
    }
    const modalRef = this.modalService.open(TutorialModalComponent);
  }

  onScrollIncrease(): void {
    this.menuList[this.activeMenuIndex].onScrollIncrease();
  }

  onScrollDecrease(): void {
    this.menuList[this.activeMenuIndex].onScrollDecrease();
  }

  onBackwardClicked(): void {
    this.playService.previousTrack().catch(console.error);
  }

  onForwardClicked(): void {
    this.playService.nextTrack().catch(console.error);
  }


  onEnterClicked(): void {

    this.getNextPage().catch(console.error);
    /*
    if (this.menuList[this.activeMenuIndex].selectedMenuItem.type !== MenuItemTypeEnum.SongItem) {
        this.toNextMenu();
    } else {
        this.toSong();
    }*/
    // MenuItemTypeEnum.NowPlaying todo
  }

  private setActiveMenuName(): void {
    this.stateService.setSelectedMenuName(this.menuList[this.activeMenuIndex].name);
  }

  onMenuButtonClicked(): void {
    if (this.atSong) {
      this.atSong = false;
      this.slidePages();
      return;
    }
    if (this.activeMenuIndex > 0) {
      this.activeMenuIndex--;
      this.setActiveMenuName();
      setTimeout(function () {
        this.menuList.pop();
      }.bind(this), 500);
      this.slidePages();
    }
  }

  private slidePages(): void {
    this.pageOffSet = -this.activeMenuIndex * this.stateService.pageWidth;
    if (this.atSong || this.atDeviceSelector || this.atFeedback || this.atAbout) {
      // we slide to the now playing page
      this.pageOffSet -= this.stateService.pageWidth;
    }
    if (this.authorizationService.spotifyLoggedIn) {
      // we slide from the  login page
      this.pageOffSet -= this.stateService.pageWidth;
    }
    if (this.authorizationService.appleMusicLoggedIn) {
      // we slide from the  login page
      this.pageOffSet -= this.stateService.pageWidth;
    }
  }

  onPlayClicked(): void {
    this.playService.onPlayClicked();
  }

  private async getNextPage(): Promise<void> {
    switch (this.menuList[this.activeMenuIndex].selectedMenuItem.type) {

      case (MenuItemTypeEnum.AlbumsCategoryItem): {
        await this.toAlbumCatalog();
        break;
      }
      case (MenuItemTypeEnum.AlbumItem): {
        await this.toAlbum();
        break;
      }
      case (MenuItemTypeEnum.ArtistsCategoryItem): {
        await this.toArtistCatalog();
        break;
      }
      case (MenuItemTypeEnum.ArtistItem): {
        await this.toArtist();
        break;
      }
      case (MenuItemTypeEnum.PlaylistsCategoryItem): {
        await this.toPlaylists();
        break;
      }
      case (MenuItemTypeEnum.PlaylistItem): {
        await this.toPlaylist();
        break;
      }
      case (MenuItemTypeEnum.LogoutItem): {
        await this.toLogout();
        break;
      }
      case (MenuItemTypeEnum.NowPlaying): {
        await this.toNowPlaying();
        break;
      }
      case (MenuItemTypeEnum.SongsCategoryItem): {
        await this.toSongs();
        break;
      }
      case (MenuItemTypeEnum.SongItem): {
        await this.toSong();
        break;
      }
      case (MenuItemTypeEnum.SelectDeviceItem): {
        await this.streamingService.setDevices();
        this.publisher.fireOnShowSelectDevice(null);
        break;
      }
      case (MenuItemTypeEnum.FeedbackItem): {
        await this.streamingService.setDevices();
        this.publisher.fireNavigateToFeedback();
        break;
      }
      case (MenuItemTypeEnum.AboutItem): {
        this.publisher.fireNavigateToAbout();
        break;
      }
      default: {

        this.toNextMenu();
        break;
      }

    }
  }

  toNextMenu(): void {
    const childMenu = this.menuList[this.activeMenuIndex].getChildMenu();
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toAlbumCatalog(): Promise<void> {
    const albums = await this.streamingService.getAlbums();
    const albumMenuItems = new Array<MenuItem>();

    albums.forEach(a => {
      albumMenuItems.push(new MenuItem({
        album: a,
        label: a.name,
        type: MenuItemTypeEnum.AlbumItem
      }));
    });

    const childMenu = new MenuModel('Albums', albumMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toArtistCatalog(): Promise<void> {
    const artists = await this.streamingService.getArtists();
    const artistMenuItems = new Array<MenuItem>();

    artists.forEach(a => {
      artistMenuItems.push(new MenuItem({
        label: a.name,
        artist: a,
        type: MenuItemTypeEnum.ArtistItem
      }));
    });

    const childMenu = new MenuModel('Artists', artistMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toAlbum(): Promise<void> {

    const album = this.menuList[this.activeMenuIndex].selectedMenuItem.album;
    const tracks = await this.streamingService.getAlbumsTracks(album);

    const trackMenuItems = new Array<MenuItem>();

    tracks.forEach(t => {
      trackMenuItems.push(new MenuItem({
        label: t.name,
        track: t,
        album,
        type: MenuItemTypeEnum.SongItem
      }));
    });

    const childMenu = new MenuModel(album.name, trackMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toArtist(): Promise<void> {
    const artist = this.menuList[this.activeMenuIndex].selectedMenuItem.artist;

    const albums = await this.streamingService.getArtistsAlbums(artist.id);
    const albumMenuItems = new Array<MenuItem>();

    albums.forEach(a => {
      albumMenuItems.push(new MenuItem({
        album: a,
        label: a.name,
        type: MenuItemTypeEnum.AlbumItem
      }));
    });

    const childMenu = new MenuModel('Albums', albumMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toPlaylists(): Promise<void> {

    const playlists = await this.streamingService.getPlaylists();
    const playlistsMenuItems = new Array<MenuItem>();

    playlists.forEach(p => {
      playlistsMenuItems.push(new MenuItem({
        playlist: p,
        label: p.name,
        type: MenuItemTypeEnum.PlaylistItem
      }));
    });

    const childMenu = new MenuModel('Playlists', playlistsMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toPlaylist(): Promise<void> {

    const playlist = this.menuList[this.activeMenuIndex].selectedMenuItem.playlist;
    const playlistResponse = await this.streamingService.getPlaylist(playlist.id);
    const playlistsMenuItems = new Array<MenuItem>();

    playlistResponse.tracks.forEach(t => {
      playlistsMenuItems.push(new MenuItem({
        track: t,
        playlistWithTracks: playlistResponse,
        label: t.name,
        type: MenuItemTypeEnum.SongItem
      }));
    });

    const childMenu = new MenuModel(playlistResponse.name, playlistsMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toSongs(): Promise<void> {

    const playingPlaylistModel = await this.streamingService.getSavedTracks();
    const trackMenuItems = new Array<MenuItem>();

    playingPlaylistModel.tracks.forEach(t => {
      trackMenuItems.push(new MenuItem({
        track: t,
        playlistWithTracks: playingPlaylistModel,
        label: t.name,
        type: MenuItemTypeEnum.SongItem
      }));
    });

    const childMenu = new MenuModel('Songs', trackMenuItems);
    this.menuList.push(childMenu);
    this.activeMenuIndex++;
    this.setActiveMenuName();
    this.slidePages();
  }

  async toLogout(): Promise<void> {

    await this.authorizationService.logoutRoutine();
    this.publisher.fireOnNavigateToLogin();
  }

  async toNowPlaying(): Promise<void> {

    if (this.playService.behaviorFactory.behavior.playlist.tracks.length !== 0)
      if (this.deviceService.activeDevice === undefined) {

        await this.requestPlaybackDevice()
          .then(async function () {
            await this.playService.showCurrentlyPlaying();
            this.showPlaybackPage();
          }.bind(this))
          .catch(error => {
            console.log('rejected', error);
          });
      } else {
        await this.playService.showCurrentlyPlaying();
        await this.showPlaybackPage();
      }
  }

  async toSong(): Promise<void> {
    try {
      const track = this.menuList[this.activeMenuIndex].selectedMenuItem.track;
      if (track.id === this.playService.behaviorFactory.behavior.playlist.currentTrack.id) {
        return this.toNowPlaying();
      }
      let playlist: NowPlayingPlaylistModel;
      if (this.menuList[this.activeMenuIndex].selectedMenuItem.playlistWithTracks !== null) {
        playlist = this.menuList[this.activeMenuIndex].selectedMenuItem.playlistWithTracks;
      } else if (this.menuList[this.activeMenuIndex].selectedMenuItem.album !== undefined) {
        const album = this.menuList[this.activeMenuIndex].selectedMenuItem.album;
        playlist = await this.streamingService.getAlbum(album.id);
      }
      playlist.setCurrentTrackByTrackId(track.id);
      this.playService.loadPlaylist(playlist);
      //this.playService.loadPlayListLEGACY(playlist.tracks, this.menuList[this.activeMenuIndex].selectedIndex);

      /**
       * Ha nincs Device beállítva akkor addig ne lépjünk tovább, amíg nincs lejátszónk
       */
      if (this.deviceService.activeDevice === undefined) {

        await this.requestPlaybackDevice()
          .then(async function () {
            await this.playService.playPlaylist();
            this.showPlaybackPage();
          }.bind(this))
          .catch(error => {
            console.log('rejected', error);
          });
      } else {
        await this.playService.playPlaylist();
        await this.showPlaybackPage();
      }

    } catch (e) {
      if (e instanceof ExceptionBase) {
        console.error('Exception:', e.description);
        return;
      } else {
        console.error('Unknown exception caught:', e);
      }
    }
  }

  private async showPlaybackPage(): Promise<void> {
    //await this.playService.startPlaying();
    this.atSong = true;
    this.publisher.fireNowPlayingSlideShow();
  }

  private async requestPlaybackDevice(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.publisher.fireOnShowSelectDevice({resolve, reject});
    });
  }

  onNowPlayingSlideHide(): void {
    this.subscribeToClickWheelEventSet();
    this.atSong = false;
    this.slidePages();
  }

  onNowPlayingSlideShow(): void {
    this.unsubscribeToClickWheelEventSet();
    this.atSong = true;
    this.slidePages();
  }

  onSpotifyAccessTokenChange(): void {
  }

  onSpotifyLogin(): void {
  }

  onSpotifyLogout(): void {
  }

  onNavigateLogin(): void {
    this.unsubscribeToClickWheelEventSet();
    this.slidePages();
  }

  onNavigateToMainMenu(): void {
    this.stateService.setSelectedMenuName(StringResources.ApplicationName);
  }

  onNavigateToMainMenuRequest(): void {
    this.subscribeToClickWheelEventSet();
    this.slidePages();
  }

  onShowSelectDevice(): void {
    this.toDeviceSelector();
  }

  onHideSelectDevice(): void {
    this.fromDeviceSelector();
  }

  onFeedbackShowEvent(): void {
    this.toFeedback();
  }

  onFeedbackHideEvent(): void {
    this.fromFeedback();
  }

  onAboutShowEvent(): void {
    this.toAbout();
  }

  onAboutHideEvent(): void {
    this.fromAbout()
  }

  private toDeviceSelector(): void {
    this.unsubscribeToClickWheelEventSet();
    this.atDeviceSelector = true;
    this.slidePages();
  }

  private fromDeviceSelector(): void {
    this.subscribeToClickWheelEventSet();
    this.atDeviceSelector = false;
    this.slidePages();
  }

  private toFeedback(): void {
    this.unsubscribeToClickWheelEventSet();
    this.atFeedback = true;
    this.stateService.atFeedback = true;
    this.slidePages();
  }

  private fromFeedback(): void {
    this.subscribeToClickWheelEventSet();
    this.atFeedback = false;
    this.stateService.atFeedback = false;
    this.slidePages();
  }

  private toAbout(): void {
    this.unsubscribeToClickWheelEventSet();
    this.atAbout = true;
    this.slidePages();
  }

  private fromAbout(): void {
    this.subscribeToClickWheelEventSet();
    this.atAbout = false;
    this.slidePages();
  }

  private subscribeToClickWheelEventSet(): void {
    this.publisher.subscribeToScrollEvents(this);
    this.publisher.subscribeToMenuButtonEvents(this);
    this.publisher.subscribeToEnterButtonEvents(this);
  }

  private unsubscribeToClickWheelEventSet(): void {
    this.publisher.unsubscribeToScrollEvents(this);
    this.publisher.unsubscribeToMenuButtonEvents(this);
    this.publisher.unsubscribeToEnterButtonEvents(this);
  }

  onLogin(type: LoginTypesEnum): void {

    this.menuList.pop() //temp menu removal;

    const mainMenuItems = this.playService.behaviorFactory.behavior.getMainMenu();

    const mainMenu = new MenuModel(StringResources.ApplicationName, mainMenuItems);

    this.menuList.push(mainMenu);
    this.slidePages();
  }

  onLogout(): void {
  }
}
