///  <reference types="@types/spotify-web-playback-sdk"/>

import {Injectable} from '@angular/core';
import {PublisherService} from './publisher.service';
import {SpotifyLoginTokensEnum} from '../enums/SpotifyLoginTokensEnum';
import {InvalidTokenHandlingException} from '../exceptions/InvalidTokenHandlingException';
import {DataService} from './data.service';
import {LoginTypesEnum} from '../enums/LoginTypesEnum';
import {EventKeys} from '../enums/EventKeys';
import {StringResources} from '../enums/StringResources';
import {StateService} from './state.service';
import {environment} from '../../environments/environment';

declare var MusicKit: any;

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public TAG = 'AuthorizationService';

  musicKit: any;

  public spotifyAccessToken = '';
  public spotifyRefreshToken = '';
  public region = '';
  public spotifyLoggedIn = false;
  public appleMusicLoggedIn = false;

  constructor(private publisher: PublisherService,
              private dataService: DataService,
              private stateService: StateService) {
    try {
      this.initializeMusicKit();
    } catch (e) {
      if (e.errorCode === 'CONFIGURATION_ERROR') {
        this.listenToMusicKitConfiguredEvent();
      }
      console.error('musicKit error', e.errorCode);
    }
  }

  private listenToMusicKitConfiguredEvent(): void {
    document.addEventListener(EventKeys.musickitconfigured, this.initializeMusicKit.bind(this));
  }

  private initializeMusicKit(): void {
    this.musicKit = MusicKit.getInstance();
    this.handleAppleMusicLoggedIn();
    this.musicKit.addEventListener(MusicKit.Events.authorizationStatusDidChange, function (e) {
    });
    this.musicKit.addEventListener(MusicKit.Events.authorizationStatusDidChange, this.handleAppleMusicLoggedIn.bind(this));
  }

  public loginRoutine(): void {
    this.checkSpotifyCallback();
    this.readSpotifyTokensFromLocalstorage();
    if (this.isSpotifyLoggedIn()) {
      return this.handleSpotifyLoggedIn();
    }
    if (this.isAppleMusicLoggedIn()) {
      return this.handleAppleMusicLoggedIn();
    }
    this.stateService.setSelectedMenuName(StringResources.LoginText);
  }

  async logoutRoutine(): Promise<void> {

    this.clearTokensFromLocalstorage();
    if (this.isSpotifyLoggedIn()) {
      this.isSpotifyLoggedIn();
    }
    if (this.isAppleMusicLoggedIn()) {
      await this.musicKit.unauthorize();
      this.appleMusicLoggedIn = false;
    }
    this.publisher.fireLogout()
  }

  public getAuthorizationHeaders(): any {

    // todo only with spotify ATM
    return {
      headers: {
        Authorization: 'Bearer ' + this.spotifyAccessToken
      }
    };
  }

  private checkSpotifyCallback(): void {

    const params = this.getSpotifyHashParams();

    if (
      params[SpotifyLoginTokensEnum.SpotifyAccessToken] !== undefined &&
      params[SpotifyLoginTokensEnum.SpotifyRefreshToken] !== undefined &&
      params[SpotifyLoginTokensEnum.Region] !== undefined
    ) {
      this.spotifyAccessToken = params[SpotifyLoginTokensEnum.SpotifyAccessToken];
      this.spotifyRefreshToken = params[SpotifyLoginTokensEnum.SpotifyRefreshToken];
      this.region = params[SpotifyLoginTokensEnum.Region];
      this.writeSpotifyTokensToLocalstorage();
      this.removeFragment();
    }

  }

  public async refreshTokenSpotify(): Promise<void> {
    if (this.spotifyRefreshToken === '') {
      throw new InvalidTokenHandlingException();
    }
    try {
      const response = await this.dataService.get(environment.SpotifyLoginBackendUrl + this.spotifyRefreshToken, {});
      this.spotifyAccessToken = response[SpotifyLoginTokensEnum.SpotifyAccessToken];
      this.writeSpotifyTokensToLocalstorage();
    } catch (e: any) {
      // TODO notification
      console.error(e);
    }
  }

  private writeSpotifyTokensToLocalstorage(): void {
    if (this.spotifyRefreshToken === '' || this.spotifyAccessToken === '') {
      throw new InvalidTokenHandlingException();
    }
    window.localStorage.setItem(SpotifyLoginTokensEnum.SpotifyRefreshToken, this.spotifyRefreshToken);
    window.localStorage.setItem(SpotifyLoginTokensEnum.SpotifyAccessToken, this.spotifyAccessToken);
    window.localStorage.setItem(SpotifyLoginTokensEnum.Region, this.region);
  }

  private readSpotifyTokensFromLocalstorage(): void {
    if (window.localStorage.getItem(SpotifyLoginTokensEnum.SpotifyRefreshToken) !== null) {
      this.spotifyRefreshToken = window.localStorage.getItem(SpotifyLoginTokensEnum.SpotifyRefreshToken)!;
    }
    if (window.localStorage.getItem(SpotifyLoginTokensEnum.SpotifyAccessToken) !== null) {
      this.spotifyAccessToken = window.localStorage.getItem(SpotifyLoginTokensEnum.SpotifyAccessToken)!;
      //console.log('spotifyAccessToken', this.spotifyAccessToken);
    }

    if (window.localStorage.getItem(SpotifyLoginTokensEnum.Region) !== null) {
      this.region = window.localStorage.getItem(SpotifyLoginTokensEnum.Region)!;
    }
  }

  private clearTokensFromLocalstorage(): void {

    this.spotifyAccessToken = '';
    this.spotifyRefreshToken = '';
    this.region = '';
    window.localStorage.clear();
  }

  // https://stackoverflow.com/questions/269044/remove-fragment-in-url-with-javascript-w-out-causing-page-reload
  private removeFragment(): void {
    window.location.replace('#');

    // slice off the remaining '#' in HTML5:
    if (typeof window.history.replaceState === 'function') {
      history.replaceState({}, '', window.location.href.slice(0, -1));
    }
  }

  private handleSpotifyLoggedIn(): void {

    if (this.isSpotifyLoggedIn()) {
      this.publisher.fireOnLogin(LoginTypesEnum.Spotify);
    }

  }

  private handleAppleMusicLoggedIn(): void {
    if (this.isAppleMusicLoggedIn()) {
      this.publisher.fireOnLogin(LoginTypesEnum.AppleMusic);
    }

  }

  private isSpotifyLoggedIn(): boolean {
    if (this.spotifyRefreshToken !== '' && this.spotifyAccessToken !== '') {
      this.spotifyLoggedIn = true;
      return true;
    }
    this.spotifyLoggedIn = false;
    return false;
  }

  private isAppleMusicLoggedIn(): boolean {
    if (this.musicKit === undefined)
      return false;
    if (this.musicKit.isAuthorized) {
      this.appleMusicLoggedIn = true;
      return true;
    }
    this.appleMusicLoggedIn = false;
    return false;
  }

  private getSpotifyHashParams(): any {
    const hashParams: any = {};
    let e, r = /([^&;=]+)=?([^&;]*)/g,
      q = window.location.hash.substring(1);
    while (e = r.exec(q)) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
  }
}
