import {Injectable} from '@angular/core';
import {UserModel} from '../models/UserModel';
import {PublisherService} from './publisher.service';
import {AuthorizationService} from './authorization.service';
import {TAGResources} from '../enums/TAGResources';
import {ILoginSubscriber} from '../interfaces/ILoginSubscriber';
import {IStreamEngine} from '../interfaces/IStreamEngine';
import {SpotifyEngine} from '../engines/SpotifyEngine';
import {DataService} from './data.service';
import {StreamEngineEnum} from '../enums/StreamEngineEnum';
import {EngineNotSetException} from '../exceptions/EngineNotSetException';
import {AlbumModel} from '../models/AlbumModel';
import {DeviceService} from './device.service';
import {DeviceModel} from '../models/DeviceModel';
import {CurrentlyPlayingResponseModel} from '../engines/Model/Spotify/CurrentlyPlayingResponseModel';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {ArtistModel} from '../models/ArtistModel';
import {PlaylistModel} from '../models/PlaylistModel';
import {LoginTypesEnum} from '../enums/LoginTypesEnum';
import {AppleMusicEngine} from '../engines/AppleMusicEngine';
import {TrackModel} from '../models/TrackModel';
import {CacheService} from '../cache/cache.service';
import {CacheTypeEnum} from '../cache/CacheTypeEnum';
import {StateService} from './state.service';

@Injectable({
  providedIn: 'root'
})
export class StreamingService implements ILoginSubscriber {

  public TAG = TAGResources.StreamingService;
  private engine: IStreamEngine | null = null;
  private engineType: StreamEngineEnum = StreamEngineEnum.None;

  constructor(private publisher: PublisherService,
              private authorizationService: AuthorizationService,
              private dataService: DataService,
              private deviceService: DeviceService,
              private cacheService: CacheService,
              private stateService: StateService) {

    this.publisher.subscribeToLoginEvents(this);
  }


  async getUser(): Promise<UserModel> {

    switch (this.engineType) {
      case (StreamEngineEnum.Spotify): {
        return await this.engine.getUser();
      }
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  async getAlbums(): Promise<Array<AlbumModel>> {

    return await this.cacheService.get<Array<AlbumModel>>(CacheTypeEnum.GetAlbums)
      .catch(err => {
        return this.engine.getAlbums();
      }).then(res => {
        return this.cacheService.store<Array<AlbumModel>>(CacheTypeEnum.GetAlbums, res);
      });

  }

  async getAlbumsTracks(album: AlbumModel): Promise<TrackModel[]> {
    return await this.engine.getAlbumsTracks(album);
  }

  async getAlbum(albumId: string): Promise<NowPlayingPlaylistModel> {

    switch (this.engineType) {
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.Spotify): {
        return await this.engine.getAlbum(albumId);
      }
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  async getArtists(): Promise<Array<ArtistModel>> {
    return await this.cacheService.get<Array<ArtistModel>>(CacheTypeEnum.GetArtists)
      .catch(err => {
        return this.engine.getArtists();
      }).then(res => {
        return this.cacheService.store<Array<ArtistModel>>(CacheTypeEnum.GetArtists, res);
      });
  }

  async getArtistsAlbums(artistId: string): Promise<Array<AlbumModel>> {

    switch (this.engineType) {
      case (StreamEngineEnum.Spotify):
      case (StreamEngineEnum.AppleMusic): {
        return await this.engine.getArtistsAlbums(artistId);
      }
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  async getPlaylists(): Promise<Array<PlaylistModel>> {

    return await this.cacheService.get<Array<PlaylistModel>>(CacheTypeEnum.GetPlaylists)
      .catch(err => {
        return this.engine.getPlaylists();
      }).then(res => {
        return this.cacheService.store<Array<PlaylistModel>>(CacheTypeEnum.GetPlaylists, res);
      });
  }

  async getPlaylist(id: string): Promise<NowPlayingPlaylistModel> {

    switch (this.engineType) {
      case (StreamEngineEnum.Spotify): {
        return await this.engine.getPlaylist(id);
      }
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  async getSavedTracks(): Promise<NowPlayingPlaylistModel> {
    return await this.cacheService.getSmart(CacheTypeEnum.GetSavedTracks, NowPlayingPlaylistModel)
      .catch(async err => {
        return await this.engine.getSavedTracks();
      }).then(res => {
        return this.cacheService.store<NowPlayingPlaylistModel>(CacheTypeEnum.GetSavedTracks, res);
      });
  }

  async setDevices(): Promise<Array<DeviceModel>> {
    switch (this.engineType) {
      case (StreamEngineEnum.Spotify): {
        return await this.engine.getDevices();
      }
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }


  async playPlaylist(playlist: NowPlayingPlaylistModel): Promise<void> {
    switch (this.engineType) {
      case (StreamEngineEnum.Spotify):
      case (StreamEngineEnum.AppleMusic): {
        await this.engine.playPlaylist(playlist);
        break;
      }
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  public async updatePlaylist(): Promise<void> {

    switch (this.engineType) {
      case (StreamEngineEnum.Spotify): {
        await this.engine.updatePlaylist();
        break;
      }
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  async playTrack(trackId: string): Promise<void> {
    switch (this.engineType) {
      case (StreamEngineEnum.Spotify): {
        await this.engine.playTrack(trackId);
        break;
      }
      case (StreamEngineEnum.AppleMusic):
      case (StreamEngineEnum.None): {
        throw new EngineNotSetException();
      }
    }
  }

  onSpotifyAccessTokenChange(): void {
  }

  onLogin(type: LoginTypesEnum): void {
    switch (type) {
      case LoginTypesEnum.AppleMusic:
        this.engine = new AppleMusicEngine(
          this.authorizationService,
          this.dataService,
          this.publisher,
          this.deviceService,
          this.TAG);
        this.engineType = StreamEngineEnum.Spotify;
        break;
      case LoginTypesEnum.Spotify:
        this.engine = new SpotifyEngine(
          this.authorizationService,
          this.dataService,
          this.publisher,
          this.deviceService,
          this.stateService,
          this.TAG);
        this.engineType = StreamEngineEnum.Spotify;
    }


    this.engine.initialize().catch(console.error);
  }

  onLogout(): void {
  }

  async resume(): Promise<void> {
    await this.engine.resumePlayback();
  }

  async pause(): Promise<void> {
    await this.engine.pausePlayback();
  }

  public isVolumeSettingAvailable(): boolean {
    return this.engine && this.engine.isVolumeSettingAvailable();
  }

  public async setVolume(volume: number): Promise<void> {
    if (this.engine.isVolumeSettingAvailable()) {
      return this.engine.setVolume(volume);
    }
  }

  public async getVolume(): Promise<number> {
    if (this.isVolumeSettingAvailable()) {
      return this.engine.getVolume();
    }
  }

  public async getCurrentlyPlaying(): Promise<CurrentlyPlayingResponseModel> {
    return await this.engine.getCurrentlyPlaying();
  }

  public async previousTrack(): Promise<void> {
    return await this.engine.previousTrack();
  }

  public async nextTrack(): Promise<void> {
    return await this.engine.nextTrack();
  }

  public async seek(positionMs: number): Promise<void> {
    return await this.engine.seek(positionMs);
  }

}
