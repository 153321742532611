import {Injectable} from '@angular/core';
import {IPublisher} from '../interfaces/IPublisher';
import {IClickWheelEventsSubscriber} from '../interfaces/IClickWheelEventsSubscriber';
import {INowPlayingSlideEventSubscriber} from '../interfaces/INowPlayingSlideEventSubscriber';
import {ILoginSubscriber} from '../interfaces/ILoginSubscriber';
import {INavigationEventSubscriber} from '../interfaces/INavigationSubscriber';
import {IPlayPauseResumeEventSubscriber} from '../interfaces/IPlayPauseResumeEventSubscriber';
import {ITrackFinishedSubscriber} from '../interfaces/ITrackFinishedSubscriber';
import {IChangeTrackSubscriber} from '../interfaces/INextTrackSubscriber';
import {IDeviceChangedSubscriber} from '../interfaces/IDeviceChangedSubscriber';
import {IMenuButtonEventsSubscriber} from '../interfaces/IMenuButtonEventsSubscriber';
import {IEnterButtonEventsSubscriber} from '../interfaces/IEnterButtonEventsSubscriber';
import {INextPreviousButtonEventsSubscriber} from '../interfaces/INextPreviousButtonEventsSubscriber';
import {IPlayButtonSubscriber} from '../interfaces/IPlayButtonSubscriber';
import {IScrollSubscriber} from '../interfaces/IScrollSubscriber';
import {IDeviceBrowserRequestEventsSubscriber} from '../interfaces/IDeviceBrowserRequestEventsSubscriber';
import {IPlaybackModeControlShowHideEventsSubscriber} from '../interfaces/IPlaybackModeControlShowHideEventsSubscriber';
import {IDeviceControlShowHideEventsSubscriber} from '../interfaces/IDeviceControlShowHideEventsSubscriber';
import {IVolumeControlShowHideEventsSubscriber} from '../interfaces/IVolumeControlShowHideEventsSubscriber';
import {ISeekControlShowHideEventsSubscriber} from '../interfaces/ISeekControlShowHideEventsSubscriber';
import {INowPlayingBarShowHideEventsSubscriber} from '../interfaces/INowPlayingBarShowHideEventsSubscriber';
import {IVolumeControlsAvailableUpdateSubscriber} from '../interfaces/IVolumeSettingsAvailableUpdateSubscriber';
import {ISelectDeviceRequestSubscriber} from '../interfaces/ISelectDeviceRequestSubscriber';
import {PlayStateEnum} from '../enums/PlayStateEnum';
import {IPlaylistChangedSubscriber} from '../interfaces/IPlaylistChangedSubscriber';
import {NowPlayingPlaylistModel} from '../models/NowPlayingPlaylistModel';
import {IPlayProgressEventSubscriber} from '../interfaces/IPlayProgressEventSubscriber';
import {LoginTypesEnum} from '../enums/LoginTypesEnum';
import {IFeedbackEventsSubscriber} from '../interfaces/IFeedbackEventsSubscriber';
import {IAboutEventsSubscriber} from '../interfaces/IAboutEventsSubscriber';

@Injectable({
  providedIn: 'root'
})
export class PublisherService implements IPublisher {

  private subscribeDebug = false;
  private triggerDebug = false;

  private nowPlayingSlideEventSubscribers: Array<INowPlayingSlideEventSubscriber>;
  private loginEventSubscribers: Array<ILoginSubscriber>;
  private navigationEventSubscribers: Array<INavigationEventSubscriber>;
  private playPauseResumeEventSubscribers: Array<IPlayPauseResumeEventSubscriber>;
  private trackFinishedEventSubscribers: Array<ITrackFinishedSubscriber>;
  private changeTrackEventSubscribers: Array<IChangeTrackSubscriber>;
  private deviceChangedEventSubscribers: Array<IDeviceChangedSubscriber>;
  private menuButtonEventSubscribers: Array<IMenuButtonEventsSubscriber>;
  private scrollSubscribers: Array<IScrollSubscriber>;
  private enterButtonEventSubscribers: Array<IEnterButtonEventsSubscriber>;
  private nextPreviousButtonEventSubscribers: Array<INextPreviousButtonEventsSubscriber>;
  private playButtonEventSubscribers: Array<IPlayButtonSubscriber>;
  private deviceBrowserRequestEventsSubscriber: Array<IDeviceBrowserRequestEventsSubscriber>;
  private playbackModeControlShowHideEventSubscribers: Array<IPlaybackModeControlShowHideEventsSubscriber>;
  private deviceControlShowHideEventSubscribers: Array<IDeviceControlShowHideEventsSubscriber>;
  private volumeControlShowHideEventsSubscribers: Array<IVolumeControlShowHideEventsSubscriber>;
  private seekControlShowHideEventsSubscribers: Array<ISeekControlShowHideEventsSubscriber>;
  private nowPlayingBarShowHideEventsSubscribers: Array<INowPlayingBarShowHideEventsSubscriber>;
  private volumeControlsAvailableSubscribers: Array<IVolumeControlsAvailableUpdateSubscriber>;
  private selectDeviceRequestSubscribers: Array<ISelectDeviceRequestSubscriber>;
  private playlistChangedSubscribers: Array<IPlaylistChangedSubscriber>;
  private playProgressEventSubscribers: Array<IPlayProgressEventSubscriber>;
  private feedbackEventSubscribers: Array<IFeedbackEventsSubscriber>;
  private aboutEventSubscribers: Array<IAboutEventsSubscriber>;


  constructor() {

    this.nowPlayingSlideEventSubscribers = Array<INowPlayingSlideEventSubscriber>();
    this.loginEventSubscribers = Array<ILoginSubscriber>();
    this.navigationEventSubscribers = Array<INavigationEventSubscriber>();
    this.playPauseResumeEventSubscribers = Array<IPlayPauseResumeEventSubscriber>();
    this.trackFinishedEventSubscribers = Array<ITrackFinishedSubscriber>();
    this.changeTrackEventSubscribers = Array<IChangeTrackSubscriber>();
    this.deviceChangedEventSubscribers = new Array<IDeviceChangedSubscriber>();
    this.menuButtonEventSubscribers = new Array<IMenuButtonEventsSubscriber>();
    this.scrollSubscribers = new Array<IScrollSubscriber>();
    this.enterButtonEventSubscribers = new Array<IEnterButtonEventsSubscriber>();
    this.nextPreviousButtonEventSubscribers = new Array<INextPreviousButtonEventsSubscriber>();
    this.playButtonEventSubscribers = new Array<IPlayButtonSubscriber>();
    this.deviceBrowserRequestEventsSubscriber = new Array<IDeviceBrowserRequestEventsSubscriber>();
    this.playbackModeControlShowHideEventSubscribers = new Array<IPlaybackModeControlShowHideEventsSubscriber>();
    this.deviceControlShowHideEventSubscribers = new Array<IDeviceControlShowHideEventsSubscriber>();
    this.volumeControlShowHideEventsSubscribers = new Array<IVolumeControlShowHideEventsSubscriber>();
    this.seekControlShowHideEventsSubscribers = new Array<ISeekControlShowHideEventsSubscriber>();
    this.nowPlayingBarShowHideEventsSubscribers = new Array<INowPlayingBarShowHideEventsSubscriber>();
    this.volumeControlsAvailableSubscribers = new Array<IVolumeControlsAvailableUpdateSubscriber>();
    this.selectDeviceRequestSubscribers = new Array<ISelectDeviceRequestSubscriber>();
    this.playlistChangedSubscribers = new Array<IPlaylistChangedSubscriber>();
    this.playProgressEventSubscribers = new Array<IPlayProgressEventSubscriber>();
    this.feedbackEventSubscribers = new Array<IFeedbackEventsSubscriber>();
    this.aboutEventSubscribers = new Array<IAboutEventsSubscriber>();
  }

  private getAboutEventSubscribers(): Array<IAboutEventsSubscriber> {
    const listCopy = new Array<IAboutEventsSubscriber>();
    this.aboutEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getFeedbackEventSubscribers(): Array<IFeedbackEventsSubscriber> {
    const listCopy = new Array<IFeedbackEventsSubscriber>();
    this.feedbackEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getNowPlayingSlideEventSubscribers(): Array<INowPlayingSlideEventSubscriber> {
    const listCopy = new Array<INowPlayingSlideEventSubscriber>();
    this.nowPlayingSlideEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getLoginSubscribers(): Array<ILoginSubscriber> {
    const listCopy = new Array<ILoginSubscriber>();
    this.loginEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getNavigationEventSubscribers(): Array<INavigationEventSubscriber> {
    const listCopy = new Array<INavigationEventSubscriber>();
    this.navigationEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getPlayPauseResumeEventSubscribers(): Array<IPlayPauseResumeEventSubscriber> {
    const listCopy = new Array<IPlayPauseResumeEventSubscriber>();
    this.playPauseResumeEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getTrackFinishedSubscribers(): Array<ITrackFinishedSubscriber> {
    const listCopy = new Array<ITrackFinishedSubscriber>();
    this.trackFinishedEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }


  private getChangeTrackEventsSubscribers(): Array<IChangeTrackSubscriber> {
    const listCopy = new Array<IChangeTrackSubscriber>();
    this.changeTrackEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getDeviceChangedSubscribers(): Array<IDeviceChangedSubscriber> {
    const listCopy = new Array<IDeviceChangedSubscriber>();
    this.deviceChangedEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getMenuButtonSubscribers(): Array<IMenuButtonEventsSubscriber> {
    const listCopy = new Array<IMenuButtonEventsSubscriber>();
    this.menuButtonEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getScrollSubscribers(): Array<IScrollSubscriber> {
    const listCopy = new Array<IScrollSubscriber>();
    this.scrollSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getEnterButtonEventSubscribers(): Array<IEnterButtonEventsSubscriber> {
    const listCopy = new Array<IEnterButtonEventsSubscriber>();
    this.enterButtonEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getNextPreviousButtonEventSubscribers(): Array<INextPreviousButtonEventsSubscriber> {
    const listCopy = new Array<INextPreviousButtonEventsSubscriber>();
    this.nextPreviousButtonEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getPlayButtonSubscribers(): Array<IPlayButtonSubscriber> {
    const listCopy = new Array<IPlayButtonSubscriber>();
    this.playButtonEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getDeviceBrowserRequestEventsSubscribers(): Array<IDeviceBrowserRequestEventsSubscriber> {
    const listCopy = new Array<IDeviceBrowserRequestEventsSubscriber>();
    this.deviceBrowserRequestEventsSubscriber.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getPlaybackModeControlShowHideEventSubscribers(): Array<IPlaybackModeControlShowHideEventsSubscriber> {
    const listCopy = new Array<IPlaybackModeControlShowHideEventsSubscriber>();
    this.playbackModeControlShowHideEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getDeviceControlShowHideEventsSubscribers(): Array<IDeviceControlShowHideEventsSubscriber> {
    const listCopy = new Array<IDeviceControlShowHideEventsSubscriber>();
    this.deviceControlShowHideEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getVolumeControlShowHideEventsSubscribers(): Array<IVolumeControlShowHideEventsSubscriber> {
    const listCopy = new Array<IVolumeControlShowHideEventsSubscriber>();
    this.volumeControlShowHideEventsSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getPlayProgressEventSubscribers(): Array<IPlayProgressEventSubscriber> {
    const listCopy = new Array<IPlayProgressEventSubscriber>();
    this.playProgressEventSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getSeekControlShowHideEventsSubscribers(): Array<ISeekControlShowHideEventsSubscriber> {
    const listCopy = new Array<ISeekControlShowHideEventsSubscriber>();
    this.seekControlShowHideEventsSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getNowPlayingBarShowHideSubscribers(): Array<INowPlayingBarShowHideEventsSubscriber> {
    const listCopy = new Array<INowPlayingBarShowHideEventsSubscriber>();
    this.nowPlayingBarShowHideEventsSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getVolumeControlsAvailableSubscribers(): Array<IVolumeControlsAvailableUpdateSubscriber> {
    const listCopy = new Array<IVolumeControlsAvailableUpdateSubscriber>();
    this.volumeControlsAvailableSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getSelectDeviceRequestSubscribers(): Array<ISelectDeviceRequestSubscriber> {
    const listCopy = new Array<ISelectDeviceRequestSubscriber>();
    this.selectDeviceRequestSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  private getPlaylistChangedSubscribers(): Array<IPlaylistChangedSubscriber> {
    const listCopy = new Array<IPlaylistChangedSubscriber>();
    this.playlistChangedSubscribers.forEach(s => {
      listCopy.push(s);
    });
    return listCopy;
  }

  /** End of getters */

  subscribeToPlayPauseResumeEvents(subscriber: IPlayPauseResumeEventSubscriber): void {

    let newSubscriber = true;
    this.playPauseResumeEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.playPauseResumeEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to PlayPauseResumeEvents');
      }
    }
  }

  unsubscribeFromPlayPauseResumeEvents(subscriber: IPlayPauseResumeEventSubscriber): void {

    let index = -1;
    this.playPauseResumeEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playPauseResumeEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a PlayPauseResumeEventSubscriber');
      }
    }
  }

  subscribeToNowPlayingSlideEvents(subscriber: INowPlayingSlideEventSubscriber): void {
    let newSubscriber = true;
    this.nowPlayingSlideEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.nowPlayingSlideEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to NowPlayingSlideEvent');
      }
    }
  }

  unsubscribeFromNowPlayingSlideEvents(subscriber: INowPlayingSlideEventSubscriber): void {
    let index = -1;
    this.nowPlayingSlideEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.nowPlayingSlideEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a clickWheelEventSubscriber');
      }
    }
  }

  fireNowPlayingSlideHide(): void {
    this.getNowPlayingSlideEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received nowPlayingSlideHide');
      }
      s.onNowPlayingSlideHide();
    });
  }

  fireNowPlayingSlideShow(): void {
    this.getNowPlayingSlideEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received nowPlayingSlideShow');
      }
      s.onNowPlayingSlideShow();
    });
  }

  subscribeToLoginEvents(subscriber: ILoginSubscriber): void {

    let newSubscriber = true;
    this.loginEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.loginEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to LoginEvents');
      }
    }
  }

  unsubscribeFromLoginEvents(subscriber: ILoginSubscriber): void {
    let index = -1;
    this.loginEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.loginEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a SpotifyLoginEventSubscriber');
      }
    }
  }

  fireOnSpotifyAccessTokenChange(): void {
    this.getLoginSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received SpotifyAccessTokenChange');
      }
      s.onSpotifyAccessTokenChange();
    });
  }

  fireOnLogin(type: LoginTypesEnum): void {
    this.getLoginSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received Login: ' + type);
      }
      s.onLogin(type);
    });
  }

  fireLogout(): void {
    this.getLoginSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received SpotifyLogout');
      }
      s.onLogout();
    });
  }

  subscribeToNavigationEventsEvents(subscriber: INavigationEventSubscriber): void {
    let newSubscriber = true;
    this.navigationEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.navigationEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to NavigationEvents');
      }
    }
  }

  unsubscribeNavigationEventsEvents(subscriber: INavigationEventSubscriber): void {
    let index = -1;
    this.navigationEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.navigationEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a NavigationEventSubscriber');
      }
    }
  }

  fireOnNavigateToMainMenuRequest(): void {
    this.getNavigationEventSubscribers().forEach(s => {
      if (this.subscribeDebug) {
        console.log(s.TAG, 'received NavigateToMainMenu');
      }
      s.onNavigateToMainMenuRequest();
    });
  }

  fireOnNavigateToMainMenu(): void {
    this.getNavigationEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received NavigateToMainMenu');
      }
      s.onNavigateToMainMenu();
    });
  }

  fireOnNavigateToLogin(): void {
    this.getNavigationEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received NavigateToLogin');
      }
      s.onNavigateLogin();
    });
  }

  fireOnShowSelectDevice(showSelectDevicePromise: { resolve: (value?: (PromiseLike<void> | void)) => void; reject: (reason?: any) => void } | null): void {
    this.getNavigationEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received ShowSelectDevice');
      }
      s.onShowSelectDevice(showSelectDevicePromise);
    });
  }

  fireOnHideSelectDevice(): void {
    this.getNavigationEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received ideSelectDevice');
      }
      s.onHideSelectDevice();
    });
  }

  fireOnPauseRequestedEvent(): void {
    this.getPlayPauseResumeEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onPauseRequested');
      }
      s.onPauseRequested();
    });
  }

  fireOnPlayEvent(): void {
    this.getPlayPauseResumeEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onPlayClicked');
      }
      s.onPlayClicked();
    });
  }

  fireOnResumeRequestedEvent(): void {
    this.getPlayPauseResumeEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onResumeRequested');
      }
      s.onResumeRequested();
    });
  }

  fireOnPlayStateUpdateReceived(playStateUpdate: PlayStateEnum): void {
    this.getPlayPauseResumeEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received PlayStateUpdateReceived', playStateUpdate);
      }
      s.onPlayStateUpdateReceived(playStateUpdate);
    });
  }

  subscribeToTrackFinishedEvent(subscriber: ITrackFinishedSubscriber): void {
    let newSubscriber = true;
    this.trackFinishedEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.trackFinishedEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to TrackFinished');
      }
    }
  }

  unsubscribeFromTrackFinishedEvent(subscriber: ITrackFinishedSubscriber): void {
    let index = -1;
    this.trackFinishedEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.trackFinishedEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a TrackFinishedSubscriber');
      }
    }
  }

  fireOnTrackFinished(): void {
    this.getTrackFinishedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onResumeRequested');
      }
      s.onTrackFinished();
    });
  }


  subscribeToChangeTrackEvents(subscriber: IChangeTrackSubscriber): void {
    let newSubscriber = true;
    this.changeTrackEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.changeTrackEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to ChangeTrack');
      }
    }
  }

  unsubscribeToChangeTrackEvents(subscriber: IChangeTrackSubscriber): void {
    let index = -1;
    this.changeTrackEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.changeTrackEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a ChangeTrackEvents');
      }
    }
  }

  onNextTrack(): void {
    this.getChangeTrackEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onNextTrack');
      }
      s.onNextTrack();
    });
  }

  onPreviousTrack(): void {
    this.getChangeTrackEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received onPreviousTrack');
      }
      s.onPreviousTrack();
    });
  }

  subscribeToDeviceChangedEvents(subscriber: IDeviceChangedSubscriber): void {

    let newSubscriber = true;
    this.getDeviceChangedSubscribers().forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.deviceChangedEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to DeviceChange');
      }
    }
  }

  unsubscribeToDeviceChangedEvents(subscriber: IDeviceChangedSubscriber): void {
    let index = -1;
    this.getDeviceChangedSubscribers().forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.deviceChangedEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a DeviceChangeSubscriber');
      }
    }
  }

  fireOnDeviceGone(): void {
    this.getDeviceChangedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received fireOnDeviceGone');
      }
      s.onDeviceGone();
    });
  }

  fireOnDeviceSet(): void {
    this.getDeviceChangedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received fireOnDeviceSet');
      }
      s.onDeviceSet();
    });
  }

  fireOnDeviceListUpdated(): void {
    this.getDeviceChangedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received DeviceListUpdated');
      }
      s.onDeviceListUpdated();
    });
  }

  fireOnDeviceTransferred(): void {
    this.getDeviceChangedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received DeviceTransferred');
      }
      s.onDeviceTransferred();
    });
  }


  subscribeToMenuButtonEvents(subscriber: IMenuButtonEventsSubscriber): void {
    let newSubscriber = true;
    this.menuButtonEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.menuButtonEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to MenuButtonClick');
      }
    }
  }

  unsubscribeToMenuButtonEvents(subscriber: IMenuButtonEventsSubscriber): void {
    let index = -1;
    this.menuButtonEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.menuButtonEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a MenuButtonClickSubscriber');
      }
    }
  }

  fireMenuButtonClicked(): void {
    this.getMenuButtonSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received MenuButtonClick');
      }
      s.onMenuButtonClicked();
    });
  }

  subscribeToScrollEvents(subscriber: IScrollSubscriber): void {
    let newSubscriber = true;
    this.scrollSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.scrollSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to ScrollEvents');
      }
    }
  }

  unsubscribeToScrollEvents(subscriber: IScrollSubscriber): void {
    let index = -1;
    this.scrollSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.scrollSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a Scrollsubscriber');
      }
    }
  }

  fireScrollIncrease(): void {
    this.getScrollSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received ScrollIncrease');
      }
      s.onScrollIncrease();
    });
  }

  fireScrollDecrease(): void {
    this.getScrollSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received ScrollDecrease');
      }
      s.onScrollDecrease();
    });
  }

  subscribeToEnterButtonEvents(subscriber: IEnterButtonEventsSubscriber): void {
    let newSubscriber = true;
    this.enterButtonEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.enterButtonEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to EnterButtonEvents');
      }
    }
  }

  unsubscribeToEnterButtonEvents(subscriber: IEnterButtonEventsSubscriber): void {
    let index = -1;
    this.enterButtonEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.enterButtonEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer an EnterButtonSubscriber');
      }
    }
  }

  fireOnEnterClicked(): void {
    this.getEnterButtonEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received EnterButtonEvent');
      }
      s.onEnterClicked();
    });
  }

  subscribeToNextPreviousButtonEvents(subscriber: INextPreviousButtonEventsSubscriber): void {
    let newSubscriber = true;
    this.nextPreviousButtonEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.nextPreviousButtonEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to NextPreviousButtonEvents');
      }
    }
  }

  unsubscribeToNextPreviousButtonEvents(subscriber: INextPreviousButtonEventsSubscriber): void {
    let index = -1;
    this.nextPreviousButtonEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.nextPreviousButtonEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a NextPreviousButtonEvents');
      }
    }
  }

  fireOnBackwardClicked(): void {
    this.getNextPreviousButtonEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a BackwardClicked');
      }
      s.onBackwardClicked();
    });
  }

  fireOnForwardClicked(): void {
    this.getNextPreviousButtonEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a ForwardClicked');
      }
      s.onForwardClicked();
    });
  }

  subscribeToPlayButtonEvents(subscriber: IPlayButtonSubscriber): void {
    let newSubscriber = true;
    this.playButtonEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.playButtonEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to PlayButtonEvents');
      }
    }
  }

  unsubscribeToPlayButtonEvents(subscriber: IPlayButtonSubscriber): void {
    let index = -1;
    this.playButtonEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playButtonEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a PlayButtonEventSubscriber');
      }
    }
  }

  fireOnPlayClicked(): void {
    this.getPlayButtonSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a PlayButtonEvent');
      }
      s.onPlayClicked();
    });
  }


  subscribeToDeviceBrowserRequestEvents(subscriber: IDeviceBrowserRequestEventsSubscriber): void {
    let newSubscriber = true;
    this.deviceBrowserRequestEventsSubscriber.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.deviceBrowserRequestEventsSubscriber.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to DeviceBrowserRequestEvents');
      }
    }
  }

  unsubscribeToDeviceBrowserRequestEvents(subscriber: IDeviceBrowserRequestEventsSubscriber): void {
    let index = -1;
    this.deviceBrowserRequestEventsSubscriber.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.deviceBrowserRequestEventsSubscriber.splice(index, 1);
      if (this.triggerDebug) {
        console.log(subscriber.TAG, 'is no longer a DeviceBrowserRequestEventSubscriber');
      }
    }
  }

  fireOnDeviceRefreshRequestEvent(): void {
    this.getDeviceBrowserRequestEventsSubscribers().forEach(s => {
      if (this.subscribeDebug) {
        console.log(s.TAG, 'received a DeviceRefreshRequestEvent');
      }
      s.onDeviceRefreshRequestEvent();
    });
  }


  fireOnDeviceRefreshCancelEvent(): void {
    this.getDeviceBrowserRequestEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a DeviceRefreshCancelEvent');
      }
      s.onDeviceRefreshCancelEvent();
    });
  }


  subscribeToPlaybackModeControlShowHideEvents(subscriber: IPlaybackModeControlShowHideEventsSubscriber): void {

    let newSubscriber = true;
    this.playbackModeControlShowHideEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.playbackModeControlShowHideEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to PlaybackModeControlShowHideEvents');
      }
    }
  }

  unsubscribeToPlaybackModeControlShowHideEvents(subscriber: IPlaybackModeControlShowHideEventsSubscriber): void {

    let index = -1;
    this.playbackModeControlShowHideEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playbackModeControlShowHideEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a PlaybackModeControlShowHideEventsSubscriber');
      }
    }
  }

  fireOnPlaybackModeControlHideEvent(): void {

    this.getPlaybackModeControlShowHideEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a PlaybackModeControlHideEvent');
      }
      s.onPlaybackModeControlHideEvent();
    });
  }

  fireOnPlaybackModeControlShowEvent(): void {
    this.getPlaybackModeControlShowHideEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a PlaybackModeControlShowEvent');
      }
      s.onPlaybackModeControlShowEvent();
    });
  }


  subscribeToSeekControlShowHideEventsEvents(subscriber: ISeekControlShowHideEventsSubscriber): void {
    let newSubscriber = true;
    this.seekControlShowHideEventsSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.seekControlShowHideEventsSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to SeekControlShowHideEventsEvents');
      }
    }
  }

  unsubscribeToSeekControlShowHideEventsEvents(subscriber: ISeekControlShowHideEventsSubscriber): void {
    let index = -1;
    this.seekControlShowHideEventsSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.seekControlShowHideEventsSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a SeekControlShowHideEventsEventsSubscriber');
      }
    }
  }

  fireOnSeekControlHideEvent(): void {
    this.getSeekControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a SeekControlHideEvent');
      }
      s.onSeekControlHideEvent();
    });
  }

  fireOnSeekControlShowEvent(): void {
    this.getSeekControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a SeekControlShowEvent');
      }
      s.onSeekControlShowEvent();
    });
  }


  subscribeToDeviceControlShowHideEvents(subscriber: IDeviceControlShowHideEventsSubscriber): void {
    let newSubscriber = true;
    this.deviceControlShowHideEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.deviceControlShowHideEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to DeviceControlShowHideEvents');
      }
    }
  }

  unsubscribeToDeviceControlShowHideEvents(subscriber: IDeviceControlShowHideEventsSubscriber): void {
    let index = -1;
    this.deviceControlShowHideEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.deviceControlShowHideEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a DeviceControlShowHideEventsSubscriber');
      }
    }
  }

  fireOnDeviceControlHideEvent(): void {
    this.getSeekControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a DeviceControlHideEvent');
      }
      s.onSeekControlHideEvent();
    });
  }

  fireOnDeviceControlShowEvent(): void {
    this.getSeekControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a DeviceControlShowEvent');
      }
      s.onSeekControlShowEvent();
    });
  }


  subscribeToVolumeControlShowHideEvents(subscriber: IVolumeControlShowHideEventsSubscriber): void {
    let newSubscriber = true;
    this.volumeControlShowHideEventsSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.volumeControlShowHideEventsSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to VolumeControlShowHideEvents');
      }
    }
  }

  unsubscribeToVolumeControlShowHideEvents(subscriber: IVolumeControlShowHideEventsSubscriber): void {
    let index = -1;
    this.volumeControlShowHideEventsSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.volumeControlShowHideEventsSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a VolumeControlShowHideEventsSubscriber');
      }
    }
  }

  fireOnVolumeControlHideEvent(): void {
    this.getVolumeControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a onVolumeControlHideEvent');
      }
      s.onVolumeControlHideEvent();
    });
  }

  fireOnVolumeControlShowEvent(): void {
    this.getVolumeControlShowHideEventsSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a onVolumeControlShowEvent');
      }
      s.onVolumeControlShowEvent();
    });
  }

  subscribeToNowPlayingBarShowHideEvents(subscriber: INowPlayingBarShowHideEventsSubscriber): void {
    let newSubscriber = true;
    this.nowPlayingBarShowHideEventsSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.nowPlayingBarShowHideEventsSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to NowPlayingBarShowHideEvents');
      }
    }
  }

  unsubscribeToNowPlayingBarShowHideEvents(subscriber: INowPlayingBarShowHideEventsSubscriber): void {
    let index = -1;
    this.nowPlayingBarShowHideEventsSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.nowPlayingBarShowHideEventsSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a NowPlayingBarShowHideEventsSubscriber');
      }
    }
  }

  fireOnNowPlayingBarHideEvent(): void {
    this.getNowPlayingBarShowHideSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a NowPlayingBarHideEvent');
      }
      s.onNowPlayingBarHideEvent();
    });
  }

  fireOnNowPlayingBarShowEvent(): void {
    this.getNowPlayingBarShowHideSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a NowPlayingBarShowEvent');
      }
      s.onNowPlayingBarShowEvent();
    });
  }

  subscribeToVolumeControlsAvailableUpdateEvents(subscriber: IVolumeControlsAvailableUpdateSubscriber): void {
    let newSubscriber = true;
    this.volumeControlsAvailableSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.volumeControlsAvailableSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to VolumeControlsAvailableUpdateEvents');
      }
    }
  }

  unsubscribeToVolumeControlsAvailableUpdateEvents(subscriber: IVolumeControlsAvailableUpdateSubscriber): void {
    let index = -1;
    this.volumeControlsAvailableSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.volumeControlsAvailableSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a VolumeControlsAvailableUpdateEventsSubscriber');
      }
    }
  }

  fireOnVolumeControlsAvailableUpdate(isAvailable: boolean): void {
    this.getVolumeControlsAvailableSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a VolumeControlsAvailableUpdateEvents of', isAvailable);
      }
      s.onControlsAvailableUpdate(isAvailable);
    });
  }


  subscribeToSelectDeviceRequestEvents(subscriber: ISelectDeviceRequestSubscriber): void {
    let newSubscriber = true;
    this.selectDeviceRequestSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.selectDeviceRequestSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to SelectDeviceRequests');
      }
    }
  }

  unsubscribeToSelectDeviceRequestEvents(subscriber: ISelectDeviceRequestSubscriber): void {
    let index = -1;
    this.selectDeviceRequestSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.selectDeviceRequestSubscribers.splice(index, 1);
      if (this.triggerDebug) {
        console.log(subscriber.TAG, 'is no longer a SelectDeviceRequestSubscriber');
      }
    }
  }

  fireOnSelectDeviceRequest(): void {
    this.getSelectDeviceRequestSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a OnSelectDeviceRequest');
      }
      s.onSelectDeviceRequest();
    });
  }


  subscribeToPlaylistChangedEvents(subscriber: IPlaylistChangedSubscriber): void {
    let newSubscriber = true;
    this.playlistChangedSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.playlistChangedSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to PlaylistChanged');
      }
    }
  }

  unsubscribeToPlaylistChangedEvents(subscriber: IPlaylistChangedSubscriber): void {
    let index = -1;
    this.playlistChangedSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playlistChangedSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a PlaylistChangedSubscriber');
      }
    }
  }

  fireOnPlaylistChanged(newPlaylist: NowPlayingPlaylistModel): void {

    this.getPlaylistChangedSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a PlaylistChanged with the value of', newPlaylist);
      }
      s.onPlaylistChangedEvent(newPlaylist);
    });
  }

  subscribeToPlayProgressEvents(subscriber: IPlayProgressEventSubscriber): void {

    let newSubscriber = true;
    this.playProgressEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.playProgressEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to PlayProgressEvents');
      }
    }
  }

  unsubscribeFromPlayProgressEvents(subscriber: IPlayProgressEventSubscriber): void {
    let index = -1;
    this.playProgressEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playProgressEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a PlayProgressEventSubscriber');
      }
    }
  }

  fireOnUpdatePlayProgressEvent(playProgressPercent: number): void {
    this.getPlayProgressEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        //  console.log(s.TAG, 'received a PlayProgressEvent with the value of', playProgressPercent);
      }
      s.onUpdatePlayProgressEvent(playProgressPercent);
    });
  }

  subscribeToFeedbackEvents(subscriber: IFeedbackEventsSubscriber): void {
    let newSubscriber = true;
    this.feedbackEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.feedbackEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to FeedBackEvents');
      }
    }
  }

  unsubscribeToFeedbackEvents(subscriber: IFeedbackEventsSubscriber): void {
    let index = -1;
    this.playProgressEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.playProgressEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a FeedbackEventSubscriber');
      }
    }
  }

  fireNavigateBackFromFeedback(): void {
    this.getFeedbackEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a NavigateBackFromFeedback with');
      }
      s.onFeedbackHideEvent();
    });
  }

  fireNavigateToFeedback(): void {
    this.getFeedbackEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a NavigateToFeedback');
      }
      s.onFeedbackShowEvent();
    });
  }

  fireNavigateBackFromAbout(): void {
    this.getAboutEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a fireNavigateBackFromAbout ');
      }
      s.onAboutHideEvent();
    });
  }

  fireNavigateToAbout(): void {
    this.getAboutEventSubscribers().forEach(s => {
      if (this.triggerDebug) {
        console.log(s.TAG, 'received a fireNavigateToAbout');
      }
      s.onAboutShowEvent();
    });
  }

  subscribeToAboutEvents(subscriber: IAboutEventsSubscriber): void {
    let newSubscriber = true;
    this.aboutEventSubscribers.forEach(s => {
      if (s.TAG === subscriber.TAG) {
        newSubscriber = false;
      }
    });
    if (newSubscriber) {
      this.aboutEventSubscribers.push(subscriber);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'subscribed to subscribeToAboutEvents');
      }
    }
  }

  unsubscribeToAboutEvents(subscriber: IAboutEventsSubscriber): void {

    let index = -1;
    this.aboutEventSubscribers.forEach((s, i) => {
      if (s.TAG === subscriber.TAG) {
        index = i;
      }
    });

    if (index !== -1) {
      this.aboutEventSubscribers.splice(index, 1);
      if (this.subscribeDebug) {
        console.log(subscriber.TAG, 'is no longer a unsubscribeToAboutEvents');
      }
    }
  }


}
