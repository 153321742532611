import {asLiteral} from '@angular/compiler/src/render3/view/util';
import {OrderDirectionEnum} from '../enums/OrderDirectionEnum';
import {TextConverterHelper} from '../helpers/TextConverterHelper';

export abstract class EngineBase {


  constructor() {

  }

  getOrderFunction<T>(orderBy: string, orderDirection: OrderDirectionEnum) {

    return orderFunction.bind(this);

    function orderFunction(itemA: T, itemB: T): number {

      const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      if (orderDirection === OrderDirectionEnum.descending) {
        //not used
        return 0;
      }
      if (orderDirection === OrderDirectionEnum.ascending) {

        const valueA = TextConverterHelper.getOrderByValue<T>(itemA, orderBy);
        const valueB = TextConverterHelper.getOrderByValue<T>(itemB, orderBy);
        const isNumericA = TextConverterHelper.isNumeric(valueA);
        const isNumericB = TextConverterHelper.isNumeric(valueB);

        if (isNumericA === true &&
          isNumericB === false
        ) {
          //numbers are greater
          return 1;
        }

        if (isNumericA === false &&
          isNumericB === true
        ) {
          //numbers are greater
          return -1;
        }

        if (isNumericA === true &&
          isNumericB === true
        ) {
          if (parseInt(valueA) < parseInt(valueB))
            return -1;
          else
            return 1;
        }

        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      }
    }
  }


}
