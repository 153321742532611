export class DeviceModel {
  name: string;
  id: string;
  type: string;
  isActive: boolean;
  volume: number;

  public constructor(init?: Partial<DeviceModel>) {
    Object.assign(this, init);
  }
}
